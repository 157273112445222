import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Hidden, makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import ApiService from 'src/common/apiService';
import { getActualCompany, login, setActualCompany } from 'src/common/auth';

import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 40
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

const changeCompany = (cmp) => {
  const apiService = new ApiService();
  apiService
    .generateCompanyToken(cmp.id)
    .then((res) => {
      if (window.confirm('Deseja alterar para a conta: ' + res.company.name)) {
        login(res.token);
        setActualCompany(res.company);
        window.location.reload();
      }
    });
};

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);


  if (getQueryVariable('cc') && getQueryVariable('cc') != getActualCompany().id) {
    changeCompany({
      id: getQueryVariable('cc')
    });
  }

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Hidden>

      <TopBar/>

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>

            <Outlet />

          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
