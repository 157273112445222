import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import EditDriver from './EditDriver';
import EditPickup from './EditPickup';
import Linkify from 'react-linkify';
import nl2br from 'react-nl2br';

import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  Table,
  TableBody,
  CardHeader,
  Typography,
  CardContent, TextareaAutosize, Button, Avatar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TableCell, TableHead, TableRow, Radio, Tabs, Tab, List, ListItemText, Select, MenuItem, TextField, InputLabel, FormControl
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import OrderRow from '../OrdersView/OrderRow';
import SimpleReactFileUpload from './FileUpload';
import { isSuperUser } from 'src/common/auth';
import { SignalWifi4BarLockTwoTone } from '@material-ui/icons';
import { Trash } from 'react-feather';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{

        backgroundColor: 'rgb(236 236 236)', borderTop: '1px solid rgb(218 218 218)', paddingTop: 30, paddingLeft: 10, paddingRight: 10
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  content: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit'
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));

const DropshippinOrder = ({ order, refreshOrder, ...props }) => {
  const classes = useStyles();
  const [shipment, setShipment] = useState(order.shipments[0]);
  const [changingCarrier, setChangingCarrier] = useState(false);
  const [openChangeCarrier, setOpenChangeCarrier] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [openRemoveFromPickup, setOpenRemoveFromPickup] = useState(null);

  const [sendingAttachment, setSendingAttachment] = useState(false);
  const [attachment, setAttachment] = useState({
    name: null,
    mime: null
  });
  const [openAttachment, setOpenAttachment] = useState(false);

  const [issue, setIssue] = useState(null);
  const [sendingIssue, setSendingIssue] = useState(false);
  const apiService = new ApiService();
  const params = useParams();

  useEffect(() => {
    if (openChangeCarrier) {
      const apiService = new ApiService();
      apiService
        .quoteShipment(shipment.id)
        .then((res) => {
          setCarriers(res.data);
        });
    }
  }, [openChangeCarrier]);

  const downloadInvoice = (id) => {
    apiService.downloadInvoicePDF(id);
  };

  const sendAttachment = (response) => {
    setSendingAttachment(true);
    console.log(shipment.reference)
    apiService
      .addAttachment({
        shipment: {
          reference: shipment.reference
        },
        file: {
          name: attachment.name,
          mime: attachment.mime,
          path: response.data.path
        }
      })
      .then(() => {
        props.enqueueSnackbar('Anexada cadastrado com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        console.log(error.message);
        props.enqueueSnackbar('Ocorreu um erro ao tentar anexar o arquivo, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  const changeCarrier = () => {
    if (!selectedCarrier) {
      alert('Selecione uma cotação');
      return;
    }

    setChangingCarrier(true);

    apiService
      .changeShipmentCarrier(shipment.reference, selectedCarrier)
      .then(() => {
        props.enqueueSnackbar('Transportadora alterada com sucesso!', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };

  const removeFromPickup = () => {
    apiService
      .removeFromPickup(shipment.reference)
      .then(() => {
        props.enqueueSnackbar('Pedido removido da coleta com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };

  const sendIssue = (ship) => {
    setSendingIssue(true);
    apiService.createNewIssue({
      message: issue,
      reference: ship ? ship.reference : shipment.reference
    }).then(() => {
      props.enqueueSnackbar('Mensagem enviada com sucesso!', {
        variant: 'success',
      });
      setSendingIssue(false);
      refreshOrder();
    }).catch(() => {
      props.enqueueSnackbar('Ocorreu um erro ao tentar enviar a mensagem, tente novamente.', {
        variant: 'error',
      });
    });
  };

  const downloadFile = (path) => {
    const apiService = new ApiService();
    apiService.download(path);
  }

  const cancelShipment = () => {
    apiService
      .cancelShipment(shipment.reference)
      .then(() => {
        props.enqueueSnackbar('Remessa cancelada com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };



  const sendInvoice = (response) => {
    setSendingInvoice(true);
    apiService
      .sendInvoice({
        shipment: {
          reference: shipment.reference
        },
        invoice: {
          path: response.data.path
        }
      })
      .then(() => {
        props.enqueueSnackbar('Nota fiscal anexada com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        console.log(error.message);
        props.enqueueSnackbar('Ocorreu um erro ao tentar anexar nota fiscal, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
      style: { backgroundColor: '#fff' }
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cds = [...new Set(order.shipments.map((ship) => ship.distribution_center?.reference))];

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const shipmentsByOrigin = groupBy(order.shipments, 'distribution_center_reference');

  return (
    <Page
      className={classes.root}
      title={order ? "Pedido #" + order.reference : 'Carregando...'}
    >

      <Container maxWidth={false}>

        <Box mt={3}>
          <Typography
            color="textPrimary"
            variant="h1"
            className={classes.title}
          >
            Pedido #{order.reference}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={6} md={3} style={{ marginBottom: 20 }}>
              <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardHeader title="Destinatário" />
                <CardContent>
                  <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{order.to_address.name}</Typography>
                  <p className={classes.content}>
                    <strong>Telefone:</strong> {order.to_address.phone} <br />
                    <strong>E-mail:</strong> {order.to_address.email} <br />
                    <strong>CPF:</strong> {order.to_address.federal_tax_id} <br />
                  </p>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={4}>
              <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardHeader title="Endereço de entrega" />
                <CardContent>
                  <p className={classes.content}>
                    <strong>CEP:</strong> {order.to_address.zip} <br />
                    <strong>Endereço:</strong> {order.to_address.street1}, {order.to_address.number}, {order.to_address.street2} <br />
                    <strong>Bairro:</strong> {order.to_address.neighborhood} <br />
                    <strong>Cidade/UF:</strong> {order.to_address.city}/{order.to_address.state} <br />
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>


        <Dialog
          open={openAttachment}
          onClose={() => setOpenAttachment(false)}
        >
          <DialogTitle>{"Adicionar Anexo"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {sendingAttachment ? 'Aguarde ...' : <>
                <Box>
                  <TextField required label="Nome"
                    onChange={(ev) => setAttachment({ ...attachment, name: ev.target.value })}
                    value={attachment.name}></TextField>
                </Box>
                <Box>
                  <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                    <InputLabel margin="normal">Tipo de arquivo</InputLabel>
                    <Select
                      label="Tipo de arquivo"
                      margin="normal"
                      name="mime"
                      required
                      onChange={(ev) => setAttachment({ ...attachment, mime: ev.target.value })}
                      value={attachment.mime}
                      variant="outlined"
                    >
                      <MenuItem value="application/xml+nfe">XML Nota</MenuItem>
                      <MenuItem value="application/xml+nfe-event">XML Carta de correção</MenuItem>
                      <MenuItem value="application/xml+cte">XML CTE</MenuItem>
                      <MenuItem value="application/pdf">Documento em PDF</MenuItem>
                      <MenuItem value="application/image">Imagem</MenuItem>
                      <MenuItem value="application/others">Outros</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <SimpleReactFileUpload
                  submiting={sendingAttachment}
                  title=""
                  onSuccess={(response) => sendAttachment(response)}
                />
              </>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAttachment(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        {order.pickup ? <Dialog
          open={openRemoveFromPickup}
          onClose={() => setOpenRemoveFromPickup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar ação"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Você tem certeza?"} <br />
              Ao clicar em "Confirmar remoção", você estará removendo o pedido #{order.reference} da coleta #{order.pickup.id}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => openRemoveFromPickup(false)} color="primary">
              Fechar
            </Button>
            <Button onClick={() => removeFromPickup()} color="primary" autoFocus style={{ backgroundColor: '#F44336' }}>
              Confirmar remoção
            </Button>
          </DialogActions>
        </Dialog> : null}

        <Dialog
          open={openChangeCarrier}
          onClose={() => setOpenChangeCarrier(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alterar transportadora"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Escolha abaixo uma das cotações que deseja aplicar nesse pedido.
              <br />
            </DialogContentText>
          </DialogContent>
          {carriers.length ? <Table>
            <TableHead>
              <TableCell>Transportadora</TableCell>
              <TableCell>Peso utilizado</TableCell>
              <TableCell>Valor da Cotação</TableCell>
              <TableCell>Escolher</TableCell>
            </TableHead>
            <TableBody>
              {carriers.map((quote) => <TableRow>
                <TableCell>{quote.carrier.name}</TableCell>
                <TableCell>{quote.cubed_weight.toLocaleString('pt-BR')} kg</TableCell>
                <TableCell>{quote.estimated_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                <TableCell>
                  <Radio
                    onChange={(ev) => {
                      setSelectedCarrier(ev.target.value);
                    }}
                    key={quote.carrier.id}
                    value={quote.carrier.id}
                    name="radio-button-demo"
                  />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table> : <DialogContent> <strong>Realizando cotações, aguarde ...</strong></DialogContent>}
          <DialogActions>
            <Button onClick={() => setOpenChangeCarrier(false)} color="primary">
              Fechar
            </Button>
            <Button disabled={changingCarrier || carriers.length === 0 || !selectedCarrier} onClick={() => changeCarrier()} color="primary" autoFocus variant="contained"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar ação"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Você tem certeza?"} <br />
              Ao clicar em "Confirmar cancelamento", você estará cancelando a remessa #{shipment.reference} na Flixlog. Essa ação não poderá ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCancel(false)} color="primary">
              Fechar
            </Button>
            <Button onClick={() => cancelShipment()} color="primary" autoFocus style={{ backgroundColor: '#F44336' }}>
              Confirmar cancelamento
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCheckout}
          onClose={() => setOpenCheckout(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Enviar xml da Nota Fiscal"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <SimpleReactFileUpload
                submiting={sendingInvoice}
                title=""
                onSuccess={(response) => sendInvoice(response)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCheckout(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.rootTab}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}

          >
            {Object.keys(shipmentsByOrigin).map((cd, key) => <Tab label={<strong>{cd}</strong>} {...a11yProps(key)} />)}
          </Tabs>
          {Object.keys(shipmentsByOrigin).map((cd, key) => {
            const firstShipment = shipmentsByOrigin[cd][0];

            return <TabPanel value={value} index={key}>
              <>
                <Toolbar
                  order={firstShipment}
                  isDropShipping
                  setOpenChangeCarrier={() => {
                    setCarriers([]);
                    setShipment(firstShipment);
                    setOpenChangeCarrier(true);
                  }}
                  setOpenCancel={() => {
                    setShipment(firstShipment);
                    setOpenCancel(true);
                  }}
                  setOpenCheckout={() => {
                    setShipment(firstShipment);
                    setOpenCheckout(true);
                  }} />

                <Box mt={3}>
                  <Grid container spacing={3}>

                    {firstShipment.distribution_center ? <Grid item xs={6} md={3}>
                      <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardHeader title="Origem" />
                        <CardContent>
                          <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{firstShipment.distribution_center.reference} - <a style={{ color: '#111' }} href={`/settings/distribution-centers/edit/${firstShipment.distribution_center.id}`} target="_blank">{firstShipment.distribution_center.name}</a></Typography>
                          <p className={classes.content}>
                            <strong>CEP:</strong> {firstShipment.distribution_center.address.zip} <br />
                            <strong>Endereço:</strong> {firstShipment.distribution_center.address.street1}, {firstShipment.distribution_center.address.number}, {firstShipment.distribution_center.address.street2} <br />
                            <strong>Bairro:</strong> {firstShipment.distribution_center.address.neighborhood} <br />
                            <strong>Cidade/UF:</strong> {firstShipment.distribution_center.address.city}/{firstShipment.distribution_center.address.state} <br />
                          </p>
                        </CardContent>
                      </Card>
                    </Grid> : <Grid item xs={6} md={3}>
                      <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardHeader title="Origem" />
                        <CardContent>
                          <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>
                            CD Removido
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>}
                    <Grid item xs={6} md={6}>
                      <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardHeader title="Transportadora" />
                        <CardContent>
                          <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{firstShipment.carrier.name}</Typography>
                          <p className={classes.content}>
                            <strong>CNPJ:</strong> {firstShipment.carrier.federal_tax_id}<br />
                            <strong>Nível de serviço:</strong> {firstShipment.carrier.sla} de 5 <br />
                            <strong>Telefone:</strong> <a href={`tel:+55` + firstShipment.carrier.phone}>{firstShipment.carrier.phone}</a><br />
                            <br />
                            {firstShipment.tracker && firstShipment.tracker.tracking_code ? (<strong>Código de Ratreio: <a target="_blank" href={`/tracking/${firstShipment.tracker.tracking_code.toUpperCase()}`}>{firstShipment.tracker.tracking_code.toUpperCase()}</a></strong>) : null}
                          </p>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                      <Card>
                        <CardContent>
                          <Table>
                            <TableBody>
                              <OrderRow order={firstShipment} style={{ width: '100%' }} noRow={true} />
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>



                        <Grid item xs={12} md={12}>
                          <Card>
                            <CardHeader title="Ocorrências" />
                            <CardContent>
                              <ul class="timeline">
                                {firstShipment.review ? <li class="status-5">
                                  <span>Avaliação do cliente: <ReactStars
                                    value={firstShipment.review.rate}
                                    size={24}
                                    edit={false}
                                    activeColor="#ffcc00"
                                  /><br />
                                    <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{firstShipment.review.message}</small>
                                  </span>
                                  <span class="float-right">{moment(firstShipment.review.created_at).format('DD/MM/YYYY HH')}h</span>
                                </li> : null}
                                {firstShipment.tracker ? firstShipment.tracker.details.map((detail) => {
                                  return (<li class={"status-" + detail.status}>
                                    <span>
                                      {detail.description}
                                      <br />
                                      <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{detail.message}</small>
                                      {detail.code == 1 && firstShipment.tracker.delivery_receipt_url ? <a href={firstShipment.tracker.delivery_receipt_url} style={{
                                        color: '#03a9f4', marginTop: '10px', display: 'block', fontWeight: 'bold'
                                      }} target="_blank">Comprovante entrega</a> : null}
                                      {detail.code == 200 && firstShipment.invoice ? <a onClick={() => downloadInvoice(firstShipment.invoice.id)} style={{
                                        color: '#03a9f4', marginTop: '10px', display: 'block', fontWeight: 'bold', cursor: 'pointer'
                                      }} target="_blank">Baixar Danfe</a> : null}
                                    </span>
                                    <span class="float-right">{moment(detail.occurred_at).format('DD/MM/YYYY HH')}h</span>
                                  </li>);
                                }) : null}

                                <li>
                                  <span>Pedido criado <br />
                                    <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>Código: {firstShipment.reference}</small>
                                  </span>
                                  <span class="float-right">{moment(firstShipment.created_at).format('DD/MM/YYYY HH')}h</span>
                                </li>

                              </ul>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card>
                            <CardHeader title="Volumes" />
                            <CardContent>
                              {shipmentsByOrigin[cd].map((shipment, key) => {
                                return <p><small>{key + 1} - <strong>{'Dimensões: ('}{(shipment.height * 100) + 'x' + (shipment.width * 100) + 'x' + (shipment.length * 100)}{') cm - Peso cubado: '} {shipment.weight + 'Kg'}{' - Quantidade: '} {shipment.quantity} {' - Valor: '} {shipment.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></small></p>;
                              })}
                            </CardContent>
                          </Card>
                        </Grid>

                        {firstShipment.invoice && firstShipment.products.length ? (
                          <Grid item xs={12} md={12}>
                            <Card>
                              <CardHeader title="Produtos" />
                              <CardContent>
                                {firstShipment.products.map((shipment) => {
                                  return <p><small>{shipment.reference} - {shipment.description}</small></p>;
                                })}
                              </CardContent>
                            </Card>
                          </Grid>
                        ) : null}


                        {(firstShipment.delivery && firstShipment.delivery.log) ? (
                          <Grid item xs={12} md={12}>
                            <Card>
                              <CardHeader title={`Log de cotação - Peso cubado: ` + firstShipment.delivery.cubed_weight + `KG`} />
                              <CardContent>
                                <Table>
                                  <TableHead>
                                    <TableCell>
                                      Tipo de taxa
                                    </TableCell>
                                    <TableCell>
                                      Valor Anterior
                                    </TableCell>
                                    <TableCell>
                                      Percentual
                                    </TableCell>
                                    <TableCell>
                                      Valor Adicional
                                    </TableCell>
                                    <TableCell>
                                      Valor Final
                                    </TableCell>
                                  </TableHead>
                                  <TableBody>
                                    {firstShipment
                                    .delivery
                                    .log
                                    .filter((log) => !log.deadlines)
                                    .map((log) => {
                                      var percentage = (<TableCell>{log.percentage ? log.percentage + '%' : '-'}</TableCell>);
                                      var translageFee = {
                                        pickup: 'Taxa de Coleta',
                                        toll: 'Pedágio',
                                        weight: 'Frete peso',
                                        gris: 'GRIS',
                                        adv: 'ADV (Seguro)',
                                        tdc: 'Taxa de despacho',
                                        icms: 'ICMS',
                                        'additional-price': 'KG Excedente',
                                        'increase-table-value': 'Adicional da tabela',
                                        'increase-freight-value': 'Adicional da conta',

                                        'freight_rule_increase_value': 'Regra de Frete: Aumentar valor',
                                        'freight_rule_increase_percentage': 'Regra de Frete: Aumentar percentual',
                                        'freight_rule_decrease_value': 'Regra de Frete: Diminuir valor',
                                        'freight_rule_decrease_percentage': 'Regra de Frete: Diminuir percentual',
                                        'freight_rule_fixed_value': 'Regra de Frete: Valor fixo'
                                      }
                                      if (log.icms_percentage) {
                                        percentage = (<TableCell>{log.icms_percentage ? log.icms_percentage + '%' : '-'}</TableCell>);
                                      }
                                      if (log.gris_percentage) {
                                        percentage = (<TableCell>{log.gris_percentage ? (log.gris_percentage * 100).toFixed(2) + '%' : '-'}</TableCell>);
                                      }
                                      if (log.adv_percentage) {
                                        percentage = (<TableCell>{log.adv_percentage ? (log.adv_percentage * 100) + '%' : '-'}</TableCell>);
                                      }
                                      if (log.fee == 'freight_rule_increase_percentage' || log.fee == 'freight_rule_decrease_percentage') {
                                        percentage = (<TableCell>{log.value ? log.value + '%' : '-'}</TableCell>);
                                      }

                                      if (log.fee == 'freight_rule_increase_value' || log.fee == 'freight_rule_decrease_value') {
                                        var price = <TableCell>{log.value > 0 ? log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</TableCell>
                                      } else if (log.fee == 'freight_rule_fixed_value') {
                                        var price = <TableCell>{log.value > 0 ? '-' : '-'}</TableCell>
                                      } else {
                                        var price = <TableCell>{(log.actual_price - log.previous_price) > 0 ? (log.actual_price - log.previous_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</TableCell>
                                      }
                                      return (
                                        <TableRow>
                                          <TableCell>{translageFee[log.fee] ? translageFee[log.fee] : log.fee}</TableCell>
                                          <TableCell>{log.fee === "weight" ? log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (log.previous_price ? log.previous_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null)}</TableCell>
                                          {percentage}
                                          {price}

                                          <TableCell>{log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </CardContent>
                            </Card>

                            {firstShipment
                              .delivery
                              .log
                              .filter((log) => log.deadlines)
                              .map((log) => {
                                const deadlines = log.deadlines;
                                return <Card>
                                  <CardHeader title={`Log de Prazos`} />
                                  <CardContent>
                                    <Table>
                                      <TableHead>
                                        <TableCell>
                                          Descrição
                                        </TableCell>
                                        <TableCell>
                                          Prazo Adicional
                                        </TableCell>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>Prazo de Coleta</TableCell>
                                          <TableCell>{deadlines.pickup_deadline} dias úteis</TableCell>
                                        </TableRow>
                                        {deadlines.expedition_time > 0 ? <TableRow>
                                          <TableCell>Prazo de expedição</TableCell>
                                          <TableCell>{deadlines.expedition_time} dias úteis</TableCell>
                                        </TableRow> : null}
                                        {deadlines.sku_deadline > 0 ? <TableRow>
                                          <TableCell>Prazo de separação</TableCell>
                                          <TableCell>{deadlines.sku_deadline} dias úteis</TableCell>
                                        </TableRow> : null}
                                        <TableRow>
                                          <TableCell>Prazo de transporte</TableCell>
                                          <TableCell>{deadlines.carrier_deadline} dias úteis</TableCell>
                                        </TableRow>
                                        {deadlines.freight_rules_deadline > 0 ? <TableRow>
                                          <TableCell>Prazo de regra de frete</TableCell>
                                          <TableCell>{deadlines.freight_rules_deadline} dias úteis</TableCell>
                                        </TableRow> : null}
                                        <TableRow>
                                          <TableCell><strong>Prazo Final</strong></TableCell>
                                          <TableCell><strong>{deadlines.total} dias úteis</strong></TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </CardContent>
                                </Card>
                              })}
                          </Grid>
                        ) : null}

                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>

                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Dados da Coleta" />
                            {firstShipment.pickup ? <CardContent>
                              {firstShipment.pickup ? <List>
                                <ListItemText>Coleta Flixlog: <strong>#{firstShipment.pickup.id}</strong> </ListItemText>
                                <ListItemText>Coleta Transportadora: <strong title={`Integrado em ${moment(firstShipment.pickup.integration_date).format('DD/MM/YYYY HH:MM')}`}>{firstShipment.pickup.integration_code}</strong> </ListItemText>
                                <ListItemText>Data de solicitação de coleta: <strong>{moment(firstShipment.pickup.created_at).format('DD/MM/YYYY HH:MM')}</strong> </ListItemText>

                                {firstShipment.pickup.estimated_date ? <ListItemText style={{ color: "#000", fontWeight: 'bold' }}><strong>Data estimada de coleta: {moment(firstShipment.pickup.estimated_date).format('DD/MM/YYYY')}. {firstShipment.pickup.estimated_time_start ? (
                                  `Entre ${firstShipment.pickup.estimated_time_start ? firstShipment.pickup.estimated_time_start + 'h' : null} ${firstShipment.pickup.estimated_time_end ? firstShipment.pickup.estimated_time_end + 'h' : null}`
                                ) : ''}</strong></ListItemText> : null}

                                {firstShipment.pickup.driver ? <ListItemText style={{ color: "#000", fontWeight: 'bold' }}>{firstShipment.pickup.driver.name}</ListItemText> : null}
                              </List> : null}

                              <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setShipment(firstShipment);
                                    setOpenRemoveFromPickup(true);
                                  }}
                                  style={{
                                    color: '#000',
                                    fontSize: 11
                                  }}><Trash style={{ width: 14 }} /> REMOVER DESSA COLETA</Button>

                                {isSuperUser() ? (
                                  <>
                                    <EditDriver
                                      isShipment
                                      props={props}
                                      reference={firstShipment.reference}
                                      refreshOrder={refreshOrder}
                                      name={firstShipment.pickup.driver ? firstShipment.pickup.driver.name : null}
                                      phone={firstShipment.pickup.driver ? firstShipment.pickup.driver.phone : null}
                                      label="Editar Motorista"
                                      style={{ float: 'left', marginRight: 20 }}
                                    />
                                    <EditPickup
                                      props={props}
                                      isShipment
                                      reference={firstShipment.reference}
                                      refreshOrder={refreshOrder}
                                      estimated_date={firstShipment.pickup.estimated_date ? firstShipment.pickup.estimated_date : null}
                                      estimated_time_start={firstShipment.pickup.estimated_time_start ? firstShipment.pickup.estimated_time_start : null}
                                      estimated_time_end={firstShipment.pickup.estimated_time_end ? firstShipment.pickup.estimated_time_end : null}
                                      label="Editar data de coleta"

                                      style={{ float: 'left', marginRight: 20 }}
                                    />
                                  </>
                                ) : null}
                              </Box>

                            </CardContent> : <CardContent>
                              {firstShipment.status.id == 1 || firstShipment.status.id == 2 ? <Typography variant="h4" style={{ marginBottom: 30 }}>Ação recomendada:</Typography> : null}

                              <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}>

                                {firstShipment.status.id == 1 ? < Button
                                  variant="contained"

                                  onClick={() => {
                                    setShipment(firstShipment);
                                    setOpenCheckout(true);
                                  }}

                                  style={{
                                    color: '#fff',
                                    backgroundColor: "#8BC34A"
                                  }}>ENVIAR NOTA FISCAL</Button> : null}

                                {firstShipment.status.id == 2 ? < Button
                                  variant="contained"
                                  component={Link}
                                  to="/pickups/new"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: "#8BC34A"
                                  }}>SOLICITAR COLETA</Button> : null}

                                {isSuperUser() ? (
                                  <>
                                    <EditDriver
                                      isShipment
                                      props={props}
                                      reference={firstShipment.reference}
                                      refreshOrder={refreshOrder}
                                      name={firstShipment.pickup && firstShipment.pickup.driver ? firstShipment.pickup.driver.name : null}
                                      phone={firstShipment.pickup && firstShipment.pickup.driver ? firstShipment.pickup.driver.phone : null}
                                      label="Editar Motorista"
                                      style={{ float: 'left', marginRight: 20 }}
                                    />
                                    <EditPickup
                                      props={props}
                                      isShipment
                                      reference={firstShipment.reference}
                                      refreshOrder={refreshOrder}
                                      estimated_date={firstShipment.pickup && firstShipment.pickup.estimated_date ? firstShipment.pickup.estimated_date : null}
                                      estimated_time_start={firstShipment.pickup && firstShipment.pickup.estimated_time_start ? firstShipment.pickup.estimated_time_start : null}
                                      estimated_time_end={firstShipment.pickup && firstShipment.pickup.estimated_time_end ? firstShipment.pickup.estimated_time_end : null}
                                      label="Editar data de coleta"

                                      style={{ float: 'left', marginRight: 20 }}
                                    />
                                  </>
                                ) : null}
                              </Box>
                            </CardContent>}
                          </Card>
                        </Grid>




                        {order.delivery ? <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Dados de Entrega" />
                            <CardContent>
                              <List>
                                <ListItemText>Previsão de entrega: <strong>{moment(order.delivery.delivery_date).format('DD/MM/YYYY')}</strong> </ListItemText>
                              </List>
                            </CardContent>
                          </Card>
                        </Grid> : null}

                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title="Anexos" />
                            <CardContent>
                              <Button variant="contained"
                                onClick={() => {
                                  setShipment(firstShipment);
                                  setOpenAttachment(true);
                                }}
                                style={{
                                  color: '#fff',
                                  backgroundColor: "#8BC34A"
                                }}>ADICIONAR ANEXO</Button>

                              <List>

                                {firstShipment.attachments && firstShipment.attachments.map((res) => <ListItemText>
                                  <Box display="flex" justifyContent="space-between" style={{ borderBottom: '1px solid #ddd', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Box>{res.name}</Box>
                                    <Box>
                                      <a onClick={() => downloadFile(res.path)} target="_blank" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Abrir</a>
                                    </Box>
                                  </Box>
                                </ListItemText>
                                )}
                              </List>
                            </CardContent>

                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card>
                            <CardHeader title="Mensagens" />
                            <CardContent style={{ textAlign: 'right' }}>
                              <form>

                                <ul class="timeline message" style={{ borderBottom: '1px solid #ddd' }}>
                                  {firstShipment.issues.map((issue) => {
                                    return (<li>

                                      <span style={{ display: 'flex', width: '50%', alignItems: 'center', textAlign: 'left' }}>
                                        <Tooltip enterDelay={300} title={issue.user.name} key={issue.id}>
                                          <Avatar
                                            className={classes.purple}
                                            src={issue.user.photo}>{issue.user.name ? issue.user.name.substring(0, 1) : ''}</Avatar>
                                        </Tooltip>

                                        <small style={{ color: '#5a5a5a', fontWeight: 'normal', marginLeft: 10 }} >

                                          <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                              {decoratedText}
                                            </a>
                                          )}>{nl2br(issue.message)}</Linkify>

                                          {issue.user.is_flixlog ? <><br /><strong
                                            title="Mensagem da equipe Flixlog"
                                            style={{
                                              background: '#c6f9ff',
                                              padding: '3px',
                                              borderRadius: '5px',
                                              color: '#0b7987',
                                              marginTop: '10px',
                                              display: 'inline-block',
                                              fontSize: '9px',
                                              border: '1px',
                                              solid: '#82c9d1'
                                            }}
                                          >FLIXLOG</strong></> : null}

                                        </small>
                                      </span>
                                      <span class="float-right">{moment(issue.created_at).format('DD/MM/YYYY HH:mm')}</span>
                                    </li>);
                                  })}
                                </ul>

                                <TextareaAutosize
                                  onChange={(e) => setIssue(e.target.value)}
                                  readOnly={sendingIssue}
                                  placeholder="Digite a mensagem"
                                  style={{ marginTop: 30 }}
                                />
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={sendingIssue}
                                  onClick={() => {
                                    sendIssue(firstShipment);
                                  }}
                                >
                                  ENVIAR MENSAGEM
                                </Button>
                              </form>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            </TabPanel>
          })}

        </div>


      </Container>
    </Page>
  );
};

const mapStateToProps = (store) => ({
  orderSearch: store.orderSearch.value
});

export default connect(mapStateToProps)(withSnackbar(DropshippinOrder));
