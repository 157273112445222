import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DistributionCenterView = () => {
  const classes = useStyles();
  const [cds, setCds] = useState([]);

  const reloadList = (filters) => {
    const apiService = new ApiService();
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  useEffect(() => {
    reloadList();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Centros de distribuição"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results cds={cds} />
        </Box>
      </Container>
    </Page>
  );
};

export default DistributionCenterView;
