import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import axios from 'axios';
import * as _ from 'underscore';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus, Trash } from 'react-feather';
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const Form = ({ enqueueSnackbar, initialValues, cds, onSubmit, submitText, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const lastQuote = localStorage.getItem('lastQuote') ? JSON.parse(localStorage.getItem('lastQuote')) : {};
  const [to, setTo] = useState(initialValues.address_zip ? initialValues.address_zip : lastQuote.to);
  const [initValues, setInitValues] = useState(to ? { ...initialValues, address_zip: to } : initialValues);
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [quoting, setQuoting] = useState(false);
  const [products, setProducts] = useState({});

  const [parcels, setParcels] = useState(initialValues.shipments ? initialValues.shipments : (lastQuote.parcels ? lastQuote.parcels : [{
    from_cd_reference: '',
    reference: '',
    width: '',
    length: '',
    height: '',
    weight: '',
    quantity: 1,
    cargo_value: ''
  }]));


  const handleParcel = (idx, event) => {
    let par = JSON.parse(JSON.stringify(parcels));
    par[idx][event.target.name] = event.target.value;
    setParcels(par);
  };

  const blurParcel = () => {
    var hasParcel = parcels.filter((parcel) => {
      if (products[parcel.reference]) {
        return true;
      }

      return parcel.width && parcel.length && parcel.height && parcel.weight && parcel.cargo_value && parcel.quantity;
    });
  };

  const blurParcelSku = () => {
    const apiService = new ApiService();

    setQuoting(true);

    apiService
      .getProducts({
        reference: parcels.map((res) => res.reference).join(',')
      }, parcels.length, 1)
      .then((product) => {
        product.data.forEach((item) => {
          if (item && item.width && item.length && item.weight && item.height) {
            products[item.reference] = item;
          }

        });

        setProducts(products);

        setQuoting(false);
      })
      .catch(() => {
        setQuoting(false);
      });
  };


  useEffect(() => {
    quoteFreight();
  }, []);




  const quoteFreight = (parcelsx) => {
    if (!to) {
      return;
    }
    setQuoting(true);
    const apiService = new ApiService();
    let par = parcelsx ? JSON.parse(JSON.stringify(parcelsx)) : JSON.parse(JSON.stringify(parcels));

    apiService.quoteFreight({
      to,
      parcels: par.map((parcel) => {
        if (!parcel['reference']) {
          delete parcel['reference'];
        }
        parcel['from_cd_reference'] = parcel['from_cd_reference'];
        parcel['width'] = parseFloat(parcel['width'] / 100);
        parcel['height'] = parseFloat(parcel['height'] / 100);
        parcel['length'] = parseFloat(parcel['length'] / 100);
        parcel['weight'] = parseFloat(parcel['weight']);
        parcel['cargo_value'] = parseFloat(parcel['cargo_value']);
        return parcel;
      })
    }).then((res) => {
      setQuoting(false);

      res.data.filter((quote) => {
        return quote.own_freight_table === false;
      })
        .map((res) => {
          setEstimatedCost(res.estimated_cost);
        })
    });
  };

  const removeParcel = (ids) => {
    setParcels(parcels.filter((item, id) => (id !== ids)));
    blurParcel(parcels.filter((item, id) => (id !== ids)));
  };

  const addParcel = () => {
    setParcels(parcels => [...parcels, {
      reference: '',
      width: '',
      length: '',
      height: '',
      weight: '',
      quantity: 1,
      cargo_value: ''
    }]);
  };

  const handleCep = (cep, values) => {
    const zip = cep.replace('.', '').replace('-', '').replace('_', '');

    if (zip.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${zip}/json/`)
        .then((res) => {
          const { logradouro, complemento, bairro, localidade, uf } = res.data;

          values['address_street1'] = logradouro;
          values['address_street2'] = complemento;
          values['address_city'] = localidade;
          values['address_neighborhood'] = bairro;
          values['address_state'] = uf;

          setInitValues({ ...initValues, values });

        });
    }

  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={Yup.object().shape({
        reference: Yup.string().required('Digite o código do pedido'),
        address_name: Yup.string().max(150, 'Deve ter no máximo 150 caracteres').required('Digite o nome ou razão social'),
        address_federal_tax_id: Yup.string('Apenas números').required('Digite o CNPJ ou CPF, apenas números.'),
        address_phone: Yup.string('Apenas números').required('Digite um telefone.'),
        address_email: Yup.string().email('Digite um e-mail válido').max(255, 'Deve ter no máximo 255 caracteres'),
        address_zip: Yup.string('Apenas números').required('Digite um CEP válido.'),
        address_street1: Yup.string().required('Digite o endereço.'),
        address_number: Yup.string().required('Digite o número.'),
        address_neighborhood: Yup.string().required('Digite o bairro.'),
        address_city: Yup.string().required('Digite a cidade.'),
        address_state: Yup.string().required('Selecione o estado.')
      })}
      onSubmit={(vals) => onSubmit(vals, parcels, products)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit} onClick={() => {
          if (to && !values.address_city) {
            handleCep(to, values);
          }
        }}>
          <Card>
            <CardContent>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  {title ? title : "Novo pedido"}
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <Tooltip enterDelay={50} title="São pedidos que saem de diferentes fornecedores. Normalmente em operações de dropshipping. Você precisará adicionar a origem em cada um dos volumes.">

                    <FormControlLabel
                      label="Pedido multi-origem?"
                      control={<Switch
                        error={Boolean(touched.is_drop_shipping && errors.is_drop_shipping)}
                        helperText={touched.is_drop_shipping && errors.is_drop_shipping}
                        margin="normal"
                        name="is_drop_shipping"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.is_drop_shipping}
                        checked={values.is_drop_shipping}
                        variant="outlined"
                        fullWidth
                      />} />
                  </Tooltip>

                </Grid>

                <Grid item xs={6} lg={3}>
                  <TextField
                    error={Boolean(touched.reference && errors.reference)}
                    helperText={touched.reference && errors.reference}
                    label="Número do pedido"
                    margin="normal"
                    name="reference"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reference}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                {values.is_drop_shipping ? null : <Grid item xs={6} lg={3}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel margin="normal">CD de Origem</InputLabel>
                    <Select
                      error={Boolean(touched.from_cd_reference && errors.from_cd_reference)}
                      helperText={touched.from_cd_reference && errors.from_cd_reference}
                      label="CD de Origem"
                      margin="normal"
                      name="from_cd_reference"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.from_cd_reference}
                      variant="outlined"
                      fullWidth
                    >
                      {cds.map((cd) => <MenuItem value={cd.reference}>{cd.reference}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Dados do Destinatário" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <InputLabel margin="normal">Tipo</InputLabel>
                        <Select
                          error={Boolean(touched.address_residential && errors.address_residential)}
                          helperText={touched.address_residential && errors.address_residential}
                          label="Tipo"
                          margin="normal"
                          name="address_residential"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address_residential}
                          variant="outlined"
                        >
                          <MenuItem value={1}>Residencial</MenuItem>
                          <MenuItem value={0}>Comercial</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <TextField
                        error={Boolean(touched.address_name && errors.address_name)}
                        helperText={touched.address_name && errors.address_name}
                        label={values.address_residential === 1 ? 'Nome' : 'Razão Social'}
                        margin="normal"
                        fullWidth
                        name="address_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <InputMask
                        mask={values.address_residential === 1 ? '999.999.999-99' : '99.999.999/9999-99'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.address_federal_tax_id && errors.address_federal_tax_id)}
                        helperText={touched.address_federal_tax_id && errors.address_federal_tax_id}
                        value={values.address_federal_tax_id}
                        name="address_federal_tax_id"
                      >
                        {() => (
                          <TextField
                            label={values.address_residential === 1 ? 'CPF' : 'CNPJ'}
                            margin="normal"
                            fullWidth
                            name="address_federal_tax_id"
                            variant="outlined"
                          />
                        )}
                      </InputMask>

                    </Grid>
                    <Grid item xs={12} lg={6}>

                    <InputMask
                        mask="(99) 99999 9999"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.address_phone && errors.address_phone)}
                        helperText={touched.address_phone && errors.address_phone}
                        value={values.address_phone}
                        name="address_phone"
                      >
                        {() => (
                          <TextField
                            label="Telefone"
                            margin="normal"
                            fullWidth
                            name="address_phone"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                      
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        error={Boolean(touched.address_email && errors.address_email)}
                        helperText={touched.address_email && errors.address_email}
                        label="E-mail"
                        margin="normal"
                        name="address_email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_email}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} lg={2}>
                  <InputMask
                    mask="99.999-999"
                    error={Boolean(touched.address_zip && errors.address_zip)}
                    helperText={touched.address_zip && errors.address_zip}
                    name="address_zip"
                    value={values.address_zip}
                    onBlur={(ev) => {
                      handleBlur(ev);
                      blurParcel();
                      handleCep(ev.target.value, values);
                    }}
                    onChange={(ev) => {
                      handleChange(ev);
                      setTo(ev.target.value);
                      handleCep(ev.target.value, values);
                    }}
                  >
                    {() => (
                      <TextField
                        label="CEP"
                        margin="normal"
                        name="address_zip"
                        variant="outlined"
                      />
                    )}
                  </InputMask>

                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                      <TextField
                        error={Boolean(touched.address_street1 && errors.address_street1)}
                        helperText={touched.address_street1 && errors.address_street1}
                        label="Endereço"
                        margin="normal"
                        fullWidth
                        name="address_street1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_street1}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        error={Boolean(touched.address_number && errors.address_number)}
                        helperText={touched.address_number && errors.address_number}
                        label="Número"
                        margin="normal"
                        name="address_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_number}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <TextField
                        error={Boolean(touched.address_street2 && errors.address_street2)}
                        helperText={touched.address_street2 && errors.address_street2}
                        label="Complemento"
                        margin="normal"
                        fullWidth
                        name="address_street2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_street2}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <TextField
                        error={Boolean(touched.address_neighborhood && errors.address_neighborhood)}
                        helperText={touched.address_neighborhood && errors.address_neighborhood}
                        label="Bairro"
                        margin="normal"
                        name="address_neighborhood"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_neighborhood}
                        variant="outlined"
                        fullWidth

                      />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <TextField
                        error={Boolean(touched.address_city && errors.address_city)}
                        helperText={touched.address_city && errors.address_city}
                        label="Cidade"
                        margin="normal"
                        name="address_city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_city}
                        variant="outlined"
                        fullWidth

                      />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <InputLabel margin="normal">Estado</InputLabel>
                        <Select
                          error={Boolean(touched.address_state && errors.address_state)}
                          helperText={touched.address_state && errors.address_state}
                          label="Estado"
                          margin="normal"
                          name="address_state"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address_state}
                          fullWidth

                          variant="outlined"
                        >
                          <MenuItem value="AC">AC</MenuItem>
                          <MenuItem value="AL">AL</MenuItem>
                          <MenuItem value="AP">AP</MenuItem>
                          <MenuItem value="AM">AM</MenuItem>
                          <MenuItem value="BA">BA</MenuItem>
                          <MenuItem value="CE">CE</MenuItem>
                          <MenuItem value="DF">DF</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                          <MenuItem value="GO">GO</MenuItem>
                          <MenuItem value="MA">MA</MenuItem>
                          <MenuItem value="MT">MT</MenuItem>
                          <MenuItem value="MS">MS</MenuItem>
                          <MenuItem value="MG">MG</MenuItem>
                          <MenuItem value="PA">PA</MenuItem>
                          <MenuItem value="PB">PB</MenuItem>
                          <MenuItem value="PR">PR</MenuItem>
                          <MenuItem value="PE">PE</MenuItem>
                          <MenuItem value="PI">PI</MenuItem>
                          <MenuItem value="RJ">RJ</MenuItem>
                          <MenuItem value="RN">RN</MenuItem>
                          <MenuItem value="RS">RS</MenuItem>
                          <MenuItem value="RO">RO</MenuItem>
                          <MenuItem value="RR">RR</MenuItem>
                          <MenuItem value="SC">SC</MenuItem>
                          <MenuItem value="SP">SP</MenuItem>
                          <MenuItem value="SE">SE</MenuItem>
                          <MenuItem value="TO">TO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              title="Volumes" />
            <CardContent>

              <Grid container spacing={3}>
                {parcels.map((parcel, idx) => (
                  <>
                    {values.is_drop_shipping ? <Grid item xs={6} lg={2}>

                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <InputLabel margin="normal">CD de Origem</InputLabel>
                        <Select
                          label="CD de Origem"
                          margin="normal"
                          name="from_cd_reference"

                          required={values.is_drop_shipping}

                          onChange={(ev) => {
                            handleParcel(idx, ev);
                          }}

                          value={parcel.from_cd_reference}
                          variant="outlined"
                          fullWidth
                        >
                          {cds.map((cd) => <MenuItem value={cd.reference}>{cd.reference}</MenuItem>)}
                        </Select>
                      </FormControl>

                    </Grid> : null}
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="SKU"
                        margin="normal"
                        name="reference"
                        value={parcel.reference}
                        onBlur={(ev) => {
                          blurParcel(ev);
                          blurParcelSku(ev);
                        }}
                        onChange={(ev) => {
                          handleParcel(idx, ev);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <TextField
                        label="Altura (cm)"
                        margin="normal"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        name="height"
                        value={parcel.height}
                        disabled={quoting || (products[parcel.reference] && products[parcel.reference].height)}
                        onBlur={blurParcel}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <TextField
                        label="Largura (cm)"
                        type="number"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="width"
                        value={parcel.width}
                        disabled={quoting || (products[parcel.reference] && products[parcel.reference].width)}
                        onBlur={blurParcel}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={values.is_drop_shipping ? 1 : 2}>
                      <TextField
                        label="Comprimento (cm)"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        margin="normal"
                        name="length"
                        value={parcel.length}
                        disabled={quoting || (products[parcel.reference] && products[parcel.reference].length)}
                        onBlur={blurParcel}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <TextField
                        label="Peso (Kg)"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        type="number"
                        name="weight"
                        value={parcel.weight}
                        disabled={quoting || (products[parcel.reference] && products[parcel.reference].weight)}
                        onBlur={blurParcel}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={2}>

                      <Tooltip enterDelay={50} title="Digite o valor total, não o preço unitário!">
                        <TextField
                          label={"Valor total do item (R$)"}
                          InputLabelProps={{ shrink: true }}
                          type="number"
                          margin="normal"
                          required
                          name="cargo_value"
                          disabled={quoting}
                          onBlur={blurParcel}
                          value={parcel.cargo_value}
                          onChange={(ev) => handleParcel(idx, ev)}
                          variant="outlined"
                        />
                      </Tooltip>

                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <TextField
                        label="Quantidade"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        margin="normal"
                        name="quantity"
                        onBlur={blurParcel}
                        disabled={quoting}
                        value={parcel.quantity}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex' }}>
                      <Button disabled={idx === 0} onClick={() => removeParcel(idx)}> <Trash /> </Button>
                    </Grid>

                    {products[parcel.reference] ?
                      (
                        <Grid item xs={12} lg={12}>
                          SKU: <strong>{products[parcel.reference].reference}</strong> <br />
                          Vol. 1 - Dimensões: <strong>({products[parcel.reference].height * 100}cm x {products[parcel.reference].width * 100}cm x {products[parcel.reference].length * 100}cm) | Peso: {products[parcel.reference].weight.toLocaleString('pt-BR')}kg | Quantidade: {products[parcel.reference].quantity * parcel.quantity}</strong>

                          {products[parcel.reference] && products[parcel.reference].property ?
                            products[parcel.reference].property.map((res, idx) => {
                              return <span><br />Vol. {idx == 0 ? 2 : idx + 2} - Dimensões: <strong>({res.height * 100}cm x {res.width * 100}cm x {res.length * 100}cm) | Peso:  {res.weight.toLocaleString('pt-BR')}kg | Quantidade: {res.quantity * parcel.quantity}</strong></span>
                            }) : null}
                        </Grid>
                      ) : null}


                  </>
                ))}
              </Grid>

              <br />
              <Button onClick={addParcel}>
                <Plus /> Adicionar mais um item.
              </Button>
              <br />
              <Divider />
              <br />
              <br />
              <Grid container spacing={3}>
                <Grid item lg={12} >
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={quoting || isSubmitting}
                  >
                    {submitText ? submitText : "CADASTRAR"}
                  </Button>

                  <Button
                    color="default"
                    size="large"
                    onClick={() => quoteFreight(parcels)}
                    variant="contained"
                    disabled={quoting}
                    style={{ marginLeft: 20 }}
                  >
                    CALCULAR FRETE
                  </Button>

                  <span style={{ display: 'inline-block', marginLeft: 20, fontWeight: '500', color: '#000' }}>
                    {quoting ? 'Efetuando cotações...' : (estimatedCost ? `Cotação estimada: R$ ${estimatedCost.toLocaleString('pt-BR')}` : null)}
                  </span>

                </Grid>

              </Grid>

            </CardContent>


          </Card>

        </form>
      )
      }
    </Formik >
  );
};

export default withSnackbar(Form);
