import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import { Eye, Plus, Trash } from 'react-feather';
import ApiService from 'src/common/apiService';
import { useNavigate } from 'react-router-dom';
import { JsonToTable } from 'react-json-to-table';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  successButton: {
    backgroundColor: '#8BC34A',
    marginLeft: 20
  }
}));

const QuoteView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const [results, setResults] = useState([]);
  const [errors, setError] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [form, setForm] = useState(null);
  const [products, setProducts] = useState({});
  const [quoting, setQuoting] = useState(false);
  const [parcels, setParcels] = useState([{
    reference: '',
    width: '',
    length: '',
    height: '',
    weight: '',
    quantity: 1,
    cargo_value: ''
  }]);
  const [to, setTo] = useState(null);
  const [cd, setCd] = useState(null);
  const [carrier, setCarrier] = useState(null);

  const [cds, setCds] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const apiService = new ApiService();

  const refresh = () => {
    apiService
      .getCarriers()
      .then((res) => {
        setCarriers(res.data);
      });
  };

  const reloadCdList = (filters) => {
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  const handleParcel = (idx, event) => {
    let par = JSON.parse(JSON.stringify(parcels));
    par[idx][event.target.name] = event.target.value;
    setParcels(par);
  };

  useEffect(() => {
    reloadCdList();
    refresh();
  }, []);

  const newOrder = () => {
    localStorage.setItem('lastQuote', JSON.stringify({ to, parcels }));
    navigate('/orders/new');
  };

  const quoteFreight = () => {

    if (!cd) {
      enqueueSnackbar('Selecione o CD de Origem', {
        variant: 'error'
      });
      return;
    }

    const apiService = new ApiService();
    let par = JSON.parse(JSON.stringify(parcels));
    setClick(true);
    apiService.quoteFreight({
      debug: to ? true : false,
      from: cd.address.zip,
      to,
      carrier_service: carrier,
      parcels: par.map((parcel) => {
        if (!parcel['reference']) {
          delete parcel['reference'];
        }
        parcel['width'] = parcel['width'] / 100;
        parcel['height'] = parcel['height'] / 100;
        parcel['length'] = parcel['length'] / 100;
        return parcel;
      })
    }).then((res) => {
      setClick(false);
      if (res.data.length) {
        enqueueSnackbar('Cotação retornada com sucesso', {
          variant: 'success'
        });
        setResults(res.data);
        setError(null);
      } else {
        enqueueSnackbar('Nenhuma cotação retornada', {
          variant: 'error'
        });
        setResults([]);
        setError(res.message ? res.message : null);
      }
    }).catch(() => {
      setClick(false);
    });
  };

  const blurParcelSku = () => {
    const apiService = new ApiService();

    parcels.map((res) => {
      if (res.reference) {
        setQuoting(true);

        apiService.getProducts({
          reference: res.reference
        }, 1, 1)
          .then((product) => {
            if (product.data && product.data.length && product.data[0].width && product.data[0].length && product.data[0].weight && product.data[0].height) {
              setProducts({
                ...products,
                [res.reference]: product.data[0]
              });
            }

            setQuoting(false);
          })
          .catch(() => {
            setQuoting(false);
          });
      }
    });
  };

  const changeVolumes = (ev) => {
    const val = ev.target.value;

    if (val < 1 || val == parcels.length) {
      return;
    }

    let addparcels = [];

    for (let i = 0; i < val; i++) {
      addparcels.push({
        reference: '',
        width: '',
        length: '',
        height: '',
        weight: '',
        quantity: 1,
        cargo_value: ''
      });
    }

    setParcels(addparcels);
  };

  const removeParcel = (ids) => {
    setParcels(parcels.filter((item, id) => (id !== ids)));
  };

  const handleAdd = (result) => {
    setOpenDrawer(true);
    const details = result.log.filter((res) => res.best_rate)[0].details;
    setForm(
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Log de cotação" />
          <CardContent>
            <Table>
              <TableHead>
                <TableCell>
                  Tipo de taxa
                </TableCell>
                <TableCell>
                  Valor Anterior
                </TableCell>
                <TableCell>
                  Percentual
                </TableCell>
                <TableCell>
                  Valor Adicional
                </TableCell>
                <TableCell>
                  Valor Final
                </TableCell>
              </TableHead>
              <TableBody>
                {details.map((log) => {
                  var percentage = (<TableCell>{log.percentage ? log.percentage + '%' : '-'}</TableCell>);
                  var translageFee = {
                    pickup: 'Taxa de Coleta',
                    toll: 'Pedágio',
                    weight: 'Frete peso',
                    gris: 'GRIS',
                    adv: 'ADV (Seguro)',
                    tdc: 'Taxa de despacho',
                    icms: 'ICMS',
                    'additional-price': 'KG Excedente',
                    'increase-table-value': 'Adicional da tabela',
                    'increase-freight-value': 'Adicional da conta',

                    'freight_rule_increase_value': 'Regra de Frete: Aumentar valor',
                    'freight_rule_increase_percentage': 'Regra de Frete: Aumentar percentual',
                    'freight_rule_decrease_value': 'Regra de Frete: Diminuir valor',
                    'freight_rule_decrease_percentage': 'Regra de Frete: Diminuir percentual',
                    'freight_rule_fixed_value': 'Regra de Frete: Valor fixo'
                  }
                  if (log.icms_percentage) {
                    percentage = (<TableCell>{log.icms_percentage ? log.icms_percentage + '%' : '-'}</TableCell>);
                  }
                  if (log.gris_percentage) {
                    percentage = (<TableCell>{log.gris_percentage ? (log.gris_percentage * 100).toFixed(2) + '%' : '-'}</TableCell>);
                  }
                  if (log.adv_percentage) {
                    percentage = (<TableCell>{log.adv_percentage ? (log.adv_percentage * 100) + '%' : '-'}</TableCell>);
                  }

                  if (log.fee == 'freight_rule_increase_percentage' || log.fee == 'freight_rule_decrease_percentage') {
                    percentage = (<TableCell>{log.value ? log.value + '%' : '-'}</TableCell>);
                  }

                  if (log.fee == 'freight_rule_increase_value' || log.fee == 'freight_rule_decrease_value') {
                    var price = <TableCell>{log.value > 0 ? log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</TableCell>
                  } else if (log.fee == 'freight_rule_fixed_value') {
                    var price = <TableCell>{log.value > 0 ? '-' : '-'}</TableCell>
                  } else {
                    var price = <TableCell>{(log.actual_price - log.previous_price) > 0 ? (log.actual_price - log.previous_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</TableCell>
                  }

                  return (
                    <TableRow>
                      <TableCell>{translageFee[log.fee] ? translageFee[log.fee] : log.fee}</TableCell>
                      <TableCell>{log.fee === "weight" ? log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (log.previous_price ? log.previous_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-')}</TableCell>
                      {percentage}
                      {price}
                      <TableCell>{log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const addParcel = () => {
    setParcels(parcels => [...parcels, {
      reference: '',
      width: '',
      length: '',
      height: '',
      weight: '',
      quantity: 1,
      cargo_value: ''
    }]);
  };

  return (
    <Page
      className={classes.root}
      title="Cotações"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box mt={3} style={{ minWidth: 300, margin: 30 }}>
            {form}
          </Box>
        </Drawer>
        <Box mt={3}>
          <Card>
            <CardHeader
              subheader={<small style={{ color: '#e91e63' }}>Em "Valor total do item (R$)", colocar o valor total e não o valor unitário. Ex: um item de R$ 100,00 (Unitário) com quantidade 10, deve ser colocado o valor total de R$ 1000,00.</small>}
              ></CardHeader>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={2} lg={2}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel margin="normal">CD de Origem</InputLabel>
                    <Select
                      label="CD de Origem"
                      margin="normal"
                      name="from_cd_reference"
                      onChange={(ev) => setCd(ev.target.value)}
                      value={cd}
                      required
                      variant="outlined"
                      fullWidth
                    >
                      {cds.map((cd) => <MenuItem value={cd}>{cd.reference}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel margin="normal">Transportadora</InputLabel>
                    <Select
                      label="Transportadora"
                      margin="normal"
                      name="carrier_service"
                      onChange={(ev) => setCarrier(ev.target.value)}
                      value={carrier}
                      required
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem selected>Mais Barata</MenuItem>
                      {carriers.map((carrier) => <MenuItem value={carrier.canonical_name}>{carrier.canonical_name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CEP Destino"
                    margin="normal"
                    name="to"
                    style={{ marginRight: 10 }}
                    onChange={(ev) => setTo(ev.target.value)}
                    value={to}
                    variant="outlined"
                  />
                </Grid>

              </Grid>

              <Table>
                <TableBody>
                  {parcels.map((parcel, idx) => (
                    <>
                      <TableRow key={idx}>
                        <TableCell>
                          <TextField
                            label="SKU"
                            margin="normal"
                            name="reference"
                            style={{ marginRight: 10 }}
                            value={parcel.reference}
                            onChange={(ev) => handleParcel(idx, ev)}
                            onBlur={blurParcelSku}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Altura (cm)"
                            margin="normal"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            name="height"
                            value={parcel.height}
                            disabled={quoting || (products[parcel.reference] && products[parcel.reference].height)}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Largura (cm)"
                            type="number"
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            name="width"
                            value={parcel.width}
                            disabled={quoting || (products[parcel.reference] && products[parcel.reference].width)}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Comprimento (cm)"
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            margin="normal"
                            name="length"
                            disabled={quoting || (products[parcel.reference] && products[parcel.reference].length)}
                            value={parcel.length}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Peso (Kg)"
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            type="number"
                            name="weight"
                            value={parcel.weight}
                            disabled={quoting || (products[parcel.reference] && products[parcel.reference].weight)}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Valor total do item (R$)"
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            margin="normal"
                            name="cargo_value"
                            value={parcel.cargo_value}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ marginRight: 10 }}
                            label="Quantidade"
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            margin="normal"
                            name="quantity"
                            value={parcel.quantity}
                            onChange={(ev) => handleParcel(idx, ev)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Button disabled={idx === 0} onClick={() => removeParcel(idx)}> <Trash /> </Button>
                        </TableCell>
                      </TableRow>
                      {products[parcel.reference] ?
                        (
                          <TableRow key={idx + 1000}>
                            <TableCell>
                              {products[parcel.reference].reference}
                            </TableCell>
                            <TableCell>
                              {products[parcel.reference].height * 100}cm
                            </TableCell>
                            <TableCell>
                              {products[parcel.reference].width * 100}cm
                            </TableCell>
                            <TableCell>
                              {products[parcel.reference].length * 100}cm
                            </TableCell>
                            <TableCell>
                              {products[parcel.reference].weight.toLocaleString('pt-BR')}kg
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {products[parcel.reference].quantity * parcel.quantity}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ) : null}

                      {products[parcel.reference] && products[parcel.reference].property ?
                        products[parcel.reference].property.map((res) => {
                          return (
                            <TableRow key={idx + 1001}>
                              <TableCell>
                                {res.reference}
                              </TableCell>
                              <TableCell>
                                {res.height * 100}cm
                            </TableCell>
                              <TableCell>
                                {res.width * 100}cm
                            </TableCell>
                              <TableCell>
                                {res.length * 100}cm
                            </TableCell>
                              <TableCell>
                                {res.weight.toLocaleString('pt-BR')}kg
                            </TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                {res.quantity * parcel.quantity}
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          );
                        }) : null}
                    </>
                  ))}
                </TableBody>
              </Table>
              <br />
              <Button onClick={addParcel}>
                <Plus /> Adicionar mais um item.
              </Button>


            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={quoteFreight}
                disabled={click}
              >
                Simular
              </Button>

              {results.length && to ?
                (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={newOrder}
                    className={classes.successButton}
                  >
                    CRIAR REMESSA
                  </Button>
                ) : null}
            </Box>
          </Card>
        </Box>
      </Container>


      {results.length || errors ? (
        <Container maxWidth={false}>
          <Box mt={3}>
            <Card>
              <CardHeader title="Resultados da simulação" />
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Valores Declarados
                      </TableCell>
                      <TableCell>
                        Transportadora
                      </TableCell>
                      <TableCell>
                        Custo (R$)
                      </TableCell>
                      <TableCell>
                        Preço cliente final (R$)
                      </TableCell>
                      <TableCell>
                        Estimativa de entrega
                      </TableCell>
                      <TableCell>
                        Região de Destino
                      </TableCell>
                      {to ? (
                        <TableCell>
                          Detalhes
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((result) => (
                      <TableRow
                        hover
                        key={result.id}
                      >
                        <TableCell>
                        <strong>Valor da nota: {result.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong> <br/>
                        <strong>Peso cubado: {result.cubed_weight.toLocaleString('pt-BR')}kg</strong> <br/>
                        </TableCell>
                        <TableCell>
                          {result.carrier_canonical_name}
                        </TableCell>
                        <TableCell>
                          <strong>R$ {result.original_estimated_cost ? result.original_estimated_cost .toLocaleString('pt-BR') : result.estimated_cost .toLocaleString('pt-BR')}</strong>
                        </TableCell>
                        <TableCell>
                          <strong>R$ {result.estimated_cost.toLocaleString('pt-BR')}</strong>
                        </TableCell>
                        <TableCell>
                          <strong>{result.delivery_days} dias úteis</strong>
                        </TableCell>
                        <TableCell>
                          {result.region}
                        </TableCell>
                        {to ? (
                          <TableCell>
                            <Button onClick={() => handleAdd(result)} title="Detalhes">
                              <Eye />
                            </Button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}

                    {errors ? <TableRow
                      hover
                      key={"error"}
                    >
                      <TableCell colSpan={6}>
                        <strong style={{ color: 'red' }}>{errors}</strong>
                      </TableCell>
                    </TableRow> : null}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Box>
        </Container>
      ) :
        (click ? <Container maxWidth={false}>
          <Box mt={3}>
            <Card>
              <CardHeader title="Resultados da simulação" />
              <CardContent>
                {"Nenhuma cotação encontrada para esse destino"}
              </CardContent>
            </Card>
          </Box>
        </Container>
          : null)}
    </Page>
  );
};

export default withSnackbar(QuoteView);
