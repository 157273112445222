import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewDistributionCenterView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);

  return (
    <Page
      className={classes.root}
      title="Novo - Centro de distribuição"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton={true} />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Form
                initialValues={{
                  reference: 'CD1',
                  expedition_time: 0,
                  name: 'CD ' + getActualCompany().name,
                  address_name: '',
                  address_federal_tax_id: '',
                  address_phone: '',
                  address_email: '',
                  address_zip: '',
                  address_street1: '',
                  address_number: '',
                  address_street2: '',
                  address_neighborhood: '',
                  address_city: '',
                  address_state: '',
                  address_country: 'BRA',
                 
                  pickup_window_monday_time1: '09:00',
                  pickup_window_monday_time2: '12:00',
                  pickup_window_monday_time3: '14:00',
                  pickup_window_monday_time4: '17:00',

                  pickup_window_tuesday_time1: '09:00',
                  pickup_window_tuesday_time2: '12:00',
                  pickup_window_tuesday_time3: '14:00',
                  pickup_window_tuesday_time4: '17:00',
                  pickup_window_tuesday_time4: '17:00',

                  pickup_window_wednesday_time1: '09:00',
                  pickup_window_wednesday_time2: '12:00',
                  pickup_window_wednesday_time3: '14:00',
                  pickup_window_wednesday_time4: '17:00',

                  pickup_window_thursday_time1: '09:00',
                  pickup_window_thursday_time2: '12:00',
                  pickup_window_thursday_time3: '14:00',
                  pickup_window_thursday_time4: '17:00',

                  pickup_window_friday_time1: '09:00',
                  pickup_window_friday_time2: '12:00',
                  pickup_window_friday_time3: '14:00',
                  pickup_window_friday_time4: '17:00',

                }}
                submitText="CADASTRAR"
                onSubmit={(values) => {
                  const apiService = new ApiService();
                  apiService
                    .newDistributionCenter({
                      reference: values.reference,
                      name: values.name,
                      address: {
                        residential: false,
                        name: values.address_name,
                        federal_tax_id: values.address_federal_tax_id,
                        phone: values.address_phone,
                        email: values.address_email,
                        zip: values.address_zip,
                        street1: values.address_street1,
                        number: values.address_number,
                        street2: values.address_street2,
                        neighborhood: values.address_neighborhood,
                        city: values.address_city,
                        state: values.address_state,
                        country: 'BRA'
                      },
                      pickup_window: {
                        monday: {
                          time1: values.pickup_window_monday_time1,
                          time2: values.pickup_window_monday_time2,
                          time3: values.pickup_window_monday_time3,
                          time4: values.pickup_window_monday_time4
                        },
                        tuesday: {
                          time1: values.pickup_window_tuesday_time1,
                          time2: values.pickup_window_tuesday_time2,
                          time3: values.pickup_window_tuesday_time3,
                          time4: values.pickup_window_tuesday_time4
                        }, 
                        wednesday: {
                          time1: values.pickup_window_wednesday_time1,
                          time2: values.pickup_window_wednesday_time2,
                          time3: values.pickup_window_wednesday_time3,
                          time4: values.pickup_window_wednesday_time4
                        }, 
                        thursday: {
                          time1: values.pickup_window_thursday_time1,
                          time2: values.pickup_window_thursday_time2,
                          time3: values.pickup_window_thursday_time3,
                          time4: values.pickup_window_thursday_time4
                        }, 
                        friday: {
                          time1: values.pickup_window_friday_time1,
                          time2: values.pickup_window_friday_time2,
                          time3: values.pickup_window_friday_time3,
                          time4: values.pickup_window_friday_time4
                        }
                      }
                    })
                    .then((res) => {
                      enqueueSnackbar('CD cadastrado com sucesso', {
                        variant: 'success',
                      });
                      navigate('/settings/distribution-centers');
                    })
                    .catch((res) => {
                      enqueueSnackbar('Ocorreu um erro:' + res.message, {
                        variant: 'error',
                      });
                    });

                  return true;
                }}
              />
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewDistributionCenterView);
