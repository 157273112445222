import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  Box
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ExternalLink, Flag, MapPin } from 'react-feather';
import { LoadingButton } from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import AvatarGroup from '@atlaskit/avatar-group';
import { FlagRounded, WarningRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const OrdersList = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(props.limit ? props.limit : 20);
  const [page, changePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(props.pickupId ? { pickup_id: props.pickupId } : props.filters ? props.filters : null);
  const [carriers, setCarriers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  const [selectedRows, setSelectedRowsIds] = useState(props.selectedRows ? props.selectedRows : []);

  const handleSelectAll = (event) => {
    let rowIds;
    let entities = [];

    if (event.target.checked) {
      rowIds = orders.map((order) => order.id);
      entities = orders;
    } else {
      rowIds = [];
      entities = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
    if (props.onSelectEntities) {
      props.onSelectEntities(entities);
    }
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);

    if (props.onSelect) {
      props.onSelect(rowIds);
    }

    if (props.onSelectEntities) {
      props.onSelectEntities(orders.filter((order) => rowIds.indexOf(order.id) !== -1));
    }
  };


  const [statuses, setStatuses] = useState([
    { value: 12, label: 'Aguardando Cotação', badge: 'removed' },
    { value: 11, label: 'Aguardando Aprovação', badge: 'moved' },
    { value: 1, label: 'Aguardando Nota', badge: 'new' },
    { value: 2, label: 'Aguardando aceite', badge: 'inprogress' },
    { value: 3, label: 'Em trânsito', badge: 'success' },
    { value: 4, label: 'Saiu para entrega', badge: 'success' },
    { value: 5, label: 'Entregue', badge: 'success' },
    { value: 6, label: 'Coleta aceita', badge: 'success' },
    { value: 7, label: 'Retornando', badge: 'moved' },
    { value: 8, label: 'Falha', badge: 'removed' },
    { value: 9, label: 'Cancelado', badge: undefined },
    { value: 10, label: 'Erro', badge: 'removed' },
  ]);

  const deadlineStatus = [
    { value: 'delayed', label: 'Entregas Atrasadas' },
    { value: 'today', label: 'Entregas para hoje' },
    { value: 'delivered_early', label: 'Entregues antes do prazo' },
    { value: 'delivered_late', label: 'Entregues com atraso' }
  ];

  const channelIconsUrl = (channel) => {
    var urls = {
      madeiramadeira: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      tiny: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      bling: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      tray: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      b2w: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      magalu: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      viavarejo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      woo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      'tray-corp': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      'fbitsws': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      omie: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
      simplo7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      leroy: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      nuvemshop: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
      mercadolivre: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
    }
    return urls[channel.toLocaleLowerCase()] || null;
  }

  const handleFilters = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  }

  const getCarriers = () => {
    apiService
      .getCarriers(true)
      .then((res) => setCarriers(res.data));
  };

  const getCompanies = () => {
    apiService
      .getCompanies()
      .then((res) => setCompanies(res.data));
  };

  const reloadOrders = (filters, sort) => {
    setIsLoading(true);
    apiService
      .getOrders(filters, limit, page, props.isPickupPage)
      .then((res) => {
        setOrders(res.data);
        setTotalRows(res.total);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reloadOrders(filters);
  }, [filters, limit, page, props.reloadOrders]);

  useEffect(() => {
    getCarriers(true);
    getCompanies();
  }, []);

  return (
    <>

      <DataTable
        rows={orders}
        caption={props.caption}
        filters={
          [
            {
              placeholder: 'Buscar por pedido, nota ou ID',
              type: 'search',
              filter: 'reference',
              // onBlur: handleFilters,
              onEnter: handleFilters,
            },
            props.miniVersion ? null : {
              placeholder: 'Empresa',
              type: 'select',
              filter: 'company',
              options: companies.map((company) => ({ value: company.id, label: `${company.id} - ${company.name}` })),
              onChange: handleFilters
            },
            {
              placeholder: 'Status',
              type: 'select',
              filter: 'status',
              options: statuses,
              onChange: handleFilters
            },
            {
              placeholder: 'Transportadora',
              type: 'select',
              filter: 'carrier',
              options: carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` })),
              onChange: handleFilters
            },
            {
              placeholder: 'Prazo de Entrega',
              type: 'select',
              filter: 'deadline',
              width: 200,
              options: deadlineStatus,
              onChange: handleFilters
            }
          ].filter(res => res)
        }
        head={{
          key: `table-order`,
          isHighlighted: false,
          cells: [
            {
              key: 'selector',
              width: 10,
              content: <Checkbox
                onClick={handleSelectAll}
                name="select-all-basic"
                size="large"
              />
            },
            {
              key: 'id',
              content: 'ID',
              isSortable: false,
            },
            props.miniVersion ? null : {
              key: 'company',
              content: 'Empresa',
              isSortable: false,
            },
            {
              key: 'reference',
              content: 'Número Pedido / NF',
              isSortable: false,
            },
            {
              key: 'carrier',
              content: 'Transportadora',
              isSortable: false
            },
            {
              key: 'addressee',
              content: 'Destinatário',
              isSortable: false,
              width: 20
            },
            {
              key: 'status',
              width: 20,
              content: 'Status',
              isSortable: false
            },
            // {
            //   key: 'property',
            //   content: 'Integrações',
            //   isSortable: false
            // },
            props.miniVersion ? null : {
              key: 'delivery_date',
              content: 'Previsão de Entrega',
              isSortable: false
            },
            {
              key: 'created_at',
              content: 'Criado em',
              isSortable: false
            },
            {
              key: 'actions',
              content: '',
              isSortable: false
            }
          ].filter(res => res)
        }}
        rows={orders.map((order, index) => ({
          key: `row-${index}-${order.id}`,
          isHighlighted: selectedRows.indexOf(order.id) !== -1,
          cells: [
            {
              key:  `${order.id}-selector`,
              content: <Checkbox
                onClick={() => handleSelectOne(order.id)}
                key={`selected-${order.id}`}
                name="select-all-one"
                isChecked={selectedRows.indexOf(order.id) !== -1}
                size="large"
              />
            },
            {
              key: order.id,
              content: order.id
            },
            props.miniVersion ? null : {
              key: order.company_name,
              content: <strong>{order.company_name}</strong>
            },,
            {
              key: order.reference,
              content: <>
              <Tooltip title={"Número do pedido"}><strong>{order.reference}</strong></Tooltip> 
              {order.shipments[0]?.invoice?.number ? <><br/><Tooltip title={"Número da nota fiscal"}><strong>{order.shipments[0]?.invoice?.number}</strong></Tooltip></> : null}
              </>
            },
            {
              key: order.carrier?.canonical_name,
              content: <strong>{order.carrier?.canonical_name?.toUpperCase()}</strong>
            },
            {
              key: order.to_address?.id,
              content: <>
                <Tooltip title={order.to_address?.name}><strong style={{display: 'block', width: '140px', overflow:'hidden', textOverflow: 'ellipsis'}}>{order.to_address?.name}</strong></Tooltip> 
                <small style={{ display: 'flex', alignItems: 'center' }}><MapPin style={{ width: 10, marginRight: 5 }} /> {order.to_address.city} / {order.to_address.state}</small>
              </>
            },
            {
              key: 'status',
              content: <StatusIcon
                type={statuses.filter((st) => st.value == order.shipments[0]?.status?.id)[0]?.badge}
                title={order.shipments[0]?.status?.description}
                description={order.shipments[0]?.status?.description} />
              // content: [...new Set(order.shipments.map((ship) => ship.status.description))].join(', ')
            },
            // {
            //   key: 'integration',
            //   content: <div style={{display: 'flex', alignItems: 'center'}}>
            //     <AvatarGroup 
            //     appearance="stack" 
            //     size="small"
                
            //     data={[
            //       order.property?.channel ? {channel: order.property.channel, channel_id: order.property.channel_id, channel_entity_id: order.property.channel_entity_id} : null,
            //       order.property?.marketplace ? {channel: order.property.marketplace, channel_id: null, channel_entity_id: order.property.marketplace_order_number} : null,
            //     ].filter(res => res).map(res => ({
            //       email: res.channel,
            //       key: res.channel,
            //       name: `Canal: ${res.channel}. Pedido: ${res.channel_entity_id}`,
            //       href: '#',
            //       src: channelIconsUrl(res.channel),
            //       borderColor: "#242424"
            //     }))} />
            //   </div>
            // },
            props.miniVersion ? null : {
              key: 'delivery_date',
              content:  order.delivered_at && order?.delivery?.delivery_date
              ? <div style={{textAlign: 'right'}}>
                  <div style={{ textDecoration: 'line-through', color: '#aaa' }}>{order?.delivery?.delivery_date && moment(order?.delivery?.delivery_date).format('DD/MM/YYYY HH:mm')}</div>
                  
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {order.deadline === 'delivered_late' ? <Tooltip title="Entregue depois do prazo"><FlagRounded style={{ color: 'red', width: '18px' }} /></Tooltip> : null}
                  {order.deadline === 'delivered_early' ? <Tooltip title="Entregue antes do prazo"><FlagRounded style={{ color: '#8bc34a', width: '18px' }} /></Tooltip> : null}

                  <Tooltip title={`Entrega realizada pela transportadora em ${moment(order.delivered_at).format('DD/MM/YYYY HH:mm')}`}><div style={{ color: '#145e3f' }}>{moment(order.delivered_at).format('DD/MM/YYYY HH:mm')}</div></Tooltip>
                  </div>
                
                </div> : order?.delivery?.delivery_date ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{order.deadline === 'delayed' ? <Tooltip title="Pedido atrasado"><WarningRounded style={{ color: 'red', width: '18px' }} /></Tooltip> : null} {moment(order?.delivery?.delivery_date).format('DD/MM/YYYY HH:mm')}</div> 
              : ''
            },
            {
              key: 'created_at',
              content: <Tooltip title={moment(order.created_at).format()}><span>{moment(order.created_at).format('DD/MM HH:mm')}</span></Tooltip>
            },
            {
              key: 'actions',
              content: <>
                {!props.noButtons && <DropdownMenu trigger="Ações">
                  <DropdownItemGroup>
                    <DropdownItem onClick={() => navigate(`/orders/${order.id}`)}>
                      <Box display="flex" alignItems="center">
                        <ExternalLink style={{ width: 14, marginRight: 10 }} /> Abrir
                      </Box>
                    </DropdownItem>

                    {props.buttons ? props.buttons.map((button) => <Tooltip
                      title={button.description}
                    >
                      <DropdownItem onClick={() => button.onClick(order)}>
                        <Box display="flex" alignItems="center">{button.icon({ width: 14, marginRight: 10 })} {button.title}</Box>
                      </DropdownItem>
                    </Tooltip>) : null}

                  </DropdownItemGroup>
                </DropdownMenu>}
              </>
            }
          ].filter(res => res)
        }))}
        limit={limit}
        page={page}
        height={props.height ? props.height : 500}
        changePage={changePage}
        totalRows={totalRows}
        isLoading={isLoading}
      />
    </>
  );
};


export default OrdersList;
