import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Close } from '@material-ui/icons';
import { Check, Edit, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, freightTables, changeLimit, changePage, total, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Região Origem
                </TableCell>
                <TableCell>
                  Centros de distribuição
                </TableCell>
                <TableCell>
                  Transportadora
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Fator de Cubagem
                </TableCell>
                <TableCell>
                  Ativada
                </TableCell>
                <TableCell>
                  Data de Criação
                </TableCell>
                <TableCell>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {freightTables.map((freightTable) => (
                <TableRow
                  hover
                  key={freightTable.id}
                >
                  <TableCell>
                    {freightTable.id}
                  </TableCell>
                  <TableCell>
                    {freightTable.origin}
                  </TableCell>
                  <TableCell>
                    {freightTable.distribution_centers.map((cd) => <><strong><a href={`/settings/distribution-centers/edit/${cd.id}`} target="_blank">{cd.reference}</a></strong><br/></>)}
                    {freightTable.distribution_centers.length == 0 ? 'Todos' : ''}
                  </TableCell>
                  <TableCell style={{
                    textOverflow: 'ellipsis',
                    maxWidth: '180px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}
                  >
                    {freightTable.carrier.name}
                  </TableCell>
                  <TableCell>
                    {freightTable.name}
                  </TableCell>
                  <TableCell>
                    {freightTable.cubage}
                  </TableCell>
                  <TableCell>
                    {freightTable.active ? <Check title="Ativo" /> : <Close title="Inativo" /> }
                  </TableCell>
                  <TableCell>
                    {moment(freightTable.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to={"/settings/freight-table/edit/" + freightTable.id}>
                      <Edit />
                    </Button>
                    <Button component={Link} to={"/settings/freight-table/remove/" + freightTable.id}>
                      <Trash />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  freightTables: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
};

export default Results;
