import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Close } from '@material-ui/icons';
import { Check } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, carriers, total, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Código
                </TableCell>
                <TableCell>
                  CNPJ
                </TableCell>
                <TableCell>
                  Inscrição Estadual
                </TableCell>
                <TableCell>
                  Ativa
                </TableCell>
                <TableCell>
                  Cadastrado em
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carriers.slice(0, limit).map((carrier) => (
                <TableRow
                  hover
                  key={carrier.id}
                >
                  <TableCell>
                   {carrier.id}
                  </TableCell>
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={carrier.image}
                      >
                        {getInitials(carrier.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {carrier.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {carrier.canonical_name.toUpperCase()}
                  </TableCell>
                  <TableCell>
                    {carrier.federal_tax_id}
                  </TableCell>
                  <TableCell>
                    {carrier.state_tax_id}
                  </TableCell>
                  <TableCell>
                    {carrier.active ? <Check title="Ativo" /> : <Close title="Inativo" /> }
                  </TableCell>
                  <TableCell>
                    {moment(carrier.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  carriers: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
};

export default Results;
