import React, { useState } from 'react';

import { Field, HelperMessage } from '@atlaskit/form';
import Button from '@atlaskit/button/standard-button';
import Textfield from '@atlaskit/textfield';
import OrdersList from 'src/views/orders/v2/OrdersView/List';
import { Box, Divider, Typography, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from '../../../../layouts/DashboardLayout/Header';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';
import moment from 'moment';
import { MapPin } from 'react-feather';

import {
  makeStyles,
  Tooltip,
  Container,
  ButtonGroup,
  IconButton
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default function NewPickup() {
  const classes = useStyles();
  const apiService = new ApiService();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [step, setStep] = useState(1);
  const [note, setNote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const groupedByCarriers = selectedOrders ? groupBy(selectedOrders, 'carrier_id') : {};
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = (ev) => {
    setIsLoading(true);
    return apiService
      .newPickup({
        note: note,
        orders: selectedOrders.map((order) => order.reference)
      })
      .then((res) => {
        enqueueSnackbar('Coleta solicitada com sucesso, aguarde a confirmação das transportadoras em algumas horas.', {
          variant: 'success',
        });
        setIsLoading(false);
        navigate('/pickups');
      })
      .catch((res) => {
        setIsLoading(false);
        enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
          variant: 'error',
        });
      });
  }

  return <Page
    className={classes.root}
    title="Nova Coleta - Coletas - Flixlog"
  >
    <Header
      help="https://flixlog.readme.io/"
      breadcrumbs={[
        {
          text: 'Início',
          link: '/'
        },
        {
          text: 'Coletas',
          link: '/pickups'
        },
        {
          text: 'Nova Coleta',
          link: ''
        }
      ]}
      buttons={[
        {
          text: 'Voltar',
          appearance: 'subtle',
          onClick: () => window.location.href = '/pickups'
        }
      ]}
    />

    <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center' }}>
      <Box maxWidth={1366}>

        <form>

          {step === 1 ? <>
            <Typography variant="h1" color="textPrimary" style={{
              fontFamily: 'Syne',
              fontSize: 30,
              fontWeight: 'bold',
              marginTop: 20
            }}>Solicitar Coleta</Typography>
            <p>Selecione os pedidos que deseja que sejam coletados em breve.</p>
            <br />
            <br />

            <Field id="note" name="note" label="Observação">
              {({ fieldProps }) => (
                <>
                  <Textfield
                    {...fieldProps}
                    value={note}
                    onChange={(ev) => setNote(ev.target.value)}
                  />
                  <HelperMessage>
                    Caso necessário, você pode descrever aqui uma mensagem para a transportadora a respeito dessa coleta.
                  </HelperMessage>
                </>
              )}
            </Field>

            <Box marginTop="20px" marginBottom="20px">
              <Divider />
            </Box>

            <Box marginBottom="10px" display="flex" alignItems="center" justifyContent="space-between">
              <h3>Selecionar pedidos para coleta</h3>
            </Box>

            <OrdersList
              filters={{ status: 2 }}
              limit={500}
              isPickupPage
              height={'100%'}
              onSelectEntities={setSelectedOrders}
              selectedRows={selectedOrders.map((order) => order.id)}
              noButtons
            />
          </> : <Grid container>
            <Grid item lg={12}>
              <Typography variant="h1" color="textPrimary" style={{
                fontFamily: 'Syne',
                fontSize: 30,
                fontWeight: 'bold',
                marginTop: 20
              }}>Confirmar coleta</Typography>
              <p>Quase lá! Agora que você já selecionou os pedidos para serem coletados, você precisa apenas revisar os dados e ajustar o que for necessário.</p>
              <br />
              <br />

              <Field id="note" name="note" label="Observação">
                {({ fieldProps }) => <Textfield
                  {...fieldProps}
                  value={note}
                  readOnly
                  disabled
                  isReadOnly
                />}
              </Field>

              <Box marginTop="20px" marginBottom="20px">
                <Divider />
              </Box>

              {Object
                .keys(groupedByCarriers)
                .map((carrierId) => {
                  const ordersByCarrier = groupedByCarriers[carrierId] || [];
                  return <>

                    <Card>
                      <CardHeader title={`Coleta para ${ordersByCarrier[0]?.carrier?.canonical_name?.toUpperCase()}`} />
                      <CardContent>
                        <DataTable
                          rows={ordersByCarrier}
                          head={{
                            key: `table-order-pickup`,
                            isHighlighted: false,
                            cells: [
                              {
                                key: 'id',
                                content: 'ID',
                                isSortable: false,
                              },
                              {
                                key: 'reference',
                                content: 'Número Pedido',
                                isSortable: false,
                              },
                              {
                                key: 'invoice_number',
                                content: 'Número NF',
                                isSortable: false
                              },
                              {
                                key: 'carrier',
                                content: 'Transportadora',
                                isSortable: false
                              },
                              {
                                key: 'addressee',
                                content: 'Destinatário',
                                isSortable: false
                              },
                              {
                                key: 'created_at',
                                content: 'Criado em',
                                isSortable: false
                              }
                            ]
                          }}
                          rows={ordersByCarrier.map((order, index) => ({
                            key: `row-${index}-${order.id}`,
                            cells: [
                              {
                                key: order.id,
                                content: order.id
                              },
                              {
                                key: order.reference,
                                content: <strong>#{order.reference}</strong>
                              },
                              {
                                key: order.invoice?.number,
                                content: <strong>{order.shipments[0]?.invoice?.number}</strong>
                              },
                              {
                                key: order.carrier?.canonical_name,
                                content: <strong>{order.carrier?.canonical_name?.toUpperCase()}</strong>
                              },
                              {
                                key: order.to_address?.id,
                                content: <>
                                  <strong>{order.to_address?.name}</strong> <br />
                                  <small style={{ display: 'flex', alignItems: 'center' }}><MapPin style={{ width: 10, marginRight: 5 }} /> {order.to_address.city} / {order.to_address.state}</small>
                                </>
                              },
                              {
                                key: 'created_at',
                                content: <Tooltip title={moment(order.created_at).format()}><span>{moment(order.created_at).format('DD/MM HH:mm')}</span></Tooltip>
                              }
                            ]
                          }))}
                          limit={1000}
                          page={1}
                          height={'auto'}
                          totalRows={ordersByCarrier.length}
                        />
                      </CardContent>
                    </Card>
                  </>
                })}
            </Grid>
          </Grid>}

          <Box display="flex" alignItems="center" width="100%" position="absolute" zIndex="2" boxShadow="0 0 5px #ddd" bottom="0" padding="10px" left="0" right="0" aligItems="center" style={{ backgroundColor: '#fff' }}>
            <span style={{ width: '100%', fontWeight: 'bold', color: '#242424' }}>Notas Selecionadas: ({selectedOrders.length})</span>
            {step === 1 && <Button appearance="primary" type="button" onClick={() => setStep(2)} isDisabled={!selectedOrders.length} title={!selectedOrders.length ? 'Selecione pelo menos um pedido' : 'Avançar para revisão'}>
              Avançar
            </Button>}
            {step === 2 && <>
              <Button appearance="subtle" isDisabled={isLoading} type="button" onClick={() => setStep(1)} title={'Voltar para a parte de selecionar pedidos'}>
                Voltar
              </Button>

              <Button appearance="primary" type="button" onClick={onSubmit} isDisabled={!selectedOrders.length || isLoading}>
                Solicitar Coletas
              </Button>
            </>}

          </Box>

        </form>
      </Box>
    </Container>
  </Page>;
};
