import React, { useEffect, useState } from 'react';
import Form from "@rjsf/material-ui";
import { JsonToTable } from "react-json-to-table";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Drawer,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Check, Eye, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const IntegrationsListViewActive = () => {
  const classes = useStyles();
  const [channels, setChannels] = useState([]);
  const [companyChannels, setCompanyChannels] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [form, setForm] = useState(null);
  const apiService = new ApiService();

  useEffect(() => {
    apiService
      .getChannels(1)
      .then((res) => {
        setChannels(res.data);
      });

    apiService
      .getCompanyChannels()
      .then((res) => {
        setCompanyChannels(res.data);
      });
  }, []);

  const log = (type) => console.log.bind(console, type);

  const submit = (channel, formData) => {
    setOpenDrawer(false);
    apiService
      .newCompanyChannel({
        channel: {
          id: channel.id
        },
        data: formData
      })
      .then(() => {
        apiService
          .getCompanyChannels()
          .then((res) => {
            setCompanyChannels(res.data);
          });
      });
  };

  const handleAdd = (channel) => {
    if (typeof channel.layout.oauth !== undefined && channel.layout.oauth) {
      return window.location.href = channel.layout.oauth;
    }

    setOpenDrawer(true);
    setForm(
      <Form
        schema={channel.layout}
        onChange={log('changed')}
        onSubmit={(ev) => submit(channel, ev.formData)}
        onError={log('errors')}
      />
    );
  };

  const previewChannel = (companyChannel) => {
    setOpenDrawer(true);
    setForm(<JsonToTable json={companyChannel.property} />);
  };

  return (
    <Page
      className={classes.root}
      title="Integrações Ativas"
    >
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box mt={3} style={{ minWidth: 300, margin: 30 }}>
          {form}
        </Box>
      </Drawer>
      <Container maxWidth={false}>
      <Toolbar title="Integrações ativas" />
      <Box mt={3}>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    #
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    Integração
                  </TableCell>
                  <TableCell>
                    Token
                  </TableCell>
                  <TableCell>
                    Habilitada
                  </TableCell>
                  <TableCell>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              {companyChannels.length ? (
                <TableBody>
                  {companyChannels.map((channel) => (
                    <TableRow
                      hover
                      key={channel.id}
                    >
                      <TableCell>
                        {channel.id}
                      </TableCell>
                      <TableCell>
                        <Box
                          alignItems="center"
                          display="flex"
                        >
                          
                                    <img src={channel.channel.icon} style={{width: 40}} />

                        </Box>
                      </TableCell>
                      <TableCell>
                        {channel.channel.name}
                      </TableCell>
                      <TableCell>
                        {channel.token.token}
                      </TableCell>
                      <TableCell>
                        <Check color="green" />
                      </TableCell>
                      <TableCell>
                        <Button title="Detalhes" component={Link}  to={`/settings/integrations/${channel.id}/detail`}>
                          <Eye />
                        </Button>
                        <Button component={Link} to={`/settings/integrations/remove/${channel.id}`}>
                          <Trash />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                  <TableBody>
                    <TableRow
                      
                    >
                      <TableCell colSpan={5}>
                        Nenhuma integração habilitada ainda.
                      </TableCell>

                    </TableRow>
                  </TableBody>
                )
              }
            </Table>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default IntegrationsListViewActive;
