import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextareaAutosize,
  makeStyles, FormControl, InputLabel, Select, MenuItem, TextField, Switch, Input
} from '@material-ui/core';

import ApiService from 'src/common/apiService';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    width: '100%'
  }
}));

const StepOne = ({ className, setLayout, ...rest }) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [tableName, setTableName] = useState(null);
  const [tipo, setTipoCorredor] = useState('region');
  const [expiredAt, setExpiredAt] = useState(null);
  const [active, setActive] = useState(false);
  const [regionFlixlogType, setRegionFlixlogType] = useState(0);
  const [company, setCompany] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [destinos, setDestinos] = React.useState([]);
  const [regioesCustomizadas, setRegioesCustomizadas] = React.useState([]);
  const [todosDestinos, setTodosDestinos] = React.useState([]);
  const [gris, setGris] = React.useState(null);
  const [minGris, setMinGris] = React.useState(null);
  const [minAdv, setMinAdv] = React.useState(null);
  const [toll, setToll] = React.useState(null);
  const [icms, setAplicarICMS] = React.useState(0);
  const [adv, setAdv] = React.useState(null);
  const [tdc, setTdc] = React.useState(null);
  const [rangeWeights, setRangeWeights] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(false);

  useEffect(() => {
    apiService.getCompanies().then((res) => setCompanies(res.data));
    apiService.getCarriers(true).then((res) => setCarriers(res.data));
    apiService.getRegions().then((res) => setTodosDestinos(res.data));
  }, []);

  const generateLayout = () => {
    setLayout({
      company_id: company,
      carrier_id: carrier,
      name: tableName,
      type: tipo,
      expired_at: expiredAt,
      active,
      region_type: regionFlixlogType,
      regions: destinos,
      custom_regions: regioesCustomizadas,
      gris,
      minGris,
      toll,
      tdc,
      icms,
      adv,
      minAdv,
      range_weights: rangeWeights
    });
    // setDisableButton(true);
  };

  console.log(regioesCustomizadas);
  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Etapa 1 - Cabeçalho da tabela"
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <FormControl variant="outlined" className={classes.formControl} style={{marginRight: 20, width: 200}}>
                  <InputLabel id="demo-simple-select-outlined-label">Cliente</InputLabel>
                  <Select
                    style={{width: '100%'}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={company}
                    onChange={(ev) => setCompany(ev.target.value)}
                    label="Age"
                  >
                    {companies.map((res) => <MenuItem value={res.id}>{res.name} - {res.federal_tax_id}</MenuItem>)}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl} style={{marginRight: 20, width: 200}}>
                  <InputLabel>Transportadora</InputLabel>
                  <Select
                    label="Transportadora"
                    style={{width: '100%'}}
                    value={carrier}
                    onChange={(ev) => setCarrier(ev.target.value)}
                  >
                    {carriers.map((res) => <MenuItem value={res.id}>{res.name} - {res.federal_tax_id}</MenuItem>)}
                  </Select>
                </FormControl>

                <TextField label="Nome da tabela" variant="outlined" value={tableName} onChange={(ev) => setTableName(ev.target.value)} style={{marginRight: 20}} />
                <TextField InputLabelProps={{ shrink: true }} type="date" label="Data de expiração" variant="outlined" value={expiredAt} onChange={(ev) => setExpiredAt(ev.target.value)} style={{marginRight: 20}} />
                <Switch
                    checked={active}
                    labelId="tabela-ativa"
                    onChange={(ev) => setActive(ev.target.checked)}
                    color="primary"
                    title="Ativa?"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />

                  <FormControl variant="outlined" style={{marginRight: 20, width: 200}}>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo de corredor</InputLabel>
                    <Select
                      label="Tipo de corredor"
                      style={{width: '100%'}}
                      value={tipo}
                      onChange={(ev) => setTipoCorredor(ev.target.value)}
                    >
                      <MenuItem value="region">Por região</MenuItem>
                      <MenuItem value="city">Por cidade</MenuItem>
                    </Select>
                  </FormControl>
            </Grid>
            <Grid item xs={2} style={{marginRight: 20}}>
              
            </Grid>
            <Grid item xs={2}>
              
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Etapa 2 - Regiões"
          subheader="Descreva quais as regiões da transportadora"
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{marginBottom: 20}}>

              <FormControl variant="outlined" style={{marginRight: 20, width: 200, marginBottom: 30}}>
                <InputLabel id="demo-simple-select-outlined-label1" InputLabelProps={{ shrink: true }}>Regiões no padrão ?</InputLabel>
                <Select
                  style={{width: '100%'}}
                  label="Regiões no padrão Flixlog?"
                  value={regionFlixlogType}
                  onChange={(ev) => setRegionFlixlogType(ev.target.value)}>
                  <MenuItem value={1}>Sim</MenuItem>
                  <MenuItem value={0}>Não</MenuItem>
                </Select>
              </FormControl>

              {regionFlixlogType ? (<FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label1" InputLabelProps={{ shrink: true }}>Destinos</InputLabel>
                <Select
                  label="Destinos"
                  id="demo-mutiple-chip"
                  multiple
                  value={destinos}
                  onChange={(ev) => setDestinos(ev.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {todosDestinos.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>) : <TextareaAutosize placeholder="SP Interior I, SP ABC..." label="Digite as regiões separadas por virgula" variant="outlined" onChange={(ev) => setRegioesCustomizadas(ev.target.value.split(/\n/))} style={{width: '100%'}} />}

            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Etapa 3 - Generalidades"
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={6} style={{marginBottom: 20}}>
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="0,05" label="GRIS (%)" variant="outlined" value={gris} onChange={(ev) => setGris(ev.target.value)} style={{marginRight: 20}} />
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="1,00" label="GRIS Mínimo (R$)" variant="outlined" value={minGris} onChange={(ev) => setMinGris(ev.target.value)} style={{marginRight: 20}} />
            </Grid>
            <Grid item xs={6} style={{marginBottom: 20}}>
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="0,20" label="ADV (%)" variant="outlined" value={adv} onChange={(ev) => setAdv(ev.target.value)} style={{marginRight: 20}} />
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="2,20" label="ADV (R$)" variant="outlined" value={minAdv} onChange={(ev) => setMinAdv(ev.target.value)} style={{marginRight: 20}} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={{marginBottom: 20}}>
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="3,00" label="Pedágio (R$)" variant="outlined" value={toll} onChange={(ev) => setToll(ev.target.value)} style={{marginRight: 20}} />
              <FormControl variant="outlined" style={{marginRight: 20, width: 200}}>
                <InputLabel id="demo-simple-select-outlined-label1">Aplicar ICMS?</InputLabel>
                <Select
                  style={{width: '100%'}}
                  labelId="demo-simple-select-outlined-label1"
                  id="demo-simple-select-outlined1"
                  value={icms}
                  onChange={(ev) => setAplicarICMS(ev.target.value)}
                  label="Aplicar ICMS?"
                >
                  <MenuItem value="0">Sim</MenuItem>
                  <MenuItem value="1">Não</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6} style={{marginBottom: 20}}>
              <TextField type="number" InputLabelProps={{ shrink: true }} placeholder="20,50" label="Despacho/CTE (R$)" variant="outlined" value={tdc} onChange={(ev) => setTdc(ev.target.value)} style={{marginRight: 20}} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Etapa 4 - Faixas de peso"
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <TextField placeholder="5,10,30" label="Faixas de peso separado por vírgula" variant="outlined" value={rangeWeights} onChange={(ev) => setRangeWeights(ev.target.value.split(','))} style={{width: '100%'}} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={disableButton}
            onClick={generateLayout}
          >
            Gerar Layout de tabela
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default StepOne;
