import React, { useEffect, useState, useCallback } from 'react';
import { Link, Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import ApiService from 'src/common/apiService';
import { JsonToTable } from "react-json-to-table";
import moment from 'moment';
import { getActualCompany, hasPermission, inPresentation, isAdmin, isSuperUser, login, setActualCompany } from 'src/common/auth';
import Select from '@atlaskit/select';
import Button from '@atlaskit/button/standard-button';

import ButtonMenu from '@atlaskit/button';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { Field } from '@atlaskit/form';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#242424',
    height: 40
  },
  toolbar: {
    minHeight: 40,
    '& .MuiToolbar-regular': {
      minHeight: 40
    }
  },
  avatar: {
    width: 60,
    height: 60
  },
  logo: {
    height: 15,
    marginTop: 5,
    marginRight: 30
  }
}));


const items = [
 /* {
    href: '/dashboard',
    title: 'Dashboard',
    render: hasPermission('dashboard')
  }, */
  {
    href: '/orders',
    title: 'Pedidos',
    render: hasPermission('orders')
  },
  {
    href: '/pickups',
    title: 'Coletas',
    render: hasPermission('pickups')

  },
  /*{
    href: '/quote',
    title: 'Cotações',
    render: hasPermission('quotation')
  },
  {
    href: '/settings',
    title: 'Configurações',
    render: isAdmin() || isSuperUser() || hasPermission('settings')
  }*/
];

const itemsSettings = [
  {
    href: '/settings/freight-rules',
    title: 'Regras de Frete',
    render: isAdmin() && hasPermission('settings')
  },
  {
    href: '/settings/distribution-centers',
    title: 'Centros de Distribuição',
    render: isAdmin() && hasPermission('settings')
  },
  {
    href: '/settings/users',
    title: 'Usuários',
    render: isAdmin() && hasPermission('settings')
  },
  {
    href: '/settings/products',
    title: 'Produtos',
    render: hasPermission('settings')
  },
  {
    href: '/settings/contingency-tables',
    title: 'Tabelas de Contingência',
    render: isAdmin() && hasPermission('settings')
  },
  {
    href: '/settings/freight-tables',
    title: 'Tabela de Frete',
    render: isSuperUser()
  },
  {
    href: '/settings/carriers',
    title: 'Transportadoras',
    render: hasPermission('settings')
  },
  {
    href: '/settings/integrations',
    title: 'Integrações',
    render: isAdmin() && hasPermission('settings')
  },
];

const TopBar = ({
  className,
  onMobileNavOpen,
  onMobileClose,
  openMobile,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const apiService = new ApiService();

  const location = useLocation();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [isSetting, setIsSetting] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    if (location.pathname.indexOf("settings") !== -1) {
      setIsSetting(true);
    } else {
      setIsSetting(false);
    }

    if (!companies.length) {
      apiService
        .getCompanies()
        .then((res) => {
          setCompanies(res.data);
        });
    }

    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const changeCompany = (cmp) => {
    apiService
      .generateCompanyToken(cmp.id)
      .then((res) => {
        login(res.token);
        setActualCompany(res.company);
        window.location.href = '/dashboard';
      });
  };

  const getMe = () => {
    apiService
      .me()
      .then((res) => window.localStorage.setItem('@flixlog/me', JSON.stringify({ name: res.name, email: res.email })));
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadNotifications = () => {
    setOpenDrawer(true);
    setLoadingNotifications(true);
    apiService.getNotifications({}, 20, 1).then((res) => {
      setNotifications(res.data);
      setLoadingNotifications(false);
    });
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <Typography variant='h2'>Trocar de Conta</Typography>
            </ModalHeader>
            <div style={{ padding: 30 }}>
              <Field label="Procurar Cliente" name="actualCompany" defaultValue="">
                {({ fieldProps }) => (
                  <Select
                    inputId="actualCompany"
                    styles={{
                      width: 200
                    }}
                    onChange={(value) => setCompany({ name: value.name, id: value.value })}
                    options={companies.map((company) => ({ label: `${company.id} - ${company.name}`, value: company.id }))}
                  />
                )}
              </Field>
            </div>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>Cancelar</Button>
              <Button appearance="primary" onClick={() => {
                changeCompany(company);
              }} autoFocus>
                Trocar
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <Toolbar className={classes.toolbar}>
        <RouterLink to="/dashboard">
          <Logo className={classes.logo} />
        </RouterLink>

        <ul className="menu-top">
          {items.map((item) => <li>
            {item.render && <Link to={item.href}>{item.title}</Link>}
          </li>)}
        </ul>
        <Box flexGrow={1} />

        <div style={{
          borderRight: '1px solid #6c6c6cbf',
          paddingRight: '10px',
          marginRight: '0px'
        }}>
          <strong style={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={openModal}>{getActualCompany().name}</strong>
        </div>



        <RouterLink to="/logout">
          <IconButton color="inherit">
            <InputIcon style={{ color: '#fff' }} />
          </IconButton>
        </RouterLink>

      </Toolbar>

      {/* {isAdmin() || isSuperUser() || hasPermission('settings') ? <Toolbar className={classes.toolbar} style={{backgroundColor: "#727272", borderBottom: "#242424"}}>
       
        <ul className="menu-top-2">
          {itemsSettings.map((item) => <li>
            {item.render && <Link to={item.href}>{item.title}</Link>}
          </li>)}
        </ul> 
        <Box flexGrow={1} />

      </Toolbar>: null} */}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
