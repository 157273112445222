import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import EditDriver from './EditDriver';
import EditPickup from './EditPickup';
import Linkify from 'react-linkify';
import nl2br from 'react-nl2br';

import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  Table,
  TableBody,
  CardHeader,
  Typography,
  CardContent, TextareaAutosize, Button, Avatar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TableCell, TableHead, TableRow, Radio, List, ListItemText, TextField, FormControl, MenuItem, InputLabel, Select, Divider
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import OrderRow from '../OrdersView/OrderRow';
import SimpleReactFileUpload from './FileUpload';
import { isSuperUser } from 'src/common/auth';
import { Bold, Trash } from 'react-feather';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  content: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit'
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));

const SimpleOrder = ({ order, refreshOrder, ...props }) => {
  const classes = useStyles();
  const [openChangeCarrier, setOpenChangeCarrier] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const invoice = order?.shipments[0]?.invoice;

  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [sendingAttachment, setSendingAttachment] = useState(false);
  const [attachment, setAttachment] = useState({
    name: null,
    mime: null
  });
  const [openAttachment, setOpenAttachment] = useState(false);

  const [openCheckout, setOpenCheckout] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [openRemoveFromPickup, setOpenRemoveFromPickup] = useState(null);
  const [changingCarrier, setChangingCarrier] = useState(false);
  const [invoiceErrors, setInvoiceErros] = useState([]);

  const [issue, setIssue] = useState(null);

  const [sendingIssue, setSendingIssue] = useState(false);
  const apiService = new ApiService();
  const params = useParams();
  const myRef = useRef(null);

  useEffect(() => {
    if (openChangeCarrier) {
      const apiService = new ApiService();
      apiService
        .quoteOrder(order.id)
        .then((res) => {
          setCarriers(res.data);
        });
    }

    if (invoice) {
      validateInvoice();
    }
  }, [openChangeCarrier, invoice]);

  const validateInvoice = () => {
    if (invoice) {
      const apiService = new ApiService();
      apiService
        .validateInvoice(invoice.id)
        .then((res) => setInvoiceErros(res.errors));
    }

  }
  const downloadFile = (path) => {
    const apiService = new ApiService();
    apiService.download(path);
  }

  const changeCarrier = () => {
    if (!selectedCarrier) {
      alert('Selecione uma cotação');
      return;
    }

    setChangingCarrier(true);

    apiService
      .changeOrderCarrier(order.id, selectedCarrier)
      .then(() => {
        props.enqueueSnackbar('Transportadora alterada com sucesso!', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };

  const sendIssue = () => {
    setSendingIssue(true);
    apiService.createNewIssue({
      message: issue,
      order_id: order.id
    }).then(() => {
      props.enqueueSnackbar('Mensagem enviada com sucesso!', {
        variant: 'success',
      });
      setSendingIssue(false);
      refreshOrder();
      myRef.current.value = "";
    }).catch(() => {
      props.enqueueSnackbar('Ocorreu um erro ao tentar enviar a mensagem, tente novamente.', {
        variant: 'error',
      });
    });
  };

  const downloadInvoice = (id) => {
    apiService.downloadInvoicePDF(id);
  };

  const cancelOrder = () => {
    apiService
      .cancelOrder(order.reference)
      .then(() => {
        props.enqueueSnackbar('Pedido cancelado com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };


  const removeFromPickup = () => {
    apiService
      .removeFromPickup(order.id)
      .then(() => {
        props.enqueueSnackbar('Pedido removido da coleta com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };


  const sendInvoice = (response) => {
    setSendingInvoice(true);
    apiService
      .sendInvoice({
        order: {
          reference: order.reference
        },
        invoice: {
          path: response.data.path
        }
      })
      .then(() => {
        props.enqueueSnackbar('Nota fiscal anexada com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        console.log(error.message);
        props.enqueueSnackbar('Ocorreu um erro ao tentar anexar nota fiscal, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  const sendAttachment = (response) => {
    setSendingAttachment(true);
    apiService
      .addAttachment({
        shipment: {
          reference: order.reference
        },
        file: {
          name: attachment.name,
          mime: attachment.mime,
          path: response.data.path
        }
      })
      .then(() => {
        props.enqueueSnackbar('Anexada cadastrado com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        console.log(error.message);
        props.enqueueSnackbar('Ocorreu um erro ao tentar anexar o arquivo, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  const handleSubmit =  (event) => {
    event.preventDefault();
    sendIssue();
  };

  return (
    <Page
      className={classes.root}
      title={order ? "Pedido #" + order.reference : 'Carregando...'}
    >
      <Container maxWidth={false}>
        {order ? (
          <>
            <Toolbar order={order} setOpenChangeCarrier={setOpenChangeCarrier} setOpenCancel={setOpenCancel} setOpenCheckout={setOpenCheckout} />

            {invoiceErrors.length > 0 && [2, 6, 11, 12].indexOf(order.status.id) !== -1 ? <Alert severity="error" style={{ color: 'red' }}>
              <AlertTitle>Nota fiscal inválida: Necessário carta de correção.</AlertTitle>
              {invoiceErrors.map((error, index) => <>{index + 1} - {error}<br /></>)}
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              Esta é uma mensagem automática. Se já enviou a carta de correção está tudo certo, pode ignorar o alerta.
            </Alert> : null}



            <Dialog
              open={openChangeCarrier}
              onClose={() => setOpenChangeCarrier(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Alterar transportadora"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Escolha abaixo uma das cotações que deseja aplicar nesse pedido.
                  <br />
                </DialogContentText>
              </DialogContent>
              {carriers.length ? <Table>
                <TableHead>
                  <TableCell>Transportadora</TableCell>
                  <TableCell>Peso utilizado</TableCell>
                  <TableCell>Valor da Cotação</TableCell>
                  <TableCell>Escolher</TableCell>
                </TableHead>
                <TableBody>
                  {carriers.map((quote) => <TableRow>
                    <TableCell>{quote.carrier.name}</TableCell>
                    <TableCell>{quote.cubed_weight.toLocaleString('pt-BR')} kg</TableCell>
                    <TableCell>{quote.estimated_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                    <TableCell>
                      <Radio
                        onChange={(ev) => {
                          setSelectedCarrier(ev.target.value);
                        }}
                        key={quote.carrier.id}
                        value={quote.carrier.id}
                        name="radio-button-demo"
                      />
                    </TableCell>
                  </TableRow>)}
                </TableBody>
              </Table> : <DialogContent> <strong>Realizando cotações, aguarde ...</strong></DialogContent>}
              <DialogActions>
                <Button onClick={() => setOpenChangeCarrier(false)} color="primary">
                  Fechar
                </Button>
                <Button disabled={changingCarrier || carriers.length === 0 || !selectedCarrier} onClick={() => changeCarrier()} color="primary" autoFocus variant="contained"
                >
                  Confirmar
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={openCancel}
              onClose={() => setOpenCancel(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirmar ação"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Você tem certeza?"} <br />
                  Ao clicar em "Confirmar cancelamento", você estará cancelando o pedido #{order.reference} na Flixlog. Essa ação não poderá ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenCancel(false)} color="primary">
                  Fechar
                </Button>
                <Button onClick={() => cancelOrder()} color="primary" autoFocus style={{ backgroundColor: '#F44336' }}>
                  Confirmar cancelamento
                </Button>
              </DialogActions>
            </Dialog>

            {order.pickup ? <Dialog
              open={openRemoveFromPickup}
              onClose={() => setOpenRemoveFromPickup(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirmar ação"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Você tem certeza?"} <br />
                  Ao clicar em "Confirmar remoção", você estará removendo o pedido #{order.reference} da coleta #{order.pickup.id}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenRemoveFromPickup(false)} color="primary">
                  Fechar
                </Button>
                <Button onClick={() => removeFromPickup()} color="primary" autoFocus style={{ backgroundColor: '#F44336' }}>
                  Confirmar remoção
                </Button>
              </DialogActions>
            </Dialog> : null}

            <Dialog
              open={openAttachment}
              onClose={() => setOpenAttachment(false)}
            >
              <DialogTitle>{"Adicionar Anexo"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {sendingAttachment ? 'Aguarde ...' : <>
                    <Box>
                      <TextField required label="Nome"
                        onChange={(ev) => setAttachment({ ...attachment, name: ev.target.value })}
                        value={attachment.name}></TextField>
                    </Box>
                    <Box>
                      <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                        <InputLabel margin="normal">Tipo de arquivo</InputLabel>
                        <Select
                          label="Tipo de arquivo"
                          margin="normal"
                          name="mime"
                          required
                          onChange={(ev) => setAttachment({ ...attachment, mime: ev.target.value })}
                          value={attachment.mime}
                          variant="outlined"
                        >
                          <MenuItem value="application/xml+nfe">XML Nota</MenuItem>
                          <MenuItem value="application/xml+nfe-event">XML Carta de correção</MenuItem>
                          <MenuItem value="application/xml+cte">XML CTE</MenuItem>
                          <MenuItem value="application/pdf">Documento em PDF</MenuItem>
                          <MenuItem value="application/image">Imagem</MenuItem>
                          <MenuItem value="application/others">Outros</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <SimpleReactFileUpload
                      submiting={sendingAttachment}
                      title=""
                      onSuccess={(response) => sendAttachment(response)}
                    />
                  </>}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenAttachment(false)} color="primary">
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openCheckout}
              onClose={() => setOpenCheckout(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Enviar xml da Nota Fiscal"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <SimpleReactFileUpload
                    submiting={sendingInvoice}
                    title=""
                    onSuccess={(response) => sendInvoice(response)}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenCheckout(false)} color="primary">
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
            <Box mt={3}>
              <Grid container spacing={3}>
                {order.distribution_center ? <Grid item xs={6} md={3}>
                  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardHeader title="Origem" />
                    <CardContent>
                      <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{order.distribution_center.reference} - <a style={{ color: '#111' }} href={`/settings/distribution-centers/edit/${order.distribution_center.id}`} target="_blank">{order.distribution_center.name}</a></Typography>
                      <p className={classes.content}>
                        <strong>CEP:</strong> {order.distribution_center.address.zip} <br />
                        <strong>Endereço:</strong> {order.distribution_center.address.street1}, {order.distribution_center.address.number}, {order.distribution_center.address.street2} <br />
                        <strong>Bairro:</strong> {order.distribution_center.address.neighborhood} <br />
                        <strong>Cidade/UF:</strong> {order.distribution_center.address.city}/{order.distribution_center.address.state} <br />
                      </p>
                    </CardContent>
                  </Card>
                </Grid> : <Grid item xs={6} md={3}>
                  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardHeader title="Origem" />
                    <CardContent>
                      <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>
                        CD removido
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>}

                <Grid item xs={6} md={2} style={{ marginBottom: 20 }}>
                  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardHeader title="Destinatário" />
                    <CardContent>
                      <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{order.to_address.name}</Typography>
                      <p className={classes.content}>
                        <strong>Telefone:</strong> {order.to_address.phone} <br />
                        <strong>E-mail:</strong> {order.to_address.email} <br />
                        <strong>CPF:</strong> {order.to_address.federal_tax_id} <br />
                      </p>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardHeader title="Endereço de entrega" />
                    <CardContent>
                      <p className={classes.content}>
                        <strong>CEP:</strong> {order.to_address.zip} <br />
                        <strong>Endereço:</strong> {order.to_address.street1}, {order.to_address.number}, {order.to_address.street2} <br />
                        <strong>Bairro:</strong> {order.to_address.neighborhood} <br />
                        <strong>Cidade/UF:</strong> {order.to_address.city}/{order.to_address.state} <br />
                      </p>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardHeader title="Transportadora" />
                    <CardContent>
                      <Typography color="textPrimary" variant="h4" style={{ fontSize: 20 }}>{order.carrier.name}</Typography>
                      <p className={classes.content}>
                        <strong>CNPJ:</strong> {order.carrier.federal_tax_id}<br />
                        <strong>Nível de serviço:</strong> {order.carrier.sla} de 5 <br />
                        <strong>Telefone:</strong> <a href={`tel:+55` + order.carrier.phone}>{order.carrier.phone}</a><br />
                        <br />
                        {order.tracker && order.tracker.tracking_code ? (<strong>Código de Ratreio: <a target="_blank" href={`/tracking/${order.tracker.tracking_code.toUpperCase()}`}>{order.tracker.tracking_code.toUpperCase()}</a></strong>) : null}
                      </p>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Card>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <OrderRow order={order} style={{ width: '100%' }} noRow={true} />
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardHeader title="Ocorrências" />
                        <CardContent>
                          <ul class="timeline">
                            {order.review ? <li class="status-5">
                              <span>Avaliação do cliente: <ReactStars
                                value={order.review.rate}
                                size={24}
                                edit={false}
                                activeColor="#ffcc00"
                              /><br />
                                <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{order.review.message}</small>
                              </span>
                              <span class="float-right">{moment(order.review.created_at).format('DD/MM/YYYY HH')}h</span>
                            </li> : null}
                            {order.tracker ? order.tracker.details.map((detail) => {
                              return (<li class={"status-" + detail.status}>
                                <span>
                                  {detail.description}
                                  <br />
                                  <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{detail.message}</small>
                                  {detail.code == 1 && order.tracker.delivery_receipt_url ? <a href={order.tracker.delivery_receipt_url} style={{
                                    color: '#03a9f4', marginTop: '10px', display: 'block', fontWeight: 'bold'
                                  }} target="_blank">Comprovante entrega</a> : null}
                                  {detail.code == 200 && order.shipments[0].invoice ? <a onClick={() => downloadInvoice(order.shipments[0].invoice.id)} style={{
                                    color: '#03a9f4', marginTop: '10px', display: 'block', fontWeight: 'bold', cursor: 'pointer'
                                  }} target="_blank">Baixar Danfe</a> : null}
                                </span>
                                <span class="float-right">{moment(detail.occurred_at).format('DD/MM/YYYY HH')}h</span>
                              </li>);
                            }) : null}

                            <li>
                              <span>Pedido criado <br />
                                <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>Código: {order.reference}</small>
                              </span>
                              <span class="float-right">{moment(order.created_at).format('DD/MM/YYYY HH')}h</span>
                            </li>

                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardHeader title="Volumes" />
                        <CardContent>
                          {order.shipments.map((shipment) => {
                            return <p><small>{shipment.volume} - <strong>{'Dimensões: ('}{(shipment.height * 100) + 'x' + (shipment.width * 100) + 'x' + (shipment.length * 100)}{') cm - Peso cubado: '} {shipment.weight + 'Kg'}{' - Quantidade: '} {shipment.quantity} {' - Valor: '} {shipment.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></small></p>;
                          })}
                        </CardContent>
                      </Card>
                    </Grid>

                    {order.shipments && order.shipments[0] && order.shipments[0].invoice && order.shipments[0].products ? (
                      <Grid item xs={12} md={12}>
                        <Card>
                          <CardHeader title="Produtos" />
                          <CardContent>
                            {order.shipments[0].products.map((shipment) => {
                              return <p><small>{shipment.reference} - {shipment.description}</small></p>;
                            })}
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}


                    {(order.delivery && order.delivery.log) ? (
                      <Grid item xs={12} md={12}>
                        <Card>
                          <CardHeader title={`Log de cotação - Peso cubado: ` + order.delivery.cubed_weight + `KG`} />
                          <CardContent>
                            <Table>
                              <TableHead>
                                <TableCell>
                                  Tipo de taxa
                                </TableCell>
                                <TableCell>
                                  Valor Anterior
                                </TableCell>
                                <TableCell>
                                  Percentual
                                </TableCell>
                                <TableCell>
                                  Valor Adicional
                                </TableCell>
                                <TableCell>
                                  Valor Final
                                </TableCell>
                              </TableHead>
                              <TableBody>
                                {order
                                  .delivery
                                  .log
                                  .filter((log) => !log.deadlines)
                                  .map((log) => {
                                    var percentage = (<TableCell>{log.percentage ? log.percentage + '%' : '-'}</TableCell>);
                                    var translageFee = {
                                      pickup: 'Taxa de Coleta',
                                      toll: 'Pedágio',
                                      weight: 'Frete peso',
                                      gris: 'GRIS',
                                      adv: 'ADV (Seguro)',
                                      tdc: 'Taxa de despacho',
                                      icms: 'ICMS',
                                      'additional-price': 'KG Excedente',
                                      'increase-table-value': 'Adicional da tabela',
                                      'increase-freight-value': 'Adicional da conta',

                                      'freight_rule_increase_value': 'Regra de Frete: Aumentar valor',
                                      'freight_rule_increase_percentage': 'Regra de Frete: Aumentar percentual',
                                      'freight_rule_decrease_value': 'Regra de Frete: Diminuir valor',
                                      'freight_rule_decrease_percentage': 'Regra de Frete: Diminuir percentual',
                                      'freight_rule_fixed_value': 'Regra de Frete: Valor fixo'
                                    }
                                    if (log.icms_percentage) {
                                      percentage = (<TableCell>{log.icms_percentage ? log.icms_percentage + '%' : '-'}</TableCell>);
                                    }
                                    if (log.gris_percentage) {
                                      percentage = (<TableCell>{log.gris_percentage ? (log.gris_percentage * 100).toFixed(2) + '%' : '-'}</TableCell>);
                                    }
                                    if (log.adv_percentage) {
                                      percentage = (<TableCell>{log.adv_percentage ? (log.adv_percentage * 100) + '%' : '-'}</TableCell>);
                                    }
                                    if (log.fee == 'freight_rule_increase_percentage' || log.fee == 'freight_rule_decrease_percentage') {
                                      percentage = (<TableCell>{log.value ? log.value + '%' : '-'}</TableCell>);
                                    }

                                    if (log.fee == 'freight_rule_increase_value' || log.fee == 'freight_rule_decrease_value') {
                                      var price = <TableCell>{log.value > 0 ? log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</TableCell>
                                    } else if (log.fee == 'freight_rule_fixed_value') {
                                      var price = <TableCell>{log.value > 0 ? '-' : '-'}</TableCell>
                                    } else {
                                      var price = <TableCell>{(log.actual_price - log.previous_price) > 0 ? (log.actual_price - log.previous_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</TableCell>
                                    }
                                    return (
                                      <TableRow>
                                        <TableCell>{translageFee[log.fee] ? translageFee[log.fee] : log.fee}</TableCell>
                                        <TableCell>{log.fee === "weight" ? log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (log.previous_price ? log.previous_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null)}</TableCell>
                                        {percentage}
                                        {price}

                                        <TableCell>{log.actual_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                        
                        {order
                          .delivery
                          .log
                          .filter((log) => log.deadlines)
                          .map((log) => {
                            const deadlines = log.deadlines;
                            return <Card>
                              <CardHeader title={`Log de Prazos`} />
                              <CardContent>
                                <Table>
                                  <TableHead>
                                    <TableCell>
                                      Descrição
                                    </TableCell>
                                    <TableCell>
                                      Prazo Adicional
                                    </TableCell>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Prazo de Coleta</TableCell>
                                      <TableCell>{deadlines.pickup_deadline} dias úteis</TableCell>
                                    </TableRow>
                                    {deadlines.expedition_time > 0 ? <TableRow>
                                      <TableCell>Prazo de expedição</TableCell>
                                      <TableCell>{deadlines.expedition_time} dias úteis</TableCell>
                                    </TableRow> : null}
                                    {deadlines.sku_deadline > 0 ? <TableRow>
                                      <TableCell>Prazo de separação</TableCell>
                                      <TableCell>{deadlines.sku_deadline} dias úteis</TableCell>
                                    </TableRow> : null}
                                    <TableRow>
                                      <TableCell>Prazo de transporte</TableCell>
                                      <TableCell>{deadlines.carrier_deadline} dias úteis</TableCell>
                                    </TableRow>
                                    {deadlines.freight_rules_deadline > 0 ? <TableRow>
                                      <TableCell>Prazo de regra de frete</TableCell>
                                      <TableCell>{deadlines.freight_rules_deadline} dias úteis</TableCell>
                                    </TableRow> : null}
                                    <TableRow>
                                      <TableCell><strong>Prazo Final</strong></TableCell>
                                      <TableCell><strong>{deadlines.total} dias úteis</strong></TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </CardContent>
                            </Card>
                          })}
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>



                    <Grid item xs={12}>
                      <Card>
                        <CardHeader title="Dados da Coleta" />
                        {order.pickup ? <CardContent>
                          {order.pickup ? <List>
                            <ListItemText>Coleta Flixlog: <strong>#{order.pickup.id}</strong> </ListItemText>
                            <ListItemText>Coleta Transportadora: <strong title={`Integrado em ${moment(order.pickup.integration_date).format('DD/MM/YYYY HH:MM')}`}>{order.pickup.integration_code}</strong> </ListItemText>
                            <ListItemText>Data de solicitação de coleta: <strong>{moment(order.pickup.created_at).format('DD/MM/YYYY HH:MM')}</strong> </ListItemText>

                            {order.pickup.estimated_date ? <ListItemText style={{ color: "#000", fontWeight: 'bold' }}><strong>Data estimada de coleta: {moment(order.pickup.estimated_date).format('DD/MM/YYYY')}. {order.pickup.estimated_time_start ? (
                              `Entre ${order.pickup.estimated_time_start ? order.pickup.estimated_time_start + 'h' : null} ${order.pickup.estimated_time_end ? order.pickup.estimated_time_end + 'h' : null}`
                            ) : ''}</strong></ListItemText> : null}

                            {order.pickup.driver ? <ListItemText style={{ color: "#000", fontWeight: 'bold' }}>{order.pickup.driver.name}</ListItemText> : null}
                          </List> : null}

                          <Box style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                            <Button
                              variant="contained"
                              onClick={setOpenRemoveFromPickup}
                              style={{
                                color: '#000',
                                fontSize: 11
                              }}><Trash style={{ width: 14 }} /> REMOVER DESSA COLETA</Button>

                            {isSuperUser() ? (
                              <>
                                <EditDriver
                                  props={props}
                                  reference={order.reference}
                                  refreshOrder={refreshOrder}
                                  name={order.pickup.driver ? order.pickup.driver.name : null}
                                  phone={order.pickup.driver ? order.pickup.driver.phone : null}
                                  label="Editar Motorista"
                                  style={{ float: 'left', marginRight: 20 }}
                                />
                                <EditPickup
                                  props={props}
                                  reference={order.reference}
                                  refreshOrder={refreshOrder}
                                  estimated_date={order.pickup.estimated_date ? order.pickup.estimated_date : null}
                                  estimated_time_start={order.pickup.estimated_time_start ? order.pickup.estimated_time_start : null}
                                  estimated_time_end={order.pickup.estimated_time_end ? order.pickup.estimated_time_end : null}
                                  label="Editar data de coleta"

                                  style={{ float: 'left', marginRight: 20 }}
                                />
                              </>
                            ) : null}
                          </Box>

                        </CardContent> : <CardContent>
                          {order.status.id == 1 || order.status.id == 2 ? <Typography variant="h4" style={{ marginBottom: 30 }}>Ação recomendada:</Typography> : null}

                          <Box style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>

                            {order.status.id == 1 ? < Button
                              variant="contained"
                              onClick={setOpenCheckout}
                              style={{
                                color: '#fff',
                                backgroundColor: "#8BC34A"
                              }}>ENVIAR NOTA FISCAL</Button> : null}

                            {order.status.id == 2 ? < Button
                              variant="contained"
                              component={Link}
                              to="/pickups/new"
                              style={{
                                color: '#fff',
                                backgroundColor: "#8BC34A"
                              }}>SOLICITAR COLETA</Button> : null}

                            {isSuperUser() ? (
                              <>
                                <EditDriver
                                  props={props}
                                  reference={order.reference}
                                  refreshOrder={refreshOrder}
                                  name={order.pickup && order.pickup.driver ? order.pickup.driver.name : null}
                                  phone={order.pickup && order.pickup.driver ? order.pickup.driver.phone : null}
                                  label="Editar Motorista"
                                  style={{ float: 'left', marginRight: 20 }}
                                />
                                <EditPickup
                                  props={props}

                                  reference={order.reference}
                                  refreshOrder={refreshOrder}
                                  estimated_date={order.pickup && order.pickup.estimated_date ? order.pickup.estimated_date : null}
                                  estimated_time_start={order.pickup && order.pickup.estimated_time_start ? order.pickup.estimated_time_start : null}
                                  estimated_time_end={order.pickup && order.pickup.estimated_time_end ? order.pickup.estimated_time_end : null}
                                  label="Editar data de coleta"

                                  style={{ float: 'left', marginRight: 20 }}
                                />
                              </>
                            ) : null}
                          </Box>
                        </CardContent>}
                      </Card>
                    </Grid>

                    {order.delivery ? <Grid item xs={12}>
                      <Card>
                        <CardHeader title="Dados de Entrega" />
                        <CardContent>
                          <List>
                            <ListItemText>Previsão de entrega: <strong>{moment(order.delivery.delivery_date).format('DD/MM/YYYY')}</strong> </ListItemText>
                          </List>
                        </CardContent>
                      </Card>
                    </Grid> : null}

                    <Grid item xs={12}>
                      <Card>
                        <CardHeader title="Anexos" />
                        <CardContent>
                          <Button variant="contained"
                            onClick={() => setOpenAttachment(true)}
                            style={{
                              color: '#fff',
                              backgroundColor: "#8BC34A"
                            }}>ADICIONAR ANEXO</Button>

                          <List>

                            {order.attachments && order.attachments.map((res) => <ListItemText>
                              <Box display="flex" justifyContent="space-between" style={{ borderBottom: '1px solid #ddd', paddingTop: '10px', paddingBottom: '10px' }}>
                                <Box>{res.name}</Box>
                                <Box>
                                  <a onClick={() => downloadFile(res.path)} target="_blank" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Abrir</a>
                                </Box>
                              </Box>
                            </ListItemText>
                            )}
                          </List>
                        </CardContent>

                      </Card>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardHeader title="Mensagens" />
                        <CardContent style={{ textAlign: 'right' }}>
                          <form onSubmit={handleSubmit}>

                            <ul class="timeline message" style={{ borderBottom: '1px solid #ddd' }}>
                              {order.issues.map((issue) => {
                                return (<li>

                                  <span style={{ display: 'flex', width: '50%', alignItems: 'center', textAlign: 'left' }}>
                                    <Tooltip enterDelay={300} title={issue.user.name} key={issue.id}>
                                      <Avatar
                                        className={classes.purple}
                                        src={issue.user.photo}
                                      >{issue.user.name ? issue.user.name.substring(0, 1) : ''}</Avatar>
                                    </Tooltip>

                                    <small style={{ color: '#5a5a5a', fontWeight: 'normal', marginLeft: 10 }} >
                                      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                          {decoratedText}
                                        </a>
                                      )}>{nl2br(issue.message)}</Linkify>
                                      {issue.user.is_flixlog ? <><br /><strong
                                        title="Mensagem da equipe Flixlog"
                                        style={{
                                          background: '#c6f9ff',
                                          padding: '3px',
                                          borderRadius: '5px',
                                          color: '#0b7987',
                                          marginTop: '10px',
                                          display: 'inline-block',
                                          fontSize: '9px',
                                          border: '1px',
                                          solid: '#82c9d1'
                                        }}
                                      >FLIXLOG</strong></> : null}
                                    </small>
                                  </span>
                                  <span class="float-right">{moment(issue.created_at).format('DD/MM/YYYY HH:mm')}</span>
                                </li>);
                              })}
                            </ul>

                            <TextareaAutosize
                              onChange={(e) => setIssue(e.target.value)}
                              name="message"
                              ref={(ref) => { 
                                myRef.current = ref; 
                              }}
                              readOnly={sendingIssue}
                              placeholder="Digite a mensagem"
                              style={{ marginTop: 30 }}
                            />
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              disabled={sendingIssue}
                            >
                              ENVIAR MENSAGEM
                            </Button>
                          </form>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : null}
      </Container>
    </Page >
  );
};

const mapStateToProps = (store) => ({
  orderSearch: store.orderSearch.value
});

export default connect(mapStateToProps)(withSnackbar(SimpleOrder));
