import axios from 'axios';
import { getToken } from './auth';

class ApiService {
  constructor() {
    if (window.location.hostname === 'localhost') {
        this.baseURL = 'https://api-v2.flixlog.com/';
    } else {
      this.baseURL = 'https://api-v2.flixlog.com/';
    }
    this.api = axios.create({
      baseURL: this.baseURL,
    });

    try {
      this.api.interceptors.request.use(
        (config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              'Content-Type': 'application/json',
              'X-Token': getToken(),
            },
          };
        },
        (error) => Promise.reject(error)
      );

      this.api.interceptors.response.use(undefined, (error) => {
        const { status } = error.response;

        if (status === 401 && !window.location.href.includes('login')) {
          localStorage.removeItem('token');
          window.location.replace('/login?status=401');
        }

        return Promise.reject(error);
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  getBaseURL = () => {
    return this.baseURL;
  }

  auth = (email, password, params) => {
    return this.api
      .post('auth', { email, password, params})
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  resetPassword = (email) => {
    return this.api
      .post('auth/reset-password', {
        action: 'ResetPassword',
        payload: {
          email
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  confirmEmail = (token) => {
    return this.api
      .post('auth/confirm-email', {
        action: 'ConfirmEmail',
        payload: token
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  newPassowrd = (payload) => {
    return this.api
      .post('auth/change-password', {
        action: 'ChangePassword',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  createPresignup = (code) => {
    return this.api
      .post('pre-signup/nuvemshop/' + code)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  allowContact = (storeId) => {
    return this.api
      .post('allow-contact/nuvemshop?token=' + storeId)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  generateCompanyToken = (companyId) => {
    return this.api
      .post('company-token', { company_id: companyId })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getOrdersFromFederalTaxId = (federalTaxId, recaptcha, company) => {
    return this.api
      .post('tracking', {
        federal_tax_id: federalTaxId,
        recaptcha,
        company
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  tracking = (trackingCode) => {
    return this.api
      .post(`tracking/${trackingCode}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  trackingCompany = (company, reference) => {
    return this.api
      .post(`company-tracking/${company}?ref=${reference}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  upload = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return this.api.post('upload', formData, config);
  }

  newDistributionCenter = (payload) => {
    return this.api
      .post('command', {
        action: 'NewDistributionCenter',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  synchronizeQuotations = () => {
    return this.api
      .post('command', {
        action: 'SynchronizeQuotation'
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  rateDelivery = (token, rate, message) => {
    return this.api
      .post('public/rate-delivery', {
        token,
        review: {
          rate,
          message
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  newOrder = (payload) => {
    return this.api
      .post('command', {
        action: 'NewOrder',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  updateOrder = (payload) => {
    return this.api
      .post('command', {
        action: 'UpdateOrder',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  newPickup = (payload) => {
    return this.api
      .post('command', {
        action: 'NewPickup',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  newBackgroundTask = (action, payload) => {
    return this.api
      .post('command', {
        action: 'NewBackgroundTask',
        payload: {
          action: action,
          payload: payload
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  updateDistributionCenter = (payload) => {
    return this.api
      .post('command', {
        action: 'UpdateDistributionCenter',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  removeDistributionCenter = (reference) => {
    return this.api
      .post('command', {
        action: 'RemoveDistributionCenter',
        payload: {
          reference
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  removeFreightRule = (freightRuleId) => {
    return this.api
      .post('command', {
        action: 'DeleteFreightRule',
        payload: {
          id: freightRuleId
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  changeDriver = (pickupId, name, phone, isShipment) => {
    var payload = {
      pickup: {
        id: pickupId,
        driver: {
          name,
          phone,
        }
      }
    };

    if (isShipment) {
      payload = {
        shipment: {
          reference: pickupId,
          driver: {
            name,
            phone,
          }
        }
      }
    }
    return this.api
      .post('command', {
        action: 'ChangeDriver',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  updatePickup = (pickupId, payload) => {
    payload.id = pickupId;
    return this.api
      .post('command', {
        action: 'UpdatePickup',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  quoteFreight = (data) => {
    return this.api
      .post('quotations', data)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  quoteOrder = (orderId) => {
    return this.api
      .get('orders/' + orderId + '/quotes')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  quoteShipment = (reference) => {
    return this.api
      .get('shipments/' + reference + '/quotes')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  confirmPickup = (pickupId, data) => {
    return this.api
      .post('command', {
        action: 'ConfirmPickup',
        payload: {
          pickup: {
            id: pickupId
          },
          data
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  changeShipmentCarrier = (shipmentReference, carrierId) => {
    return this.api
      .post('command', {
        action: 'ChangeShipmentCarrier',
        payload: {
          shipment: {
            reference: shipmentReference
          },
          carrier: {
            id: carrierId
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  changeOrderCarrier = (orderId, carrierId) => {
    return this.api
      .post('command', {
        action: 'ChangeOrderCarrier',
        payload: {
          order: {
            id: orderId
          },
          carrier: {
            id: carrierId
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  confirmShipment = (reference) => {
    return this.api
      .post('command', {
        action: 'ConfirmShipment',
        payload: {
          order: {
            reference
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  performPickup = (pickupId, data) => {
    return this.api
      .post('command', {
        action: 'PerformPickup',
        payload: {
          pickup: {
            id: pickupId
          },
          data
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  goOutDelivery = (reference) => {
    return this.api
      .post('command', {
        action: 'GoOutDelivery',
        payload: {
          order: {
            reference
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  deliver = (reference) => {
    return this.api
      .post('command', {
        action: 'Deliver',
        payload: {
          order: {
            reference
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  changeDeliveryDate = (reference, deliveryDate) => {
    return this.api
      .post('command', {
        action: 'ChangeDeliveryDate',
        payload: {
          order: {
            reference
          },
          delivery_date: deliveryDate
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  changePickupDate = (pickupId, data, isShipment) => {
    var payload = {
      pickup: {
        id: pickupId,
        estimated_date: data.estimated_date,
        estimated_time_start: data.estimated_time_start,
        estimated_time_end: data.estimated_time_end,
      }
    };

    if (isShipment) {
      payload = {
        shipment: {
          reference: pickupId,
          estimated_date: data.estimated_date,
          estimated_time_start: data.estimated_time_start,
          estimated_time_end: data.estimated_time_end,
        }
      };
    }

    return this.api
      .post('command', {
        action: 'ChangePickupDate',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  updateProduct = (data) => {
    return this.api
      .post('command', {
        action: 'UpdateProduct',
        payload: data
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  deleteProduct = (id) => {
    return this.api
      .post('command', {
        action: 'DeleteProduct',
        payload: {
          id: id
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  newProduct = (data) => {
    return this.api
      .post('command', {
        action: 'NewProduct',
        payload: data
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  addAttachment = (data) => {
    return this.api
      .post('command', {
        action: 'AddAttachment',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  sendInvoice = (data) => {
    return this.api
      .post('command', {
        action: 'SendInvoice',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  createOrdersFromInvoices = (payload) => {
    return this.api
      .post('command', {
        action: 'CreateOrdersFromInvoices',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  createProductsFromCSV = (payload) => {
    return this.api
      .post('command', {
        action: 'CreateProductsFromCSV',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  registerCompany = (payload) => {
    return this.api
      .post('register', payload)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  newCompanyChannel = (payload) => {
    return this.api
      .post('command', {
        action: 'NewCompanyChannel',
        payload
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  removeCompanyChannel = (id) => {
    return this.api
      .post('command', {
        action: 'RemoveCompanyChannel',
        payload: {
          company_channel: {
            id
          }
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  saveFreightTable = (data) => {
    return this.api
      .post('command', {
        action: 'NewFreightTable',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  newFreightRule = (data) => {
    return this.api
      .post('command', {
        action: 'NewFreightRule',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  updateFreightRule = (data) => {
    return this.api
      .post('command', {
        action: 'EditFreightRule',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  requestPassword = (email) => {
    return this.api
      .post('command', {
        action: 'ResetPassword',
        payload: {
          email,
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  requestPassword = (email) => {
    return this.api
      .post('command', {
        action: 'ResetPassword',
        payload: {
          email,
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  cancelOrder = (orderReference) => {
    return this.api
      .post('command', {
        action: 'CancelOrder',
        payload: {
          order: {
            reference: orderReference
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  removeFromPickup = (orderId) => {
    return this.api
      .post('command', {
        action: 'RemoveOrderFromPickup',
        payload: {
          order: {
            id: orderId
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  deletePickup = (pickupId) => {
    return this.api
      .post('command', {
        action: 'DeletePickup',
        payload: {
          pickup: {
            id: pickupId
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  reloadQuotationCache = (cache) => {
    return this.api
      .post('admin/refresh-cache/' + cache)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  cancelOrder = (orderReference) => {
    return this.api
      .post('command', {
        action: 'CancelOrder',
        payload: {
          order: {
            reference: orderReference
          }
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  cancelShipment = (reference) => {
    return this.api
      .post('command', {
        action: 'CancelShipment',
        payload: {
          reference
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getPickupsFromCarrierToken = (token) => {
    return this.api
      .get('carrier-pickups?token=' + token)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  confirmPickupsFromCarrierToken = (token, date, code) => {
    return this.api
      .post('carrier-pickups?token=' + token, {
        pickup_code: code,
        pickup_date: date
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  createNewIssue = (data) => {
    return this.api
      .post('command', {
        action: 'NewIssue',
        payload: data,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  createNewPassword = (token, password) => {
    return this.api
      .post('command', {
        action: 'ChangePassword',
        payload: {
          reset_password_token: token,
          password,
          confirm_password: password,
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  createNewUser = (payload) => {
    return this.api
      .post('command', {
        action: 'NewUser',
        payload,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  updateUser = (payload) => {
    return this.api
      .post('command', {
        action: 'UpdateUserInfo',
        payload,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  removeUser = (id) => {
    return this.api
      .post('command', {
        action: 'DeleteUser',
        payload: {
          id
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  deleteUser = (uuid) => {
    return this.api
      .post('command', {
        action: 'RemoveUser',
        payload: {
          uuid,
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  me = () => {
    return this.api
      .get('me')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getUsers = (filters) => {
    return this.api
      .get('users', {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  generateReport = (resource) => {
    return this.api
      .get(`reports/${resource}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getBatches = (filters) => {
    return this.api
      .get('batches', {
        filters
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getProduct = (id) => {
    return this.api
      .get(`products/${id}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getProducts = (filters, limit, page) => {
    let query = `?limit=${limit ? limit : 10}&page=${page ? page : 1}&`;

    if (filters && filters.reference) {
      query += `filters[reference]=${filters.reference}&`;
    }

    if (filters && filters.filters) {
      query += `filters[${filters.filters.filter}]=${filters.filters.value}&`;
    }

    if (filters && filters.id) {
      query += `filters[id]=${filters.id}&`;
    }


    return this.api
      .get(`products${query}`, {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getReviews = (filters, limit, page) => {
    let query = `?limit=${limit ? limit : 1000}&page=${page ? page : 1}&`;

    if (filters && filters.rate) {
      query += `filters[rate]=${filters.rate}&`;
    }

    return this.api
      .get(`reviews${query}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };


  getNotifications = (filters, limit, page) => {
    let query = `?limit=${limit}&page=${page}&`;

    return this.api
      .get(`notifications${query}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getFreightTables = (filters, limit, page) => {
    let query = `?limit=${limit}&page=${page}&`;

    return this.api
      .get(`freight-tables${query}`, {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  download = (path) => {
    return this.api
      .get(`download?url=1&path=${path}`)
      .then((res) => {
        setTimeout(() => {
          const response = {
            file: res.data.url,
          };
          // server sent the url to the file!
          // now, let's download:
          window.open(response.file);
          // you could also do:
          // window.location.href = response.file;
        }, 100);
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  downloadInvoicePDF = (invoiceId) => {
    return this.api
      .get(`invoices/${invoiceId}/pdf`)
      .then((res) => {
        
        const response = {
          file: res.data.url,
        };
        // server sent the url to the file!
        // now, let's download:
        window.open(response.file);
        // you could also do:
        // window.location.href = response.file;
        
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  downloadCsv = (pickupId) => {
    return this.api
      .get(`csv/products`)
      .then((res) => {
        const element = document.createElement("a");
        const file = new Blob([res.data], {type: 'application/csv'});
        element.href = URL.createObjectURL(file);
        element.download = 'products.csv';
        document.body.appendChild(element);
        element.click();
      });
  };

  downloadRomaneio = (pickupId) => {
    return this.api
      .get(`reports/pickup/${pickupId}`)
      .then((res) => res.data.url)
      .then((url) => {

        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  getChannels = (type) => {
    return this.api
      .get('channels?type=' + type)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getQuotationLog = (limit, page) => {
    limit = limit ? limit : 20;
    page = page ? page : 1;
    return this.api
      .get(`quotation-logs?limit=${limit}&page=${page}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCompanyChannels = () => {
    return this.api
      .get('company-channels')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCompanyChannel = (id) => {
    return this.api
      .get(`company-channels/${id}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCompanyChannelListing = (id, resource, filters, sort, limit, page) => {
    var fil = '';

    if (filters) {
      Object.keys(filters).forEach((it) => {
        fil += `${it}=${filters[it]}&`
      })
    }


    var soort = '';
    if (sort) {
      Object.keys(sort).forEach((item) => {
        soort += `sort[${item}]=${sort[item]}&`;
      });
    }

    return this.api
      .get(`company-channels/${id}/${resource}?${fil}limit=${limit}&page=${page}&${soort}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  syncCompanyChannelListing = (id, resource, listingData) => {
    return this.api
      .post(`company-channels/${id}/${resource}/sync`, listingData)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  syncCompanyChannelListingSitutation = (id, map) => {
    return this.api
      .post(`company-channels/${id}/situations`, map)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getDashboard = (filters) => {
    return this
      .api
      .get(`dashboard?filters[since]=${filters.since}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCarriersSla = (filters) => {
    return this
      .api
      .get(`dashboard/carriers-sla?filters[since]=${filters.since}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getSlaState = (filters) => {
    return this
      .api
      .get(`dashboard/carriers-sla?filters[since]=${filters.since}&filters[only_states]=1`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  geo = () => {
    return this
      .api
      .get(`geo`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCompanies = (filters) => {
    return this.api
      .get('companies', {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  validateInvoice = (invoiceId) => {
    return this.api
      .get(`invoices/${invoiceId}/validate`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
  
  getCarriers = (all) => {
    return this.api
      .get(all ? 'carriers?filters[all]=true' : 'carriers')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getCities = () => {
    return this.api
      .get('cities?limit=6000')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };


  getRegions = (filters) => {
    return this.api
      .get('regions')
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getDistributionCenter = (id) => {
    return this.api
      .get('distribution-centers/' + id)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getDistributionCenters = (filters) => {
    return this.api
      .get('distribution-centers', {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getFreightRule = (id) => {
    return this.api
      .get('freight-rules/' + id)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getFreightRules = (filters) => {
    return this.api
      .get('freight-rules', {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getBackgroundTasks = (filters) => {
    return this.api
      .get('background-tasks', {
        filters,
        limit: 100,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getUser = (id) => {
    return this.api
      .get(`users/${id}`)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getOrders = (filters, limit, page, splitDrop) => {
    var query = '?limit=' + limit + '&page=' + page + '&';

    if (filters && filters.reference) {
      query += 'filters[reference]=' + filters.reference + '&';
    }

    if (filters && filters.search) {
      query += 'filters[search]=' + filters.search + '&';
    }

    if (filters && filters.status) {
      query += 'filters[status]=' + filters.status + '&';
    }

    if (filters && filters.pickup_id) {
      query += 'filters[pickup_id]=' + filters.pickup_id + '&';
    }

    if (filters && filters.no_pickup) {
      query += 'filters[no_pickup]=' + filters.no_pickup + '&';
    }

    if (filters && filters.carrier) {
      query += 'filters[carrier]=' + filters.carrier + '&';
    }

    if (filters && filters.company) {
      query += 'filters[company]=' + filters.company + '&';
    }

    if (filters && filters.deadline) {
      query += 'filters[deadline]=' + filters.deadline + '&';
    }

    if (splitDrop) {
      query += 'filters[split_drop_shipping]=1&';
    }
    return this
      .api
      .get('orders' + query)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getPickup = (pickupId) => {
    return this
      .api
      .get('pickups/' + pickupId)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getDashboarPickupSla = (companyId) => {
    var query = companyId ? '?filters[company]=' + companyId : '';
    return this
      .api
      .get('/dashboard/pickups-sla' + query)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getPickups = (filters, limit, page) => {
    var query = '?limit=' + limit + '&page=' + page + '&';

    var fils = Object.keys(filters || {});
    fils.forEach((item) => {
      if(filters[item]) {
        query += `filters[${item}]=${filters[item]}&`;
      }
    });

    return this
      .api
      .get('pickups' + query)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getPickupsToday = (filters, limit, page) => {
    var query = '?limit=' + limit + '&page=' + page + '&';

    if (filters && filters.reference) {
      query += 'filters[reference]=' + filters.reference + '&';
    }

    if (filters && filters.search) {
      query += 'filters[search]=' + filters.search + '&';
    }

    if (filters && filters.status) {
      query += 'filters[status]=' + filters.status + '&';
    }

    return this
      .api
      .get('pickups-today' + query)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };

  getOrder = (orderId) => {
    return this
      .api
      .get('orders/' + orderId)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
}

export default ApiService;
