import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CardHeader,
  Divider
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { ArrowUpward } from '@material-ui/icons';
import reactStars from 'react-rating-stars-component';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceIconUp: {
    color: colors.green[900]
  },
  differenceValueUp: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const BlockMap = ({ className, values, since, title, ...rest }) => {
  const classes = useStyles();
  const [sla, setSla] = useState([]);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getSlaState({
        since
      })
      .then((res) => {
        setSla(res.states ? res.states : []);
      });
  }, [since]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Por Estado"
      />
      <Divider />
      <CardContent >
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item xs={12}>
            <PerfectScrollbar>
               <Table>
                <TableHead>
                {sla.sort(function (x, y) {
                  return y.total - x.total;
              }).map((st) => <TableCell>{st.state}</TableCell>)}
                </TableHead>
                <TableBody>
                  <TableRow>
                  {sla.sort(function (x, y) {
                  return y.total - x.total;
              }).map((st) => <TableCell>
                  <div style={{whiteSpace: 'nowrap'}}>Total: {st.total} <br/> Percentual: <strong>{st.percentage}%</strong> <br/> Prazo médio: <strong style={{color: st.deadline_avg <= 3 ? '#4caf51' : (st.deadline_avg > 3 && st.deadline_avg < 7 ? '#000' : '#e53a35')}}>{st.deadline_avg}</strong> dias úteis </div></TableCell>)}
                  
                                      </TableRow>

                </TableBody>
              </Table>
              </PerfectScrollbar>
           </Grid>
        </Grid>
        
      </CardContent>
    </Card>
  );
};

BlockMap.propTypes = {
  className: PropTypes.string
};

export default BlockMap;
