import React, { useState } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({ className, setSince, since, ...rest }) => {
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);

  const downloadFile = (data, fileName, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  const newOrder = () => {
    setDownloading(true);
    const apiService = new ApiService();
    apiService
      .generateReport('orders')
      .then((res) => {
        downloadFile(res, `report-${(new Date()).getTime()}.csv`, "application/csv");
        setDownloading(false);
      }).catch(() => {
        setDownloading(false);
      });
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          Dashboard
        </Typography>

        <Button
          color="primary"
          variant="contained"
          onClick={newOrder}
          className={classes.successButton}
          style={{ marginRight: 20 }}
          disabled={downloading}
        >
          {downloading ? "AGUARDE..." : "GERAR RELATÓRIO DE PEDIDOS"}
        </Button>

        <Select onChange={(ev) => setSince(ev.target.value)} value={since}>
          <MenuItem key={7} value={7}>Últimos 7 dias</MenuItem>
          <MenuItem key={15} value={15}>Últimos 15 dias</MenuItem>
          <MenuItem key={30} value={30}>Últimos 30 dias</MenuItem>
          <MenuItem key={90} value={90}>Últimos 90 dias</MenuItem>
        </Select>
      </Box>
    </div>
  );
};

export default Toolbar;
