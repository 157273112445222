import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import EmptyState from '@atlaskit/empty-state';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="404"
    >
      <EmptyState
        header="Ops! Área não encontrado"
        description="Não encontramos o recurso solicitado."
        primaryAction={<Button appearance="primary" onClick={() => window.location.href = '/'}>Voltar</Button>}
      />
    </Page>
  );
};

export default NotFoundView;
