import React from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));


const Toolbar = ({ className, onClickRefresh, isRefreshing, title, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          {title ? title : 'Cotação'}
        </Typography>

        

        {onClickRefresh ? <Tooltip enterDelay={300} title={"Atualizar"}>
          <IconButton onClick={onClickRefresh} disabled={isRefreshing}>
            <Refresh />
          </IconButton>
        </Tooltip> : <Button onClick={() => navigate('/quote/logs')} color="default" variant="contained" autoFocus>
            Histórico de Cotações
          </Button>}
      </Box>
    </div>
  );
};

export default Toolbar;
