import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import moment from 'moment';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SwipeableDrawer,
  Grid,
  Table,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  LinearProgress
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';


import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import OrderRow from '../orders/OrdersView/OrderRow';
import { Calendar, Check, CheckCircle, File, Package, Truck } from 'react-feather';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  trackerHeaderStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadBar: {
    height: 4,
    width: '100%',
    backgroundColor: '#ddd',
  },
  loadBarCompleted: {
    height: 4,
    width: '100%',
    backgroundColor: '#8BC34A'
  },
  iconCompleted: {
    color: '#8BC34A'
  },
  iconNormal: {
    color: '#727272'
  }
}));

const NotfisView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const [pickup, setPickup] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupCode, setPickupCode] = useState(null);
  const [files, setFiles] = useState([]);
  const { token } = useParams();

  const confirmPickup = () => {
    apiService
      .confirmPickupsFromCarrierToken(token, pickupDate, pickupCode)
      .then((res) => {
        setIsConfirming(false);
        setConfirmed(true);
        setFiles(res.files);
      })
  }

  useEffect(() => {
    console.log(token);
    apiService
      .getPickupsFromCarrierToken(token)
      .then((res) => {
        setPickup(res);
        setFiles(res.pickup.files || []);
        if (res.pickup.pickup_date) {
          setPickupDate(res.pickup.pickup_date);
          setPickupCode(res.pickup.integration_code);
          setConfirmed(true);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [token]);

  return (
    <Page
      className={classes.root}
      title="Arquivos para coleta"
    >
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 0, marginBottom: 60, background: '#f5f6f8' }}>
        <img src="https://flixlog.com/images/flixlogo.png" style={{ width: 150, margin: '0 auto' }} />
      </div>

      <Box style={{ margin: '40px auto', maxWidth: '800px' }}>
        <Grid container >
          {pickup && <Grid item xs={12}>


            <Typography color="textPrimary" variant="h1" style={{ marginBottom: 30 }}>
              Olá, {pickup.carrier.canonical_name.toUpperCase()}
            </Typography>

           <Alert severity="error" style={{ color: 'red' }} >
              <AlertTitle>Observação do cliente:</AlertTitle>
              {pickup.pickup.note ? <>{pickup.pickup.note}<br/><br/></> : null}

              <p>
                      <strong>Horários de coleta</strong> <br/> 
                      {pickup.pickup_window.map((pic) => <>
                        {pic}<br />
                      </>)}
                    </p>
            </Alert> 

            {/* <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={12}>
                <Card style={{ backgroundColor: '#242424', padding: 17 }}>
                  <Typography color="textPrimary" variant="h4" style={{ fontSize: 15, color: '#fc0' }}>
                    1 - Coleta
                  </Typography>
                </Card>
              </Grid>
            </Grid> */}

            <Grid container>
              <Grid item xs={12} lg={6} style={{ marginBottom: 20 }}>
                <Card>
                  <CardHeader title="LOCAL DE COLETA" />
                  <CardContent>
                    <p className={classes.content} style={{ lineHeight: '1.5em' }}>
                      <strong>CD:</strong> {pickup.distribution_center.reference}<br />
                      <strong>Razão Social:</strong> {pickup.distribution_center.name}<br />
                      <strong>CNPJ:</strong> {pickup.distribution_center.address.federal_tax_id}<br />
                      <strong>Endereço:</strong> <br /> {pickup.distribution_center.format_address}
                      {pickup.distribution_center && pickup.distribution_center.address && pickup.distribution_center.address.phone
                        ? <> <br /><strong>Telefone:</strong> {pickup.distribution_center.address.phone}<br /></> : null}

                    </p>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card>
                  <CardHeader title="RESUMO" />

                  <CardContent>
                    <p className={classes.content} style={{ lineHeight: '1.5em' }}>
                      <strong>Quantidade de volumes:</strong> {pickup.pickup.totals.total_volumes} <br />
                      <strong>Peso total da coleta:</strong> {pickup.pickup.totals.total_weight}kg <br />
                      <strong>Valor total da carga:</strong> R$ {pickup.pickup.totals.cargo_value} <br />
                    </p>
                  </CardContent>
                </Card>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader title="Arquivos para download" />
                  <CardContent>

                    {confirmed ? <><Alert severity="success" >
                      <AlertTitle>Parabéns!</AlertTitle>
                      A coleta foi confirmada para o dia {moment(pickupDate).format('DD/MM/YYYY')}
                    </Alert><br /><br /></> : null}

                    {confirmed && files.length > 0 ? <>
                      <ul style={{ listStyleType: 'none' }}>
                        {files.map((res) => <li style={{ marginBottom: 20 }}>
                          <a href={res.file} target="_blank" download style={{ color: '#03a9f4', display: 'flex', alignItems: 'center' }}>
                            <File style={{ marginRight: 10 }} /> {res.description}</a>
                        </li>)}
                      </ul>
                    </> : <>


                      <Alert severity="warning" >
                        <AlertTitle>Importante!</AlertTitle>
                        Para visualizar os arquivos das <strong>Notas Fiscais</strong> você precisa informar a <strong>Data de Coleta</strong> e clicar no botão <strong>CONFIRMAR COLETA</strong>.
                      </Alert>



                      <Grid container style={{ marginTop: 50 }}>

                        <Grid xs={12} lg={4} item>
                          <TextField
                            onChange={(e) => setPickupCode(e.target.value)}
                            label="Código da Coleta"
                            variant="outlined"
                            style={{ marginBottom: '10px' }}
                            InputLabelProps={{
                              shrink: true,
                            }} />
                          <small style={{
                            display: 'block',
                            fontSize: '11px',
                            color: '#242424'
                          }}>Você pode informar aqui o código da coleta <br /> gerada no seu sistema.</small>
                        </Grid>
                        <Grid xs={12} lg={4} item>
                          <TextField
                            type="date"
                            onChange={(e) => setPickupDate(e.target.value)}
                            label="Data da Coleta"
                            variant="outlined"
                            style={{ marginBottom: '10px' }}
                            InputLabelProps={{
                              shrink: true,
                            }} />
                        </Grid>
                        <Grid xs={12} lg={4} item>
                          <Button
                            color="primary"
                            style={{ backgroundColor: '#8bc34a', height: '55px' }}
                            disabled={isConfirming}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                              if (!pickupDate) {
                                alert('Informe a data estimada de coleta');
                              } else {
                                setIsConfirming(true);
                                confirmPickup();
                              }

                            }}
                          >
                            {isConfirming ? 'CONFIRMANDO ...' : 'CONFIRMAR COLETA'}
                          </Button>
                        </Grid>
                      </Grid>
                    </>}

                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Box>
    </Page >
  );
};

export default NotfisView;
