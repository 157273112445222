import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Box,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  makeStyles,
  LinearProgress,
  withStyles,
  Checkbox
} from '@material-ui/core';

import {
  ArrowUp as ArrowUpIcon,
  ArrowDown as ArrowDownIcon,
} from 'react-feather';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  reference: {
    fontSize: '.8125rem',
    whiteSpace: 'nowrap',
    display: 'block',
    color: '#525f7f'
  },
  last_status: {
    fontSize: '22px !important',
    color: '#000',
    marginTop: 5,
    marginBottom: 7
  },
  last_status_drop: {
    fontSize: '15px !important',
    color: '#000',
    lineHeight: '23px',
    marginTop: 5,
    marginBottom: 7
  },
  city: {
    fontSize: '22px !important',
    color: '#000',
    marginTop: 5,
    marginBottom: 7,
    display: 'flex'
  },
  date: {
    color: '#aaa',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  iconUp: {
    padding: 5,
    color: '#fff',
    backgroundColor: '#000',
    borderRadius: '50%',
    marginRight: 10
  },
  iconDown: {
    padding: 5,
    color: '#fff',
    backgroundColor: '#fc0',
    borderRadius: '50%',
    marginRight: 10
  },
  progressbars: {
    width: '100%',
    display: 'flex',
    marginTop: 10,
    height: 4
  },
  progressbar: {
    width: '24%',
    marginRight: '1%'
  },
  progressbarStoped: {
    backgroundColor: '#ddd',
    width: '24%',
    marginRight: '1%'
  },
  progressbarCompleted: {
    backgroundColor: '#ffcc00',
    width: '24%',
    marginRight: '1%'
  },
  progressDelivered: {
    backgroundColor: '#8BC34A',
    width: '24%',
    marginRight: '1%'
  },
  progressError: {
    backgroundColor: '#e91e1e',
    width: '24%',
    marginRight: '1%'
  }
}));

const FlixlogProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#ddd'
  },
  bar: {
    backgroundColor: '#ffcc00',
  },
}))(LinearProgress);

const OrderRow = ({ className, primaryColor, onClick, noStatus, order, miniVersion, handleSelectOne, selectedRows, newUrl, hidePrice, hideOrigin, ...rest }) => {
  const classes = useStyles();
  
  const channelIconsUrl = (channel) => {
    var urls = {
      madeiramadeira: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      tiny: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      bling: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      tray: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      b2w: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      magalu: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      viavarejo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      woo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      'tray-corp': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      'fbitsws': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      omie: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
      simplo7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      leroy: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      nuvemshop: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
      mercadolivre: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
    }
    return urls[channel.toLocaleLowerCase()] || null;
  }

  return (
    <TableRow
      hover
      key={order.id}
      onClick={() => onClick ? onClick() : null}
    >
      {selectedRows ? <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows.indexOf(order.reference) !== -1}
          onChange={(event) => handleSelectOne(event, order.reference)}
          value="true"
        />
      </TableCell> : null}
      <TableCell component={Link} target={miniVersion ? '_blank' : null} to={newUrl ? newUrl : `/orders/${order.id}/`} >
        <span className={classes.reference} style={hideOrigin ? { whiteSpace: 'inherit' } : {}}>{order.reference.toUpperCase()} - {order.to_address.name ? order.to_address.name.toUpperCase() : (order.to_address.business_name ? order.to_address.business_name.toUpperCase() : '')}</span>
        
        {order.is_drop_shipping ? <Typography
          color="textPrimary"
          variant="h2"
          className={classes.last_status}
        >
            {!noStatus ? [...new Set(order.shipments.map((ship) => ship.status.description))].join(', ') : null}
        </Typography>
        : <Typography
          color="textPrimary"
          variant="h2"
          className={classes.last_status}
        >
          {!noStatus ? order.status.description : null}
        </Typography>}
        
        {miniVersion ? null : <date className={classes.date}>{moment(order.created_at).format('DD/MM/YYYY HH')}h</date>}  {order.carrier_name ? (order.is_drop_shipping ? <strong>{[...new Set(order.shipments.map((ship) => ship.carrier.canonical_name.toUpperCase()))].join(', ')}</strong> : <strong>{order.carrier_name.toUpperCase()}</strong>) : null}
        {/* {order.status} */}
        {order.status_transition && !miniVersion ? (
          <Box className={classes.progressbars}>
            {order.status_transition.map((transition) => (order.status.id === 5 ? <div className={classes.progressDelivered} /> : (order.status.id === 8 || order.status.id === 10 || order.status.id === 12 ? <div className={classes.progressError} /> :
              (<Tooltip
                enterDelay={300}
                title={transition.title}
                key={transition.status}
              >
                {transition.in_progress ? <FlixlogProgress className={classes.progressbar} /> : (transition.completed ? <div className={classes.progressbarCompleted} /> : <div className={classes.progressbarStoped} />)}
              </Tooltip>
              ))))}
          </Box>
        ) : null}
      </TableCell>

      {hideOrigin ? null :
        (order.is_drop_shipping ?
          <TableCell component={Link} target={miniVersion ? '_blank' : null} to={newUrl ? newUrl : `/orders/${order.id}/`} >
            <Typography
              color="textPrimary"
              variant="h3"
              className={classes.city}
            >
              <ArrowUpIcon className={classes.iconUp} />
              {`${order.total_origins} Origens`}
            </Typography>
            
          </TableCell>
          : <TableCell component={Link} target={miniVersion ? '_blank' : null} to={newUrl ? newUrl : `/orders/${order.id}/`} >
            <Typography
              color="textPrimary"
              variant="h3"
              className={classes.city}
            >
              <ArrowUpIcon className={classes.iconUp} />
              {order.from_address.city}
            </Typography>
            {miniVersion ? null : <><date className={classes.date} style={{ marginLeft: 35 }}>{order.pickup && order.pickup.estimated_date ? moment(order.pickup.estimated_date).format('DD/MM/YYYY') : moment(order.created_at).format('DD/MM/YYYY')}<br /></date>
              <date className={classes.date} style={{ marginLeft: 35 }}>{order.pickup && order.pickup.estimated_time_end ? order.pickup.estimated_time_end : moment(order.created_at).format('HH') + 'h'}<br /></date></>}
          </TableCell>
        )}
      <TableCell component={Link} target={miniVersion ? '_blank' : null} to={newUrl ? newUrl : `/orders/${order.id}/`} >
        <Typography
          color="textPrimary"
          variant="h3"
          className={classes.city}
        >
          <ArrowDownIcon className={classes.iconDown} style={primaryColor ? {backgroundColor: primaryColor} : {}} />
          {order.to_address.city}
        </Typography>
        {order.delivery && !miniVersion ? (
          order.is_drop_shipping ? null : <>
            <date className={classes.date} style={{ marginLeft: 35 }}>
              <Tooltip
                enterDelay={300}
                title="Data limite de entrega"
              >
                <span>{order.delivery ? moment(order.delivery.delivery_date).format('DD/MM/YYYY') : null}</span>
              </Tooltip>
            </date>
            <br />
            <date className={classes.date} style={{ marginLeft: 35 }}>
              <Tooltip
                enterDelay={300}
                title="Intervalo de horário em que essa transportadora fará a entrega"
              >
                <span>09h - 18h</span>
              </Tooltip>
            </date>
          </>
        ) : (<><br /><br /></>)}

      </TableCell>
      {hidePrice || miniVersion ? null : (
        <>
          <TableCell component={Link} to={newUrl ? newUrl : `/orders/${order.id}/`} >
            {order.property && order.property.channel ? (
              <>
                <pre style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>{channelIconsUrl(order.property.channel) ? <img style={{ width: 30, height: 30, objectFit: 'cover' }} src={channelIconsUrl(order.property.channel)} /> : <strong>{order.property.channel}</strong>}: {order.property.channel_entity_id}</pre>
                {order.property.marketplace ? (
                  <pre style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>{channelIconsUrl(order.property.marketplace) ? <img style={{ width: 30, height: 30, objectFit: 'cover' }} src={channelIconsUrl(order.property.marketplace)} /> : <strong>{order.property.marketplace}</strong>}: {order.property.marketplace_order_number}</pre>
                ) : null}
              </>
            ) : '-'}
          </TableCell>
          <TableCell component={Link} to={newUrl ? newUrl : `/orders/${order.id}/`} >
            {order.estimated_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </TableCell>
        </>
      )}
    </TableRow>
  )
};

OrderRow.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderRow;
