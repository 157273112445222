import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  Card,
  TextField,
  Typography,
  makeStyles,
  Avatar,
  Grid,
  CardContent,
  CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}


const OAuthNuvemShop = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openOnboardingInfo, setOpenOnboardingInfo] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const apiService = new ApiService();
  const flistLogUrl = `${window.location.protocol}//${window.location.host}/settings/integrations/nuvemshop`;
  const clientId = '3002';
  const channelId = 29;
  const isAuth = localStorage.getItem('@flixlog-token');

  useEffect(() => {
    const code = getQueryVariable('code');
    apiService
      .createPresignup(code)
      .then((res) => {
        if (res && res.store_token) {
          window.localStorage.setItem('@ns/store-token', res.store_token);
        }
        setLoading(false);
      });
  }, []);

  const openOnboardinInfo = () => {
    setOpenOnboardingInfo(true);
    // const storeId = window.localStorage.getItem('@ns/store-id');

    // apiService.allowContact(storeId);
  }

  const allowContact = () => {
    setLoading(true);
    const storeToken = window.localStorage.getItem('@ns/store-token');
    apiService.allowContact(storeToken).then(() => {
      setLoading(false);
      setCongrats(true);
    });
  }

  const getStoreId = () => {
    return window.localStorage.getItem('@ns/store-token');
  }

  return (
    <Page
      className={classes.root}
      title="Nuvemshop + Flixlog"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Box position="relative" height="50vh" maxHeight="500px">
                <Box position="absolute" borderRadius="10px" width="310px" height="310px" overflow="hidden" style={{
                  boxShadow: '0 10px 55px 5px rgb(26 26 26 / 35%)',
                  bottom: 0, left: '3rem', zIndex: 1
                }}>
                  <img src="/static/images/channels/nuvemshop-logo-lp.png" style={{ objectFit: 'cover', width: '100%', boxShadow: '0 10px 55px 5px rgb(26 26 26 / 35%)', height: '100%' }} />
                </Box>
                <Box position="absolute" borderRadius="10px" width="310px" height="310px" overflow="hidden" style={{
                  boxShadow: '0 10px 55px 5px rgb(26 26 26 / 35%)',
                  top: 0, right: '3rem', zIndex: 2
                }}>
                  <img src="/static/images/channels/flixlogo-lp.png" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                </Box>
              </Box>

            </Grid>
            <Grid item lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="h1" style={{ fontFamily: 'Syne, sans-serif' }}>Flixlog + Nuvemshop</Typography>

              {congrats ? <p style={{
                marginTop: 20, marginBottom: 40, fontSize: 23, fontWeight: 300, color: '#444444', background: '#acf49a',
                padding: '10px',
                fontSize: '19px',
                fontWeight: '100'
              }}>
                Tudo certo 😃! Em algumas horas nosso time comercial entrará em contato para ativar a sua conta.
              </p> : null}
              {openOnboardingInfo
                ? (
                  <>
                    <p style={{ marginTop: 20, marginBottom: 30, fontSize: 23, fontWeight: 300, color: '#444444' }}>
                      Ficamos felizes com seu interesse em utilizar a Flixlog. <br /> <br />
                      Somos um Hub de Frete focado no envio de produtos volumosos, <a href="https://flixlog.com" style={{ color: '#242424', fontWeight: 'bold' }} target="_blank">saiba mais aqui</a>. <br />  <br />
                      Em parceria com a <strong style={{ color: '#2c3357' }}>Nuvemshop</strong> estamos oferecendo um <strong style={{ background: "#ffcc00", color: '#242424', padding: '3px' }}>desconto de 15%</strong> em nossa mensalidade. <br />
                    </p>

                  </>)
                : <p style={{ marginTop: 20, marginBottom: 30, fontSize: 23, fontWeight: 300, color: '#444444' }}>
                  {isAuth ? null: 'Você já possui conta na Flixlog?'}

                  {isAuth ? <Box display="flex" alignItems="center">
                              <CheckCircleOutline style={{width: 200, height: 200, color: '#4caf50'}} />
                              <span style={{
                                color: '#4caf51',
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}>
                              Integração realizada com sucesso! Clique no botão abaixo para acessar a Flixlog.
                              </span>
                            </Box> : null}
                </p>}



              {openOnboardingInfo ? <Box display="flex">
                {congrats ? <Button color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ marginRight: 30 }}
                  style={{ backgroundColor: '#2c3357', color: '#fff' }}
                  onClick={() => window.location.href = 'https://www.nuvemshop.com.br/minha-loja'}>VOLTAR PARA NUVEMSHOP</Button> : <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ marginRight: 30 }}
                    disabled={loading}
                    onClick={allowContact}
                  // onClick={toLogin}
                  >SOLICITAR CONTATO COMERCIAL</Button>}
              </Box>
                : <Box display="flex">
                  {isAuth ? <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ marginRight: 30 }}
                    disabled={loading}
                    onClick={() => window.location.href = '/settings/integrations'}
                  >ACESSAR INTEGRAÇÕES</Button> : <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ marginRight: 30 }}
                    disabled={loading}
                    onClick={() => window.location.href = '/login?sid=' + getStoreId()}
                  // onClick={toLogin}
                  >SIM</Button>}
                  {!isAuth ? <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#2c3357', color: '#fff' }}
                    disabled={loading}
                    onClick={openOnboardinInfo}
                  // onClick={toRegister}
                  >AINDA NÃO</Button> : null}
                </Box>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(OAuthNuvemShop);
