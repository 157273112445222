import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Container,
  Grid,
  makeStyles,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Tooltip,
  Avatar
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Sales from './Sales';
import ShipmentsConfirmed from './ShipmentsConfirmed';
import SlaCarriers from './SlaCarriers';
import Toolbar from './Toolbar';
import BlockMoney from './BlockMoney';
import PickupCohort from './PickupCohort';
import BlockReview from './BlockReview';
import BlockDeadline from './BlockDeadline';
import ReactStars from "react-rating-stars-component";
import BlockMap from './BlockMap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [since, setSince] = useState(30);
  const [pickupCohort, setPickupCohort] = useState([]);
  const [shipmentConfirmed, setShipmentConfirmed] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [ordersConfirmed, setOrdersConfirmed] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [amountFreight, setAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [amountFreightTicket, setAmountFreightTicket] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [totalOrders, setTotalOrders] = useState([]);
  const [reviewCounter, setReviewCounter] = useState([]);
  const [reviews, setReviews] = useState([]);
  const apiService = new ApiService();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  
  const reloadReviews = (newValue) => {
    setReviews([]);
    setLoading(true);
    apiService
      .getReviews({
        rate: (5 - newValue)
      })
      .then((res) => {
        setReviews(res.data);
        setLoading(false);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
    reloadReviews(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    reloadReviews(newValue);
  };

  useEffect(() => {
    apiService
      .getDashboard({
        since
      })
      .then((res) => {
        setPickupCohort(res.shipments_cohort);
        setOrdersConfirmed(res.orders_confirmed);
        setAmountFreight(res.amount_freight);
        setAmountFreightTicket(res.amount_freight_ticket);
        setTotalOrders(res.shipments_per_day);
        setShipmentConfirmed(res.shipments_confirmed);
        setReviewCounter(res.review_counter);
      });
  }, [since]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Toolbar setSince={setSince} since={since} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ShipmentsConfirmed
              title="PEDIDOS CONFIRMADOS"
              values={ordersConfirmed}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ShipmentsConfirmed
              title="VOLUMES CONFIRMADOS"
              values={shipmentConfirmed}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="GASTO COM FRETE"
              values={amountFreight}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="TICKET MÉDIO POR PEDIDO"
              values={amountFreightTicket}
              since={since}
            />
          </Grid>
         
          
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Sales totalOrders={totalOrders} />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <SlaCarriers since={since} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'lg'}

      >
        <DialogTitle id="scroll-dialog-title">Avaliações de entregas</DialogTitle>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={<ReactStars
            value={5}
            size={20}
            edit={false}
            activeColor="#ffcc00"
          />} />

          <Tab label={<ReactStars
            value={4}
            size={20}
            edit={false}
            activeColor="#ffcc00"
          />} />

          <Tab label={<ReactStars
            value={3}
            size={20}
            edit={false}
            activeColor="#ffcc00"
          />} />
        
          <Tab label={<ReactStars
            value={2}
            size={20}
            edit={false}
            activeColor="#ffcc00"
          />} />

          <Tab label={<ReactStars
            value={1}
            size={20}
            edit={false}
            activeColor="#ffcc00"
          />} />
        </Tabs>

        <DialogContent>
          <DialogContentText
            tabIndex={-1}
          >

            <ul class="timeline message">
              {reviews.map((review) => {
                return (<li>

                  <span style={{ display: 'flex', width: '50%', alignItems: 'center', textAlign: 'left' }}>
                    <Tooltip enterDelay={300} title={review.user.name} key={review.id}>
                      <Avatar className={classes.purple}>{review.user.name ? review.user.name.substring(0, 1) : ''}</Avatar>
                    </Tooltip>

                    <small style={{ color: '#5a5a5a', fontWeight: 'normal', marginLeft: 10 }} >
                      <a href={`/orders/${review.shipment.order_id}`} style={{color: '#000', fontWeight: 'bold'}} target="_blank">#{review.shipment.reference}</a> <br/>
                      {review.message ? review.message : 'Não deixou mensagem...'}
                      </small>
                  </span>
                  <span class="float-right">{moment(review.created_at).format('DD/MM/YYYY HH:MM')}</span>
                </li>);
              })}

              {loading == false && reviews.length == 0 ? <li>Nenhuma avaliação com {5 - value} estrelas</li> : null}
              {loading == true ? <li><strong>Carregando mensagens ...</strong></li> : null}
            </ul>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

            <BlockReview
             style={{cursor: 'pointer'}}
              onClick={handleClickOpen}
              reviews={reviewCounter}
              since={since}
            />

          </Grid>

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockDeadline
              since={since}
            />

          </Grid>

          <Grid
            item
            
            xs={12}
          >
            <BlockMap
              since={since}
            />

          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
