import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import Page from 'src/components/Page';
import StepOne from './StepOne';
import Sheet from './Sheet';
import Toolbar from './Toolbar';

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});

class FreightTablesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      layout: {},
      saving: false,
      grid: []
    };

    this.save = this.save.bind(this);
  }

  save = () => {
    const apiService = new ApiService();
    const { grid, layout } = this.state;
    const { enqueueSnackbar } = this.props;
    this.setState({ saving: true });
    apiService.saveFreightTable({
      layout,
      data: grid
    }).then(() => {
      enqueueSnackbar('Tabela de frete gravada com sucesso.', {
        variant: 'success',
      });
      window.location.reload();
    }).catch((error) => {
      enqueueSnackbar('Ocorreu um erro ao tentar gravar a tabela, tente novamente. ' + error.message, {
        variant: 'error',
      });
    });
  }

  setLayout = (data) => {
    let sta = this.state;
    sta['layout'] = data;
    this.setState(sta);

    let template = ['Região de Destino'];
    const totalFaixas = this.state.layout.range_weights.length;

    this.state.layout.range_weights.forEach((faixa) => {
      template.push('Até ' + faixa + ' kg');
    });

    template.push('Adicional/kg');
    template.push('Prazo');
    template.push('Despacho/CTE(R$)');
    template.push('Pedágio(R$)');
    template.push('ADV(%)');
    template.push('ADV Mínimo (R$)');
    template.push('GRIS(%)');
    template.push('GRIS Mínimo (R$)');

    var grid = [];

    let destinos = data.region_type == 0 ? this.state.layout.custom_regions : this.state.layout.regions;
    console.log(this.state.layout.regions, destinos);

    grid.push(template);

    destinos.forEach((destino) => {
      const tempTemplate = JSON.parse(JSON.stringify(template));
      tempTemplate[0] = destino;

      for (let i = 1; i <= totalFaixas; i++) {
        tempTemplate[i] = '';
      }
      let totalFaixasCurrent = totalFaixas + 1;

      tempTemplate[totalFaixasCurrent++] = '0,00';
      tempTemplate[totalFaixasCurrent++] = '';
      tempTemplate[totalFaixasCurrent++] = this.state.layout.tdc;
      tempTemplate[totalFaixasCurrent++] = this.state.layout.toll;
      tempTemplate[totalFaixasCurrent++] = this.state.layout.adv;
      tempTemplate[totalFaixasCurrent++] = this.state.layout.minAdv;
      tempTemplate[totalFaixasCurrent++] = this.state.layout.gris;
      tempTemplate[totalFaixasCurrent++] = this.state.layout.minGris;

      grid.push(tempTemplate);
    });

    this.setState({ grid });
    window.localStorage.setItem('lastFreightGrid', JSON.stringify(grid));
  }

  render() {
    const { classes } = this.props;

    return (
      <Page
        title='Tabela de Frete'
        className={classes.root}
      >
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <Card>
              <CardHeader
                title="Gerar layout da tabela"
              />
              <Divider />
              <CardContent>
                <StepOne setLayout={this.setLayout} />
              </CardContent>
            </Card>
            <Divider />
            <Card>
              <CardHeader
                title="Tabela para edição"
              />
              <Divider />
              <CardContent>
                <Sheet grid={this.state.grid} />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={this.state.saving}
                  onClick={this.save}
                >
                  Gravar tabela
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Page>
    );
  }
}


export default withStyles(useStyles)(withSnackbar(FreightTablesView));
