import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import ApiService from 'src/common/apiService';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FreightTableListView = () => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [freightTables, setFreightTables] = useState([]);
  const apiService = new ApiService();
  const [limit, changeLimit] = useState(10);
  const [page, changePage] = useState(1);

  const refresh = (filters) => {
    apiService
      .getFreightTables(filters, limit, page)
      .then((res) => {
        setFreightTables(res.data);
        setTotal(res.total);
      });
  };

  useEffect(() => {
    refresh({});
  }, [limit, page]);

  return (
    <Page
      className={classes.root}
      title="Tabelas de Frete"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results
            freightTables={freightTables}
            total={total}
            changePage={changePage}
            changeLimit={changeLimit}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default FreightTableListView;
