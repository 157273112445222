import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OrderRow from './OrderRow';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Results = ({ className, orders, miniVersion, totalRows, changeLimit, changePage, selectedRows, handleSelectAll, handleSelectOne, setSelectedRowsIds, limits, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === orders.length}
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0
                      && selectedRows.length < orders.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Remessa
                </TableCell>
                <TableCell>
                  Coleta
                </TableCell>
                <TableCell>
                  Entrega
                </TableCell>
                
                {miniVersion ? null : <>
                  <TableCell>
                    Integração
                  </TableCell>
                  <TableCell>
                    Custo Estimado
                  </TableCell></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.slice(0, limit).map((order) => <OrderRow miniVersion={miniVersion} order={order} handleSelectOne={handleSelectOne} selectedRows={selectedRows} />)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={limits ? limits : [10, 20, 30]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

export default Results;
