import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        reference: Yup.string(' ').max(20, 'Deve ter no máximo 20 caracteres').required('Digite um código para esse CD'),
        name: Yup.string(' ').max(20, 'Deve ter no máximo 20 caracteres').required('Digite um nome para esse CD'),
        expedition_time: Yup.number(0).max(365),
        address_name: Yup.string('').max(150, 'Deve ter no máximo 150 caracteres').required('Digite a razão social'),
        address_federal_tax_id: Yup.string('Apenas números').min(14).max(14).required('Digite o CNPJ, apenas números.'),
        address_phone: Yup.string('Apenas números').min(10).max(11, 'Deve ter no máximo 11 dígitos').required('Digite um telefone.'),
        address_email: Yup.string('').email('Digite um e-mail válido').max(255, 'Deve ter no máximo 255 caracteres'),
        address_zip: Yup.string('Apenas números').min(8, 'Deve ter 8 dígitos').max(8, ' ').required('Digite um CEP válido.'),
        address_street1: Yup.string('').required('Digite o endereço.'),
        address_number: Yup.string('').required('Digite o número.'),
        address_neighborhood: Yup.string('').required('Digite o bairro.'),
        address_city: Yup.string('').required('Digite a cidade.'),
        address_state: Yup.string('').min(2, 'Deve ter 2 caracters').max(2, '').required('Selecione o estado.')
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {title ? title : "Novo"}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Cadastre aqui um novo centro de distribuição: Estoque próprio ou Fornecedor no modelo de drop-shipping
                    </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={2}>
              <TextField
                error={Boolean(touched.reference && errors.reference)}
                helperText={touched.reference && errors.reference}
                label="Código de referência"
                margin="normal"
                name="reference"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reference}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Nome do CD"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                error={Boolean(touched.expedition_time && errors.expedition_time)}
                helperText={touched.expedition_time && errors.expedition_time}
                label="Prazo de Expedição"
                margin="normal"
                type="number"
                name="expedition_time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expedition_time}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6} lg={5}>
              <TextField
                error={Boolean(touched.address_name && errors.address_name)}
                helperText={touched.address_name && errors.address_name}
                label="Razão Social"
                margin="normal"
                fullWidth
                style={{ paddingRight: 20 }}
                name="address_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={7}>
              <TextField
                error={Boolean(touched.address_federal_tax_id && errors.address_federal_tax_id)}
                helperText={touched.address_federal_tax_id && errors.address_federal_tax_id}
                label="CNPJ"
                margin="normal"

                name="address_federal_tax_id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_federal_tax_id}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                error={Boolean(touched.address_phone && errors.address_phone)}
                helperText={touched.address_phone && errors.address_phone}
                label="Telefone"
                margin="normal"
                fullWidth
                style={{ paddingRight: 20 }}
                name="address_phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                error={Boolean(touched.address_email && errors.address_email)}
                helperText={touched.address_email && errors.address_email}
                label="E-mail"
                margin="normal"
                name="address_email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_email}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={2}>
              <TextField
                error={Boolean(touched.address_zip && errors.address_zip)}
                helperText={touched.address_zip && errors.address_zip}
                label="CEP"
                margin="normal"
                name="address_zip"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_zip}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={5}>
              <TextField
                error={Boolean(touched.address_street1 && errors.address_street1)}
                helperText={touched.address_street1 && errors.address_street1}
                label="Endereço"
                margin="normal"
                fullWidth
                style={{ paddingRight: 20 }}
                name="address_street1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_street1}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={7}>
              <TextField
                error={Boolean(touched.address_number && errors.address_number)}
                helperText={touched.address_number && errors.address_number}
                label="Número"
                margin="normal"
                name="address_number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_number}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <TextField
                error={Boolean(touched.address_street2 && errors.address_street2)}
                helperText={touched.address_street2 && errors.address_street2}
                label="Complemento"
                margin="normal"
                fullWidth
                style={{ paddingRight: 20 }}
                name="address_street2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_street2}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} lg={8}>
              <TextField
                error={Boolean(touched.address_neighborhood && errors.address_neighborhood)}
                helperText={touched.address_neighborhood && errors.address_neighborhood}
                label="Bairro"
                margin="normal"
                name="address_neighborhood"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_neighborhood}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} lg={2}>
              <TextField
                error={Boolean(touched.address_city && errors.address_city)}
                helperText={touched.address_city && errors.address_city}
                label="Cidade"
                margin="normal"
                name="address_city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_city}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Estado</InputLabel>
                <Select
                  error={Boolean(touched.address_state && errors.address_state)}
                  helperText={touched.address_state && errors.address_state}
                  label="Estado"
                  margin="normal"
                  name="address_state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address_state}
                  variant="outlined"
                >
                  <MenuItem value="AC">AC</MenuItem>
                  <MenuItem value="AL">AL</MenuItem>
                  <MenuItem value="AP">AP</MenuItem>
                  <MenuItem value="AM">AM</MenuItem>
                  <MenuItem value="BA">BA</MenuItem>
                  <MenuItem value="CE">CE</MenuItem>
                  <MenuItem value="DF">DF</MenuItem>
                  <MenuItem value="ES">ES</MenuItem>
                  <MenuItem value="GO">GO</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="MT">MT</MenuItem>
                  <MenuItem value="MS">MS</MenuItem>
                  <MenuItem value="MG">MG</MenuItem>
                  <MenuItem value="PA">PA</MenuItem>
                  <MenuItem value="PB">PB</MenuItem>
                  <MenuItem value="PR">PR</MenuItem>
                  <MenuItem value="PE">PE</MenuItem>
                  <MenuItem value="PI">PI</MenuItem>
                  <MenuItem value="RJ">RJ</MenuItem>
                  <MenuItem value="RN">RN</MenuItem>
                  <MenuItem value="RS">RS</MenuItem>
                  <MenuItem value="RO">RO</MenuItem>
                  <MenuItem value="RR">RR</MenuItem>
                  <MenuItem value="SC">SC</MenuItem>
                  <MenuItem value="SP">SP</MenuItem>
                  <MenuItem value="SE">SE</MenuItem>
                  <MenuItem value="TO">TO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Divider />

          <br />
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {"Horários de coleta"}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Defina aqui quais os melhores horários de coleta para este CD. Deixe em  bracos os dias que não podem haver coletas
                    </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  {['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta'].map((item) => <TableCell colSpan={2} style={['Segunda', 'Quarta', 'Sexta'].indexOf(item) !== -1 ? { backgroundColor: '#f0f0f0' } : {}}>{item}</TableCell>)}
                </TableHead>
                <TableBody>
                  <TableRow>
                    {['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map((item) => <>
                      <TableCell style={['monday', 'wednesday', 'friday'].indexOf(item) !== -1 ? { backgroundColor: '#f0f0f0' } : {}}>
                        <TextField
                          label="De"
                          type="time"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          style={{ paddingRight: 20 }}
                          name={`pickup_window_${item}_time1`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[`pickup_window_${item}_time1`]}
                        />
                      </TableCell>
                      <TableCell style={['monday', 'wednesday', 'friday'].indexOf(item) !== -1 ? { backgroundColor: '#f0f0f0' } : {}}>
                        <TextField
                          label="Até"
                          type="time"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          style={{ paddingRight: 20 }}
                          name={`pickup_window_${item}_time2`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[`pickup_window_${item}_time2`]}
                        />
                      </TableCell>
                    </>)}
                  </TableRow>
                  <TableRow>
                    {['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map((item) => <>
                      <TableCell style={['monday', 'wednesday', 'friday'].indexOf(item) !== -1 ? { backgroundColor: '#f0f0f0' } : {}}>
                        <TextField
                          label="De"
                          type="time"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          style={{ paddingRight: 20 }}
                          name={`pickup_window_${item}_time3`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[`pickup_window_${item}_time3`]}
                        />
                      </TableCell>
                      <TableCell style={['monday', 'wednesday', 'friday'].indexOf(item) !== -1 ? { backgroundColor: '#f0f0f0' } : {}}>
                        <TextField
                          label="Até"
                          type="time"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          style={{ paddingRight: 20 }}
                          name={`pickup_window_${item}_time4`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[`pickup_window_${item}_time4`]}
                        />
                      </TableCell>
                    </>)}
                  </TableRow>
                </TableBody>
              </Table>
              </PerfectScrollbar>
            </Grid>
          </Grid>
          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitText ? submitText : "CADASTRAR"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withSnackbar(Form);
