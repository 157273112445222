import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Button from '@atlaskit/button/standard-button';

import {
  Box,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { HelpCircle } from 'react-feather';
import { ButtonGroup } from '@atlaskit/button';

const useStyles = makeStyles((theme) => ({
  root: {

    marginBottom: "25px",
    background: '#FAFBFC',
    padding: 30,
    borderBottom: '2px solid #EBECF0',
    marginBottom: 30
  },
  title: {
    fontFamily: 'Syne',
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 20
  },
  help: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: 'purple',
    width: 140,
    '&:hover': {
      textDecoration: 'underline'
    },
    '& > svg': {
      width: 14,
      marginRight: 5
    }
  },
  breadcrumb: {
    listStyle: 'none',
    display: 'block',
    color: 'rgb(94, 108, 132)',
    '& > li:first-child': {
      marginLeft: 0
    },
    '& > li': {
      float: 'left',
      marginRight: 5,
      marginLeft: 5
    },
    '& > li a': {
      color: '#242424'
    },
    '& > li a:hover': {
      textDecoration: 'underline'
    }
  }
}));

const Header = ({ title, description, help, breadcrumbs, buttons }) => {
  const classes = useStyles();
  return (
    <div>
      <Box

        className={classes.root}
      >
        <Grid container>
          <Grid item lg={8}>
            <Box style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}>
              {breadcrumbs && <ul className={classes.breadcrumb}>
                {breadcrumbs.map((breadcrumb, index) => {
                  return <>
                    <li>
                      {breadcrumb.link ? <Link to={breadcrumb.link}>{breadcrumb.text}</Link> : <strong>{breadcrumb.text}</strong>}
                    </li>
                    {breadcrumbs[index + 1] && <li>/</li>}
                  </>
                })}
              </ul>}

              <Typography
                color="textPrimary"
                variat="h1"
                className={classes.title}
              >
                {title}
              </Typography>
              {description ? <p>
                {description}
              </p> : null}

            </Box>
          </Grid>

          <Grid item lg={4} style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}>
            <Box >
              <ButtonGroup>
                {help ? <Button appearance="subtle" target='_blank' to={help} className={classes.help} component={Link} iconBefore={<HelpCircle />}>
                  Precisa de ajuda?
                </Button> : null}
                {buttons.map((button) => button.link
                  ? <Button to={button.link} component={Link} appearance={button.appearance ? button.appearance : 'primary'}>{button.text}</Button>
                  : <Button onClick={button.onClick} appearance={button.appearance ? button.appearance : 'primary'}>{button.text}</Button>)}
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>

      </Box>
    </div>
  );
};

export default Header;
