import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  Container,
  IconButton,
  Typography,
  Grid,
  Divider,
  Box
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import { useNavigate } from 'react-router-dom';
import OrdersList from 'src/views/orders/v2/OrdersView/List';

import { LoadingButton } from '@atlaskit/button';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import EmptyState from '@atlaskit/empty-state';

import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/standard-button';
import { Checkbox } from '@atlaskit/checkbox';
import { RadioGroup } from '@atlaskit/radio';
import Select, { OptionType, ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';

import Form, { CheckboxField, ErrorMessage, Field, FormFooter, FormHeader, FormSection, HelperMessage } from '@atlaskit/form';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import SectionMessage from '@atlaskit/section-message';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import PickupsView from 'src/views/pickups/PickupsView';
import { Trash } from 'react-feather';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const PickupsEditView = ({ pickup, callReload, onCloseWindow }) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState({
    removeFromPickup: false,
    confirmPickup: false
  });

  const openModal = useCallback((orderId) => {
    setIsOpen(true);
    setSelectedOrder(orderId)
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setSelectedOrder(null);
  }, []);

  const openModalDelete = useCallback((pickupId) => {
    setIsOpenDelete(true);
  }, []);

  const closeModalDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);

  const removeFromPickup = () => {
    setLoadingSubmit({ ...loadingSubmit, removeFromPickup: true });

    apiService
      .removeFromPickup(selectedOrder.id)
      .then(() => {
        enqueueSnackbar('Pedido #' + selectedOrder.reference + ' removido com sucesso.', {
          variant: 'success',
        });
        setLoadingSubmit({ ...loadingSubmit, removeFromPickup: false });
        closeModal();
      }).catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
        setLoadingSubmit({ ...loadingSubmit, removeFromPickup: false });
        closeModal();
      });
  };

  const deletePickup = () => {
    setLoadingSubmit({ ...loadingSubmit, deletePickup: true });

    apiService
      .deletePickup(pickup.id)
      .then(() => {
        enqueueSnackbar('Coleta #' + pickup.id + ' removida com sucesso.', {
          variant: 'success',
        });
        setLoadingSubmit({ ...loadingSubmit, deletePickup: false });
        closeModalDelete();
        callReload();
        onCloseWindow();

      }).catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
        setLoadingSubmit({ ...loadingSubmit, deletePickup: false });
        closeModalDelete();
      });
  };

  const confirmPickup = (data) => {
    const apiService = new ApiService();

    if (!data.pickup_date) {
      enqueueSnackbar('Preencha a Data e Hora da coleta', {
        variant: 'error',
      });
      return;
    }

    setLoadingSubmit({ ...loadingSubmit, confirmPickup: true });

    apiService
      .confirmPickup(pickup.id, data)
      .then((res) => {
        enqueueSnackbar(pickup.id + ' - Coleta confirmada com sucesso!', {
          variant: 'success',
        });
        setLoadingSubmit({ ...loadingSubmit, confirmPickup: false });
        callReload();
      }).catch((error) => {
        enqueueSnackbar(pickup.id + ' - ' + error.message, {
          variant: 'error',
        });
        setLoadingSubmit({ ...loadingSubmit, confirmPickup: false });
      });
  }

  const performPickup = (data) => {
    const apiService = new ApiService();

    if (!data.pickup_date) {
      enqueueSnackbar('Preencha a Data e Hora da realização da coleta', {
        variant: 'error',
      });
      return;
    }

    setLoadingSubmit({ ...loadingSubmit, confirmPickup: true });

    apiService.performPickup(pickup.id, data)
      .then((res) => {
        enqueueSnackbar(pickup.id + ' - Coleta realizada com sucesso!', {
          variant: 'success',
        });
        setLoadingSubmit({ ...loadingSubmit, confirmPickup: false });
        callReload();
      }).catch((error) => {
        enqueueSnackbar(pickup.id + ' - ' + error.message, {
          variant: 'error',
        });
        setLoadingSubmit({ ...loadingSubmit, confirmPickup: false });
      });

  }


  return (
    <Container maxWidth={false} style={{ padding: 24 }}>

      <ModalTransition>
        {isOpen && pickup && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">
                Remover pedido da coleta
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                Você tem certeza que deseja remover o pedido #{selectedOrder.reference} dessa coleta da <strong>{pickup.carrier.canonical_name.toUpperCase()}</strong>?
              </p>
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                appearance="subtle"
                onClick={closeModal}
                isDisabled={loadingSubmit.removeFromPickup}>Cancelar</LoadingButton>
              <LoadingButton
                isLoading={loadingSubmit.removeFromPickup}
                appearance="danger"
                onClick={() => {
                  removeFromPickup();
                }} autoFocus>
                Confirmar Remoção
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <ModalTransition>
        {isOpenDelete && pickup && (
          <Modal onClose={closeModalDelete}>
            <ModalHeader>
              <ModalTitle appearance="danger">
                Cancelar e excluir coleta
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                Você tem certeza que deseja cancelar e excluir a coleta #{pickup.id} da <strong>{pickup.carrier.canonical_name.toUpperCase()}</strong>? <br />
                Essa ação irá notificar a transportadora de que não precisa mais efetuar a coleta desses pedidos e também removerá a coleta da sua lista de coletas pendentes.
              </p>
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                appearance="subtle"
                onClick={closeModalDelete}
                isDisabled={loadingSubmit.deletePickup}>Cancelar</LoadingButton>
              <LoadingButton
                isLoading={loadingSubmit.deletePickup}
                appearance="danger"
                onClick={() => {
                  deletePickup();
                }} autoFocus>
                Confirmar Remoção
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <div style={{
        margin: '0 8px',
        borderBottom: '1px solid #ddd',
        paddingBottom: '10px'
      }}>
        <Typography
          variant='h2'
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 19,
            marginBottom: 10
          }}>COLETA {pickup.id} <small style={{
            fontSize: 12,
            marginLeft: 10,
            color: '#6d798e'
          }}>Atualizada em {moment(pickup.updated_at).format('DD/MM/YYYY HH:mm:ss')}</small></Typography>
      </div>
      <Tabs
        onChange={(index) => console.log('Selected Tab', index + 1)}
        id="default"
      >
        <TabList>
          <Tab>Informações da coleta</Tab>
          <Tab>Pedidos</Tab>
          <Tab>Histórico</Tab>
        </TabList>
        <TabPanel>
          <Grid container>

            <Grid item lg={4} style={{ paddingTop: 40 }}>
              {pickup.pickup_date ? <Form onSubmit={(data) => performPickup(data)}>
                {({ formProps }) => (
                  <form
                    {...formProps}
                    method="POST"
                    target="submitFrame"
                    name="create-repo"
                  >
                    <FormHeader
                      title="Editar Coleta"
                      description="Preencha com a data que a coleta foi efetuada"
                    />

                    <FormSection>

                      <Field
                        name="pickup_date"
                        label="Data da coleta"
                        isRequired
                      >
                        {({ fieldProps: { id, ...rest }, error }) => {
                          const validationState = error ? 'error' : 'none';
                          return (
                            <>
                              <DateTimePicker
                                locale='pt_BR'
                                datePickerSelectProps={{ validationState, inputId: id }}
                                datePickerProps={{ maxDate: moment().format('YYYY-MM-DD') }}

                                isRequired
                                placeholder=""
                                {...rest}
                              />
                              <HelperMessage>Você pode colocar uma data e hora retroativa.</HelperMessage>

                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </>
                          );
                        }}
                      </Field>
                    </FormSection>

                    <FormSection>
                      <CheckboxField name="already_pickuped" label="Coleta já foi realizada?">
                        {({ fieldProps }) => (
                          <Checkbox
                            {...fieldProps}
                            label="Não notificar destinatários sobre essa confirmação."
                          />
                        )}
                      </CheckboxField>
                    </FormSection>


                    <FormFooter>
                      <ButtonGroup>
                        <LoadingButton
                          appearance="primary"
                          id="create-repo-button"
                          type="submit"
                          isLoading={loadingSubmit.confirmPickup}
                        >
                          Coleta Realizada
                        </LoadingButton>
                      </ButtonGroup>
                    </FormFooter>
                  </form>
                )}
              </Form> : <Form onSubmit={(data) => confirmPickup(data)}>
                {({ formProps }) => (
                  <form
                    {...formProps}
                    method="POST"
                    target="submitFrame"
                    name="create-repo"
                  >
                    <FormHeader
                      title="Editar Coleta"
                      description="Preencha com os dados informados pela transportadora."
                    />

                    <FormSection>

                      <Field
                        name="pickup_date"
                        label="Data de coleta"
                        defaultValue={pickup.pickup_date}
                        isRequired
                      >
                        {({ fieldProps: { id, ...rest }, error }) => {
                          const validationState = error ? 'error' : 'none';
                          return (
                            <>
                              <DateTimePicker
                                locale='pt_BR'
                                datePickerSelectProps={{ validationState, inputId: id }}
                                timePickerSelectProps={{
                                  validationState,
                                  'aria-labelledby': `${id}-label`,
                                }}
                                isRequired
                                placeholder=""
                                {...rest}
                              />
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </>
                          );
                        }}
                      </Field>

                      <Field name="integration_code" label="Código da Coleta" defaultValue={pickup.integration_code}>
                        {({ fieldProps }) => <Textfield {...fieldProps} />}
                      </Field>

                    </FormSection>

                    <FormFooter>
                      <ButtonGroup>
                        <LoadingButton
                          appearance="primary"
                          id="create-repo-button"
                          type="submit"
                          isLoading={loadingSubmit.confirmPickup}
                        >
                          Confirmar Coleta
                        </LoadingButton>
                      </ButtonGroup>
                    </FormFooter>
                  </form>
                )}
              </Form>}

              <Box marginTop="30px" marginBottom="30px">
                <Divider />
              </Box>
              <Box marginTop="30px" marginBottom="30px">
                <FormHeader
                  title="Área de cancelamento"
                  description="Utilize os botões abaixo com cuidado"
                />
                <FormFooter>
                  <ButtonGroup>
                    <LoadingButton
                      appearance="danger"
                      id="delete-pickup"
                      type="button"
                      onClick={openModalDelete}
                      isLoading={loadingSubmit.deletePickup}
                    >
                      Excluir Coleta
                    </LoadingButton>
                  </ButtonGroup>
                </FormFooter>
              </Box>

            </Grid>

          </Grid>
        </TabPanel>
        <TabPanel>
          <OrdersList
            pickupId={pickup.id}
            miniVersion
            reloadOrders={loadingSubmit.removeFromPickup === false}
            buttons={[
              {
                description: 'Remover pedido da coleta',
                title: 'Remover',
                icon: (style) => <Trash style={style} />,
                onClick: (orderId) => {
                  openModal(orderId);
                }
              }
            ]}
          />
        </TabPanel>
        <TabPanel>
          <EmptyState
            header="Em desenvolvimento"
            description="Em breve você poderá ver o histórico de alterações em cada uma das coletas"
          />
        </TabPanel>
      </Tabs>


    </Container>
  );
};


export default PickupsEditView;
