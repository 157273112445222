import React, { useEffect, useState } from 'react';
import Form from "@rjsf/material-ui";
import { JsonToTable } from "react-json-to-table";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Check, DownloadCloud, Eye, Filter, Tool, Trash } from 'react-feather';
import { Link, useParams } from 'react-router-dom';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
import { ArrowRightAlt, ImportantDevicesTwoTone, Refresh } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import { Alert, Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  successButton: {
    backgroundColor: '#8BC34A',
    marginLeft: 20
  },
  sortDiabled: {
    color: '#ddd',
    cursor: 'pointer'
  },
  activeArrow: {
    color: '#000',
    cursor: 'pointer'
  }
}));

const IntegrationDetail = ({ enqueueSnackbar, ...props }) => {
  const match = useParams();
  const [channel, setChannel] = useState(null);
  const apiService = new ApiService();
  const [form, setForm] = useState(null);
  const [params, setParams] = useState(null);
  const [atualizarStatus, setAtualizarStatus] = useState(false);
  const [more, showMore] = useState(false);
  const classes = useStyles();
  const [listing, setListing] = React.useState(null);
  const [syncing, setSyncing] = React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState({});
  const [listingData, setListingData] = React.useState({
    columns: {},
    data: [],
    total: 0,
    total_import: 0
  });

  const [filters, setFilters] = React.useState({});
  const [actualFilters, setActualFilters] = React.useState({});


  const [situations, setSituations] = React.useState([]);
  const flixlogSituations = [
    {
      id: 3,
      name: 'Em trânsito'
    },
    {
      id: 4,
      name: 'Saiu para entrega'
    },
    {
      id: 5,
      name: 'Entregue'
    },
  ];
  const [mapSituation, setMapSituation] = React.useState({
    3: null,
    4: null,
    5: null,
  });

  const [value, setValue] = React.useState(0);
  const [selectedRows, setSelectedRowsIds] = useState([]);

  const addFilter = (column, value) => {
    setFilters({
      ...filters,
      [column]: value
    });
  }

  const syncListing = () => {
    setSyncing(true);

    if (!listingData.import_ids && !window.confirm('Tem certeza que deseja sincronizar a base inteira? Isso pode levar alguns minutos.')) {
      setSyncing(false);
      return;
    }
    apiService
      .syncCompanyChannelListing(match.id, listing.resource, {
        ids: listingData.import_ids
      })
      .then((res) => {
        setSyncing(false);
        enqueueSnackbar('Produtos sincronizados com sucesso', {
          variant: 'success',
        });
      }).catch((res) => {
        setSyncing(false);
        enqueueSnackbar(`Ocorreu um erro:${res.message}`, {
          variant: 'error',
        })
      });
  }

  const setWebhookSituation = () => {
    apiService
      .syncCompanyChannelListingSitutation(match.id, mapSituation)
      .then((res) => {
        enqueueSnackbar('Salvo com sucesso', {
          variant: 'success',
        });
      }).catch((res) => {
        enqueueSnackbar(`Ocorreu um erro, tente novamente: ${res.message}`, {
          variant: 'error',
        })
      });
  }

  const handleSelectAll = (event) => {
    let rowIds;

    if (event.target.checked && listingData.data) {
      rowIds = listingData.data.map((item) => item.id);
    } else {
      rowIds = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);

    if (rowIds.length > 0) {
      setListingData({
        listingData, ...listingData,
        'total_import': rowIds.length,
        'import_ids': rowIds,
      });
    } else {
      setListingData({
        listingData, ...listingData,
        'total_import': listingData.total,
        'import_ids': []
      });
    }
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    apiService
      .getCompanyChannel(match.id)
      .then((res) => {
        setChannel(res);

        if (res.channel.listings) {
          setListing(res.channel.listings[0]);
        }

        setParams(res.property);

        if (res.property.situation_map) {
          setMapSituation(res.property.situation_map);
          setAtualizarStatus(res.property.situation_enabled);
        }

        setForm(<JsonToTable json={
          res.property
        } />);

      });
  }, [match.id]);



  useEffect(() => {
    if (listing) {
      setLoading(true);
      apiService
        .getCompanyChannelListing(match.id, listing.resource, filters[listing.resource], sort[listing.resource], limit, (page == 1 ? 1 : page + 1))
        .then((res) => {
          res.total_import = res.total;
          setListingData(res);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    }
  }, [listing, page, limit, filters, sort]);

  useEffect(() => {
    apiService
      .getCompanyChannelListing(match.id, 'situations', {}, {},
        100,
        1
      )
      .then((res) => {
        setSituations(res.data ? res.data : []);
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title={`Integração:  ${channel ? channel.channel.name : '...'}`}
    >
      <Container maxWidth={false}>
        <Toolbar title={`Integração:  ${channel ? channel.channel.name : '...'}`} hideButton />

        {channel ? <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Card>
                <CardHeader title={"Parâmetros"} />
                <CardContent>
                  {params && params.url_de_frete ? <Alert severity="info"><strong>URL de frete: </strong>{params.url_de_frete}</Alert> : null}
                  <br />
                  <br />
                  <div style={more ? {} : { height: '40px', overflow: 'hidden' }}>
                    {form}
                  </div>
                </CardContent>
                <CardActions>
                  {more ? <Button onClick={() => showMore(false)}>recolher</Button> : <Button onClick={() => showMore(true)}>mostrar</Button>}
                </CardActions>
              </Card>
            </Grid>
            {channel && situations.length ? <Grid item lg={12}>
              <Card>
                <CardHeader title={"Atualização de status"} />
                <CardContent>
                  <FormControlLabel
                    control={<Switch
                      checked={atualizarStatus}
                      onChange={(ev) => setAtualizarStatus(ev.target.checked)}
                      name="statusSync"
                    />}
                    label="Ativar sincronização de status"
                  />

                  {atualizarStatus && situations.length ? <Table>
                    <TableBody>
                      {flixlogSituations.map((flixStatus) => <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ width: 180 }}>
                          <Typography variant="h5">
                            {`${flixStatus.id} - ${flixStatus.name}`}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: 60 }}>
                          <ArrowRightAltIcon />
                        </TableCell>
                        <TableCell style={{ width: 210 }}>
                          <FormControl className={classes.formControl} style={{ minWidth: '100%' }} InputLabelProps={{ shrink: true }}
                          >
                            <InputLabel InputLabelProps={{ shrink: true }}
                            >{`Status Pedido ${channel.channel.name}`}</InputLabel>
                            <Select
                              label={`Status Pedido ${channel.channel.name}`}
                              value={mapSituation[flixStatus.id] ? mapSituation[flixStatus.id] : undefined}
                              onChange={(ev) => {
                                mapSituation[flixStatus.id] = ev.target.value;
                                setMapSituation(mapSituation)
                              }}
                            >
                              <MenuItem value={null}>Nenhum</MenuItem>
                              {situations.map((situ) => <MenuItem value={situ.id}>{situ.name}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table> : null}
                </CardContent>
                {atualizarStatus ? <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={setWebhookSituation}
                  >
                    {'Salvar'}
                  </Button>
                </CardActions> : null}
              </Card>
            </Grid> : null}
            {listingData.type ? <Grid item lg={12}>
              <Card>
                <CardHeader
                  title={`Entidades para importação na ${channel ? channel.channel.name : '...'}`}
                  subheader="Aqui será listado o que pode ser importado para a Flixlog"
                  action={
                    <Tooltip enterDelay={300} title={`Ao clicar aqui, você importará ${listingData.total_import} entidades (${listingData.type_translate}) selecionadas para a Flixlog.`}>
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        color="secondary"
                        badgeContent={listingData.total_import}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={syncListing}
                          disabled={syncing}
                          className={classes.successButton}
                        >
                          {syncing ? 'Aguarde ...' : `SINCRONIZAR ${listingData.type_translate ? listingData.type_translate.toUpperCase() : '...'}`}
                        </Button>
                      </Badge>
                    </Tooltip>
                  }
                />
                <CardContent>
                  <Tabs
                    value={value}
                    onChange={(ev, newValue) => {
                      setValue(newValue);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    {channel.channel.listings.map((res) => <Tab label={`${res.label}`} listing={res} onClick={() => setListing(res)} />)}

                  </Tabs>

                  <Box mt={3}>


                    <PerfectScrollbar>

                      <Table>
                        <TableHead>
                          <TableCell padding="checkbox">
                            <Tooltip title={"Filtrar resultados"}>

                              <IconButton onClick={() => setOpen(true)} >
                                <Badge
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  color="secondary"
                                  showZero={false}
                                  badgeContent={filters[listing.resource] ? Object.keys(filters[listing.resource]).length : 0}>
                                  <Filter />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                            <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
                              <DialogTitle>Filtros disponíveis</DialogTitle>
                              <DialogContent>
                                <List>
                                  {listingData.available_filters.map((filter) => (
                                    <ListItem key={filter}>
                                      <TextField
                                        label={filter}
                                        value={actualFilters[listing.resource] && typeof actualFilters[listing.resource][filter] ? actualFilters[listing.resource][filter] : null}
                                        onChange={(ev) => {
                                          setActualFilters({
                                            ...actualFilters,
                                            [listing.resource]: {
                                              [filter]: ev.target.value
                                            }
                                          });
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }} />
                                    </ListItem>
                                  ))}
                                </List>
                              </DialogContent>
                              <DialogActions>
                                <ListItem autoFocus>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      Object.keys(actualFilters).forEach((item) => {
                                        if (typeof actualFilters[listing.resource][item] !== undefined && actualFilters[listing.resource][item] == '') {
                                          delete actualFilters[listing.resource][item];
                                        }
                                      });
                                      setFilters(actualFilters);
                                      setOpen(false);
                                    }}
                                  >
                                    {'Aplicar filtros'}
                                  </Button>
                                </ListItem>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                          {Object
                            .keys(listingData.columns)
                            .map((column) => <TableCell><div style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between' }}>
                              {listingData.columns[column]}
                              {sort[listing.resource] && sort[listing.resource][column]
                                ? (sort[listing.resource][column] === 'desc'
                                  ? <ArrowRightAlt onClick={() => setSort({
                                    ...sort,
                                    [listing.resource]: {
                                      [column]: 'asc'
                                    }
                                  })} className={classes.activeArrow} style={{ transform: 'rotate(90deg)' }} />
                                  : <ArrowRightAlt onClick={() => setSort({
                                    ...sort,
                                    [listing.resource]: {
                                      [column]: 'desc'
                                    }
                                  })} className={classes.activeArrow} style={{ transform: 'rotate(270deg)' }} />)
                                : <ArrowRightAlt onClick={() => setSort({
                                  ...sort,
                                  [listing.resource]: {
                                    [column]: 'desc'
                                  }
                                })} className={classes.sortDiabled} style={{ transform: 'rotate(90deg)' }} />}
                            </div>
                            </TableCell>)}


                        </TableHead>
                        <TableBody>
                          {loading === false && listingData.data.map((item) => <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedRows.indexOf(item.id) !== -1}
                                onChange={(event) => handleSelectOne(event, item.id)}
                                value="true"
                              />
                            </TableCell>
                            {Object.keys(listingData.columns)
                              .map((column) => <TableCell>{item[column]}</TableCell>)}
                          </TableRow>)}

                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                          {loading ? <TableRow><TableCell colSpan={42}><Skeleton animation="wave" style={{ height: 50 }} /></TableCell></TableRow> : null}
                        </TableBody>
                      </Table>
                    </PerfectScrollbar>
                    <TablePagination
                      component="div"
                      count={listingData.total}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </Box>

                </CardContent>
              </Card>
            </Grid> : null}
          </Grid>

        </Box> : null}

      </Container>
    </Page >
  );
};

export default withSnackbar(IntegrationDetail);
