import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import InputMask from "react-input-mask";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';
import { DollarSign, Percent, Plus, Trash, TrendingDown } from 'react-feather';
import { SelectAllSharp, TrendingUp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [weights, setWeights] = useState(initialValues && initialValues.weights ? initialValues.weights : []);

  const addWeight = () => {
    setWeights(weights => [...weights, { id: weights.length + 1 }]);
  };

  const removeWeight = (weightId) => {
    setWeights(weights.filter((weight) => weight.id !== weightId));
  };

  const handleBlurRoute = (ev, weightId) => {
    setWeights(weights.map((weight) => {
      if (weight.id === weightId) {
        weight[ev.target.name] = ev.target.value;
      }

      return weight;
    }));
  }

  const handleChangeRoute = (ev, weightId, isCheckebox) => {
    setWeights(weights.map((weight) => {
      if (weight.id === weightId) {
        if (isCheckebox) {
          weight[ev.target.name] = ev.target.checked;
        } else {
          weight[ev.target.name] = ev.target.value;
        }
      }

      return weight;
    }));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        type: Yup.string(' ').required('Selecione o tipo de tabela'),
        zip_origin: Yup.string(' ').required('Coloque o CEP de origem'),
      })}
      onSubmit={(vals) => onSubmit(vals, weights)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {title ? title : "Formulário de solicitação"}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Utilize o formulário abaixo para cadastrar uma nova regra de frete
                    </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Tipo de Tabela</InputLabel>
                <Select
                  label="Canal de Venda"
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  margin="normal"
                  name="type"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  variant="outlined"
                  style={{ maxHeight: 58 }}
                >
                  <MenuItem value={null}>Selecionar</MenuItem>
                  <MenuItem value={'loja_integrada'}>Loja Integrada</MenuItem>
                  {/* <MenuItem value={'tray_corp'}>Tray Corp</MenuItem> */}
                  <MenuItem value={'b2w'}>B2w</MenuItem>
                  <MenuItem value={'tray_corp'}>TrayCorp</MenuItem>
                  <MenuItem value={'webcontinental'}>WebContinental</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2}>
              <InputMask
                mask="99.999-999"
                name="zip_origin"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.zip_origin}
              >
                {() => (
                  <TextField
                    label="CEP de origem"
                    name="zip_origin"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6} lg={3}>

              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Modificar preço</InputLabel>
                <Select
                  label="Modificar preço"
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  margin="normal"
                  name="increase_price_type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.increase_price_type}
                  variant="outlined"
                  style={{ maxHeight: 58 }}
                >
                  <MenuItem value={'value'}><TrendingUp style={{ marginRight: 5, width: 15 }} /> {"  Acrescentar valor sobre o frete"}</MenuItem>
                  <MenuItem value={'percentage'}><Percent style={{ marginRight: 5, width: 15 }} /> {"  Acrescentar percentual sobre o frete"}</MenuItem>
                  <MenuItem value={'fixed'}><DollarSign style={{ marginRight: 5, width: 15 }} /> {"  Frete fixo"}</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6} lg={3}>

              <TextField
                name="increase_price_value"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.increase_price_value}
                type="number"
                label={"Valor adicional"}
                margin="normal"
                variant="outlined"
                fullWidth
              />

            </Grid>


          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {weights.map((weight, index) => {
                return <Card key={index}>
                  <CardContent>
                    <h3>Faixa de peso #{index + 1}</h3>
                    <Grid container spacing={3}>
                      <Grid item xs={6} lg={2}>
                        <TextField
                          name="weight"
                          onBlur={(ev) => handleBlurRoute(ev, weight.id)}
                          onChange={(ev) => handleChangeRoute(ev, weight.id)}
                          value={weight.weight}
                          required
                          type="number"
                          label={"Até (kg)"}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} lg={2}>
                        <TextField
                          name="cargo_value"
                          onBlur={(ev) => handleBlurRoute(ev, weight.id)}
                          onChange={(ev) => handleChangeRoute(ev, weight.id)}
                          value={weight.cargo_value}
                          required
                          type="number"
                          label={"Valor estimado de carrinho (R$)"}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} lg={2}>
                        <IconButton onClick={() => removeWeight(weight.id)} style={{ marginTop: 18 }}><Trash /></IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              }
              )}
            </Grid>
            <Grid xs={12}>
              <Box my={2}>

                <Button onClick={addWeight}>
                  <Plus /> Adicionar faixa de peso.
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitText ? submitText : "CADASTRAR"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withSnackbar(Form);
