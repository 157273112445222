import React, {useState} from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({ className, enqueueSnackbar, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [sync, setSync] = useState(false);

  const send = () => {
    const apiService = new ApiService;
    if (sync) {
      return;
    }
    setSync(true);
    apiService
      .synchronizeQuotations()
      .then(() => {
        setOpenDialog(false);
        setSync(false);
        enqueueSnackbar('Sincronização efetuada com sucesso!', {
          variant: 'success',
        });
      });
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Sincronizar tabelas de frete"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
          <Button onClick={() => send()} color="primary" autoFocus>
            {sync ? 'Aguarde ...' : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
      
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          Tabelas de Frete
        </Typography>

        <Button
          color="success"
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={() => setOpenDialog(true)}>
          Sincronizar Tabelas de Frete
        </Button>

        <Button 
          color="primary"
          variant="contained"
          component={Link} to={'/settings/freight-tables/new'}>
          Nova Tabela
        </Button>

      </Box>
    </div>
  );
};

export default withSnackbar(Toolbar);
