import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  Container,
  ButtonGroup,
  IconButton,
  Button
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Header from '../../../../layouts/DashboardLayout/Header';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';
import { Link } from 'react-router-dom';
import { WarningRounded } from '@material-ui/icons';
import { Download, Edit } from 'react-feather';

import EditView from './Edit.js';
import NewPickup from './NewPickup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const PickupsListView = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [highlightedPickup, setHighlightedPickup] = useState(null);
  const [pickups, setPickups] = useState([]);
  const [pickupsToday, setPickupsToday] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(20);
  const [page, changePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    company: null
  });
  const [carriers, setCarriers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [window, setWindow] = useState(null);
  const [dashboardList, setDashboardList] = useState(null);


  const pickupStatuses = [
    { value: 1, label: 'Aguardando Aceite' },
    { value: 2, label: 'Coleta Aceita' },
    { value: 3, label: 'Coleta Realizada' }
  ];
  const deadlineStatus = [
    { value: 'delayed', label: 'Coletas atrasadas' },
    { value: 'today', label: 'Coletas para hoje' },
    { value: 'd1', label: 'Coletas em D+1' },
    { value: 'd2', label: 'Coletas em D+2' },
    { value: 'd3', label: 'Coletas em D+3' },
    { value: 'd7', label: 'Coletas em D+7' },
    { value: 'dn', label: 'Coletas em D+n' }
  ];

  const handleFilters = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  }
  
  const handleFiltersStart = (filter, value) => {
	filter = `${filter}_start`;
	console.log(filter, value);
	
    setFilters({ ...filters, [filter]: value });
  }
  
  const handleFiltersEnd = (filter, value) => {
	filter = `${filter}_end`;
	console.log(filter, value);
    setFilters({ ...filters, [filter]: value });
  }
  
  const getCarriers = () => {
    apiService
      .getCarriers(true)
      .then((res) => setCarriers(res.data));
  };

  const getCompanies = () => {
    apiService
      .getCompanies()
      .then((res) => setCompanies(res.data));
  };

  const reloadOrders = (filters) => {
    setIsLoading(true);
    apiService
      .getPickups(filters, limit, page)
      .then((res) => {
        setPickups(res.data);
        setTotalRows(res.total);
        setIsLoading(false);
      });
  };

  const openPickup = (pickupId) => {
    setWindow(null);
    setIsLoading(true);
    setHighlightedPickup(pickupId);
    apiService
      .getPickup(pickupId)
      .then((res) => {
        setWindow(<EditView pickup={res} callReload={() => reloadOrders()} onCloseWindow={() => setWindow(null)} />);
        setIsLoading(false);
      });

    ;
  }

  useEffect(() => {
    reloadOrders(filters);
  }, [filters, limit, page]);

  useEffect(() => {
    apiService.getDashboarPickupSla(filters.company).then((res) => setDashboardList([
      {
        label: 'Total de Coletas: ',
        value: Number(res.total).toLocaleString('pt-BR'),
        type: 'default'
      },
      {
        label: 'Coletas Atrasadas: ',
        value: Number(res.total_delayed).toLocaleString('pt-BR'),
        type: 'danger'
      },
      {
        label: 'Coletas para hoje: ',
        value: Number(res.total_pickup_today).toLocaleString('pt-BR'),
        type: 'success'
      },
      {
        label: 'Aguardando Coleta: ',
        value: Number(res.total_waiting_pickup).toLocaleString('pt-BR'),
        type: 'warning'
      },

      {
        label: 'Aguardando Aceite: ',
        value: Number(res.total_waiting_pickup_date).toLocaleString('pt-BR'),
        type: 'warning'
      },
      {
        label: 'Coletas realizadas: ',
        value: Number(res.total_pickuped).toLocaleString('pt-BR'),
        type: 'success'
      }
    ]));
  }, [filters.company]);

  useEffect(() => {
    getCarriers();
    getCompanies();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Gestão de Coletas - Coletas - Flixlog"
    >
      <Header
        title="Gestão de Coletas"
        description="Aqui você pode acompanhar o status de todas as coletas. Caso alguma esteja demorando ou seja mais urgente, entre em contato com nosso time operacional."
        help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Coletas',
            link: ''
          }
        ]}
        buttons={[
          {
            text: 'Solicitar Coleta',
            link: '/pickups/new'
          }
        ]}
      />

      <Container maxWidth={false}>
        <DataTable
          rows={pickups}
          dashboard={dashboardList}
          filters={
            [
              {
                placeholder: 'Buscar por pedido, nota ou ID',
                type: 'search',
                filter: 'search',
                onBlur: handleFilters,
                onEnter: handleFilters,
              },
              {
                placeholder: 'Empresa',
                type: 'select',
                filter: 'company',
                options: companies.map((company) => ({ value: company.id, label: `${company.id} - ${company.name}` })),
                onChange: handleFilters
              },
              {
                placeholder: 'Status',
                type: 'select',
                filter: 'status',
                options: pickupStatuses,
                onChange: handleFilters
              },
              {
                placeholder: 'Transportadora',
                type: 'select',
                filter: 'carrier',
                options: carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` })),
                onChange: handleFilters
              },
              {
                placeholder: 'Prazo da Coleta',
                type: 'select',
                filter: 'deadline',
                width: 200,
                options: deadlineStatus,
                onChange: handleFilters
              },
              {
                placeholder: 'Data de solicitação',
                type: 'date',
                filter: 'created_at',
                width: 200,
				onChangeStart: handleFiltersStart,
                onChangeEnd: handleFiltersEnd
              },
              {
                placeholder: 'Previsão de Coleta',
                type: 'date',
                filter: 'pickup_date',
                width: 200,
                onChangeStart: handleFiltersStart,
                onChangeEnd: handleFiltersEnd
              }
            ]
          }
          head={{
            key: `table-pickup`,
            isHighlighted: false,
            cells: [
              {
                key: 'id',
                content: 'ID',
                isSortable: false,
              },
              {
                key: 'company_name',
                content: 'Empresa',
                isSortable: false
              },
              {
                key: 'distribution_center_name',
                content: 'CD Origem',
                isSortable: false
              },
              {
                key: 'carrier',
                content: 'Transportadora',
                isSortable: false
              },
              {
                key: 'invoices',
                content: 'Total NFs',
                isSortable: false
              },
              {
                key: 'status',
                content: 'Status',
                isSortable: false
              },
              {
                key: 'integration_code',
                content: 'Código da Coleta',
                isSortable: false
              },
              {
                key: 'pickup_date',
                content: 'Estimativa de Coleta',
                isSortable: false
              },
              {
                key: 'created_at',
                content: 'Criado em',
                isSortable: false
              },
              {
                key: 'actions',
                content: 'Ações',
                isSortable: false
              }
            ]
          }}
          rows={pickups.map((pickup, index) => ({
            key: `row-${index}-${pickup.id}`,
            isHighlighted: highlightedPickup == pickup.id,
            onClick: () => openPickup(pickup.id),
            cells: [
              {
                key: pickup.id,
                content: pickup.id
              },
              {
                key: pickup.company.name,
                content: <strong>{pickup.company.name}</strong>
              },
              {
                key: pickup.distribution_center?.id,
                content: <>
                  {pickup.distribution_center
                    ? <Link target="_blank" className={classes.link} to={`/settings/distribution-centers/edit/${pickup.distribution_center?.id}`}>{pickup.distribution_center?.reference} - {pickup.distribution_center?.address.city}/{pickup.distribution_center?.address.state}</Link>
                    : '-'}
                </>
              },
              {
                key: pickup.carrier?.canonical_name,
                content: <strong>{pickup.carrier?.canonical_name?.toUpperCase()}</strong>
              },
              {
                key: index,
                content: <div>
                  <Tooltip title={`Total de volumes: ${pickup.volumes.total_volumes}. Valor total da carga: ${pickup.volumes?.cargo_value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}><strong>{pickup.totals.total}</strong></Tooltip>
                </div>
              },
              {
                key: 'status',
                content: pickup.pickuped_at
                  ? <StatusIcon type="success" title="Coleta Realizada" description={`Coleta realizada com sucesso pela transportadora em ${moment(pickup.pickuped_at).format('DD/MM/YYYY HH:mm')}`} />
                  : pickup.pickup_date
                    ? <StatusIcon type="success" title="Coleta Aceita" description={`Coleta aceita pela transportadora em ${moment(pickup.pickup_date).format('DD/MM/YYYY HH:mm')}`} />
                    : <StatusIcon title="Aguardando Aceite" />
              },
              {
                key: 'integrationCode',
                content: <strong>{pickup.integration_code}</strong>
              },
              {
                key: 'pickup_date',
                content: pickup.pickuped_at ? <div>
                  <div style={{ textDecoration: 'line-through', color: '#aaa' }}>{pickup.pickup_date && moment(pickup.pickup_date).format('DD/MM/YYYY HH:mm')}</div>
                  <Tooltip title={`Coleta realizada pela transportadora em ${moment(pickup.pickuped_at).format('DD/MM/YYYY HH:mm')}`}><div style={{ color: '#145e3f' }}>{moment(pickup.pickuped_at).format('DD/MM/YYYY HH:mm')}</div></Tooltip>
                </div> : pickup.pickup_date ? <div style={{ display: 'flex', alignItems: 'center' }}>{pickup.deadline === 'delayed' ? <Tooltip title="Coleta atrasada"><WarningRounded style={{ color: 'red' }} /></Tooltip> : null} {moment(pickup.pickup_date).format('DD/MM/YYYY HH:mm')}</div> : ''
              },
              {
                key: 'created_at',
                content: moment(pickup.created_at).format('DD/MM/YYYY HH:mm')
              },
              {
                key: 'actions',
                content: <>
                  <ButtonGroup>
                    <Tooltip
                      title="Abrir detalhes da coleta"
                    >
                      <Button onClick={() => openPickup(pickup.id)} >
                        ABRIR
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </>
              }
            ]
          }))}
          limit={limit}
          page={page}
          changePage={changePage}
          totalRows={totalRows}
          isLoading={isLoading}
          window={window}
          onCloseWindow={() => setWindow(null)}
        />

      </Container>
    </Page>
  );
};


export default PickupsListView;
